import { useState, useEffect, createRef } from "react";
import axios from "axios";

import Table from "../../components/Table";
import Modal from "../../components/Modal";
import { formatAmount } from "../../utils";
import styles from "../MDAs/style.module.css";

const coy_ANSSID = process.env.REACT_APP_ANSSID

const TotalCollections = () => {
	const [fullTableShowing, toggleFullTable] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const filterRef = createRef();

	const [anssid, setAnssid] = useState("");
	const [paymentReference, setPaymentReference] = useState("");
	const [startdate, setStartDate] = useState("");
	const [enddate, setEndDate] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [revSource, setRevSource] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [taxpayerName, setTaxpayerName] = useState("");
	const [total, setTotal] = useState(0);


	const headers = [
		["Payment Date", "DatePaid", (val) => new Date(val).toLocaleString()],
		["Payment Reference", "PaymentReference"],
		// ["ANSSID", "ANSSID"],
		[`${coy_ANSSID}`, "ANSSID" ],
		["Taxpayer name", "TaxpayerName"],
		["Amount (NGN)", "Amount", formatAmount],
		["Revenue Source", "RevenueCode"],
		["Reversal Flag", "ReversalFlag"]
	];

	const getTotalCollections =
		({ currentPage = 1 } = {}) =>
			async (e) => {
				e?.preventDefault?.();

				setLoading(true);
				if (!filterRef.current.classList.contains(styles.no__display)) {
					filterRef.current.classList.toggle(styles.no__display);
				}

				let query = `page=${currentPage}&pageSize=20&`;
				if (anssid) {
					query += `anssid=${anssid}&`;
				}
				if (taxpayerName) {
					query += `taxpayerName=${taxpayerName}&`;
				}
				if (paymentReference) {
					query += `reference=${paymentReference}&`;
				}

				if (startdate) {
					query += `startdate=${new Date(startdate).toLocaleString(
						"en-US",
						{
							timeZone: "Africa/Lagos",
							year: "numeric",
							month: "2-digit",
							day: "2-digit"
						}
					)}&`;
				}

				if (enddate) {
					query += `enddate=${new Date(enddate).toLocaleString("en-US", {
						timeZone: "Africa/Lagos",
						year: "numeric",
						month: "2-digit",
						day: "2-digit"
					})}&`;
				}

				if (minAmount) {
					query += `minAmount=${minAmount}&`;
				}

				if (maxAmount) {
					query += `maxAmount=${maxAmount}&`;
				}

				if (revSource) {
					query += `revSource=${revSource}&`;
				}

				try {
					const accessToken = localStorage.getItem("access_token");
					const info = await axios
						.get(`/txn/reports/invoices?${query}`, {
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						})
						.then((res) => res.data);

					if (info) {
						setData(info.data);
						setTotal(info.total);
					}
				} catch (err) {
					console.error(err);
				} finally {
					setLoading(false);
				}
			};

	const clearFilters = () => {
		setAnssid("");
		setPaymentReference("");
		setStartDate("");
		setEndDate("");
		setMinAmount("");
		setMaxAmount("");
		setTaxpayerName("");
		setRevSource("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getTotalCollections()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getTotalCollections()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button onClick={() => toggleFullTable(!fullTableShowing)}>
					View Entire Table
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!anssid &&
								!paymentReference &&
								!startdate &&
								!enddate &&
								!minAmount &&
								!maxAmount &&
								!taxpayerName &&
								!revSource
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="anssid">{coy_ANSSID}</label>
								<input
									name="anssid"
									id="anssid"
									type="text"
									value={anssid}
									onChange={(e) => setAnssid(e.target.value)}
									placeholder={coy_ANSSID}
								/>
							</div>
							<div>
								<label htmlFor="payment_reference">
									Payment Reference
								</label>
								<input
									name="payment_reference"
									id="payment_reference"
									type="text"
									value={paymentReference}
									onChange={(e) =>
										setPaymentReference(e.target.value)
									}
									placeholder="Payment Reference"
								/>
							</div>
						</form>
						<p>By Taxpayer Name</p>
						<form>
							<div>
								<label htmlFor="taxpayer_name">
									Taxpayer Name
								</label>
								<input
									name="taxpayer_name"
									id="taxpayer_name"
									type="text"
									value={taxpayerName}
									onChange={(e) =>
										setTaxpayerName(e.target.value)
									}
									placeholder="Eg. Chinenye Ikwe"
								/>
							</div>
						</form>
						<p>By Revenue Source</p>
						<form>
							<div>
								<label htmlFor="revSource">Revenue Source</label>
								<input
									name="revSource"
									id="revSource"
									type="number"
									value={revSource}
									onChange={(e) =>
										setRevSource(e.target.value)
									}
									placeholder="Eg. 12010003"
								/>
							</div>
						</form>
						<p>By Payment Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startdate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={enddate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="min_amount">Min Amount</label>
								<input
									name="min_amount"
									id="min_amount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="100,000"
								/>
							</div>
							<div>
								<label htmlFor="max_amount">Max Amount</label>
								<input
									name="max_amount"
									id="max_amount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="2,000,000"
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getTotalCollections()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getTotalCollections}
					/>
				)}
			</div>
			{fullTableShowing && (
				<Modal showing={fullTableShowing} toggle={toggleFullTable}>
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getTotalCollections}
					/>
				</Modal>
			)}
		</div>
	);
};

export default TotalCollections;
