import React, { useState } from "react";
import axios from "axios";
import styles from "../../Identity-management-corporate/style.module.css";
import style1 from "./style.module.css";
import ArrowLeft from "../../assets/arrowLeft.svg";
import { Link } from "react-router-dom";
const coy_ANSSID = process.env.REACT_APP_ANSSID

const StaffRecords = ({ prev, userId }) => {
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [employeesToUpload, setEmployeesToUpload] = useState([]);

	const getCompanyStaff = async () => {
		setLoading(true);
		try {
			let query = '';
			if (userId) {
				query += `userId=${userId}`;
			}
		
			const info = await axios
				.get(`/corporate/company/staff?${query}`)
				.then((res) => (res.data || {}).data);

			if (info) {
				setEmployeesToUpload(info);
			}
		} catch (err) {
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		getCompanyStaff();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const generateEmployeeForm = () => {
		return employeesToUpload.map((x, i) => (
			<React.Fragment key={"staff_upload_" + i}>
				<div className={styles.div__wrapper}>
					<div>
						<label htmlFor="surname">
							Staff's Last Name
						</label>
						<input
							type="text"
							name="surname"
							id="surname"
							className={styles.input__medium}
							value={x.surname}
							placeholder="eg John"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="firstName">
							Staff's First Name
						</label>
						<input
							type="text"
							name="firstName"
							id="firstName"
							className={styles.input__medium}
							value={x.firstName}
							placeholder="eg John"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="anssid">
							Staff's {coy_ANSSID}
						</label>
						<input
							type="number"
							name="anssid"
							id="anssid"
							className={styles.input__medium}
							value={x.anssid}
							placeholder="eg 8379302918"
							min={0}
							disabled
						/>
					</div>
					<div>
						<label htmlFor="email">
							Staff's Email Address
						</label>
						<input
							type="email"
							name="email"
							id="email"
							className={styles.input__medium}
							value={x.email}
							placeholder="eg email@staff.com"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="phoneNumber">
							Staff's Phone Number
						</label>
						<input
							type="text"
							name="phoneNumber"
							id="phoneNumber"
							className={styles.input__medium}
							value={x.phoneNumber}
							placeholder="eg +2349012345678"
							disabled
						/>
					</div>
				</div>
			</React.Fragment>
		));
	};

	return (
		<>
			<div className={style1.title__div}>
				<Link to={prev}>
					<img src={ArrowLeft} alt="left arrow" /> Prev
				</Link>
				<h1>Staff Records</h1>
			</div>
			<form className={styles.form}>
				{generateEmployeeForm()}
			</form>
		</>
	);
};

export default StaffRecords;
