import React from "react";
import axios from "axios";
import { useHistory } from "react-router";
import styles from "./style.module.css";
// import styles2 from "../MDAs/style.module.css";
import styles3 from "../../Identity-management-individual/style.module.css";

const CreateTaxPayer = () => {
	const history = useHistory();

	const [userId, setUserId] = React.useState("");
	const [accountType, setAccountType] = React.useState("individual");
	const [users, setUsers] = React.useState([]);

	const getTaxPayers = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			let { users } = await axios
				.get(`/reports/gettaxpayerslist`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || { data: {} }).data);

			if (!users) {
				// handle errors
				return null;
			}

			setUsers(users);
		} catch (err) {
			console.error(err);
		}
	};

	React.useEffect(() => {
		getTaxPayers();
	}, []);

	return (
		<form
			className={styles.form}
			onSubmit={(e) => {
				e.preventDefault();
				history.push(`/${accountType}-identity-management`, { userId });
			}}
		>
			<header>
				<h2>Create TaxPayer</h2>
				<p>Please choose the taxpayer's account to get started.</p>
			</header>
			<div className={styles3.double__inputs}>
				<div style={{ marginRight: 0 }}>
					<label
						style={{ marginBottom: "15px" }}
						className={styles3.input__label}
						htmlFor="user"
					>
						Type of TaxPayer <span style={{ color: "red" }}>*</span>
					</label>
					<ul
						className="flat__list"
						style={{
							marginTop: 0,
							marginBottom: 20,
							width: "19.8rem"
						}}
					>
						<li>
							<input
								type="radio"
								id="individual"
								name="taxPayerType"
								value="individual"
								onChange={(e) => {
									setAccountType(e.target.value);
								}}
							/>
							<label
								className={styles.radio__label}
								htmlFor="individual"
							>
								Individual
							</label>
						</li>
						<li>
							<input
								type="radio"
								id="corporate"
								name="taxPayerType"
								value="corporate"
								onChange={(e) => {
									setAccountType(e.target.value);
								}}
							/>
							<label
								className={styles.radio__label}
								htmlFor="corporate"
							>
								Organization
							</label>
						</li>
					</ul>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div style={{ marginRight: 0 }}>
					<label
						style={{ marginBottom: "15px" }}
						className={styles3.input__label}
						htmlFor="user"
					>
						User <span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="user"
						name="user"
						style={{ marginTop: 0 }}
						className={styles3.select__input}
						onChange={(e) => setUserId(e.target.value)}
						required
					>
						<option value="">Select...</option>
						{users &&
							users.map((user) => (
								<option key={user.id} value={user.id}>
									{`${user.firstname} ${
										user.middlename || ""
									} ${user.lastname}`}
								</option>
							))}
					</select>
				</div>
			</div>
			<button type="submit" className={styles.submit__btn}>
				Fill Identity Info
			</button>
		</form>
	);
};

export default CreateTaxPayer;
