/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import RegularModal from "../../components/Modal/regular";
import ArrowLeft from "../../assets/arrowLeft.svg";
import styles from "../../Biometrics/styles.module.css";
import style1 from "./style.module.css";

import { LGAContext } from ".";

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const Biometrics = ({ prev, userId, authorize, query }) => {
	const { LGA } = useContext(LGAContext);
	const { state } = useLocation();
	const { push } = useHistory();
	const [cardImage, setCardImage] = useState();
	const [loading, setLoading] = useState(false);
	const [proceed, setProceed] = useState(false);
	const [approveModalOpen, setApproveModalOpen] = useState(false);
	const [denyModalOpen, setDenyModalOpen] = useState(false);
	const [approvedModalOpen, setApprovedModalOpen] = useState(false);
	const [deniedModalOpen, setDeniedModalOpen] = useState(false);
	const [denialReasonModalOpen, setDenialReasonModalOpen] = useState(false);
	const [denialReason, setDenialReason] = useState("");
	const [lgas, setLgas] = useState([]);
	const [error, setError] = useState();
	const [otherDenialReasonInputShowing, setOtherDenialReasonInputShowing] =
		useState(false);
	const [otherDenialReason, setOtherDenialReason] = useState("");
	const [personalInfo, setPersonalInfo] = useState(null);

	const urlPrefix = userId ? "/admin/individual-taxpayer" : "";

	const getIdentityInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`/users/identity-proof?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setCardImage(info.biometricPhoto);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const getPersonalInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}
			const info = await axios
				.get(`/users?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setPersonalInfo(info);
			}
		} catch (err) {
			setError("Existing personal information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getIdentityInformation();
		getPersonalInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function closeModals() {
		setApproveModalOpen(false);
		setDenyModalOpen(false);
		setApprovedModalOpen(false);
		setDeniedModalOpen(false);
		setDenialReasonModalOpen(false);
	}

	const sendDenialSMS = async () => {
		try {
			let request = await axios.post(`/system/generateSMSeMail`, {
				userFone: personalInfo?.phonenumber,
				userEmail: personalInfo?.email,
				// msg: denialReason,
				// msg: otherDenialReason,
				msg: otherDenialReasonInputShowing
					? otherDenialReason
					: denialReason,
				fullName: `${personalInfo?.firstname} ${personalInfo?.surname}`
			});

			if (request) {
				setDeniedModalOpen(true);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setTimeout(() => {
				closeModals();
			}, 6000);
		}
	};

	async function handleApprove(e, approvalFlag) {
		e?.preventDefault();
		closeModals();
		try {
			let request = await axios.post("/anssidApprove", {
				Authoriser: JSON.parse(localStorage.getItem("user_info")).id,
				Comment:
					approvalFlag === 0
						? otherDenialReasonInputShowing
							? otherDenialReason
							: denialReason
						: "Approved",
				Approveflg: approvalFlag === 0 ? "3" : "1",
				anssidCode: userId
			});

			if (request) {
				if (approvalFlag === 0) {
					sendDenialSMS();
				} else {
					getAnssid();
				}
			}
		} catch (error) {
			console.error(error);
		}
	}

	const getAnssid = async () => {
		try {
			// if (anssid) return anssid;

			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const lgaoforigin = (lgas || []).find((l) => l.Description === LGA);
			const lgaId = lgaoforigin.Code;
			const res = await axios
				.post(`${urlPrefix}/system/generateuseranssid?${query}`, {
					lgaId,
					lgaCode: lgaId,
					lgaSerial: lgaId
				})
				.then((res) => (res.data || {}).data);

			if (!userId) {
				try {
					if (res.anssid && !userInfo.anssid) {
						localStorage.setItem(
							"user_info",
							JSON.stringify({ ...userInfo, anssid: res.anssid })
						);
					}
				} catch (e) {
					console.error(e);
				}
			}
			setApprovedModalOpen(true);

			return res.anssid;
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}
			setError(
				message || "An error occurred while generating user anssid."
			);
		}
	};

	const getLGAs = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/lga`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setLgas(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	useEffect(() => {
		getLGAs();
	}, []);

	useEffect(() => {
		if (denialReason === "Type in other reason") {
			setOtherDenialReasonInputShowing(true);
		}
	}, [denialReason]);

	return (
		<>
			<div className={style1.title__div}>
				<Link
					to={{
						pathname: prev,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					<img src={ArrowLeft} alt="left arrow" /> Prev
				</Link>
				<h1>Picture Biometrics</h1>
			</div>
			<form style={{ paddingBottom: 20 }}>
				<div className={styles.camera__box}>
					<div
						style={{
							position: "relative",
							width: "100%",
							height: "100%",
							borderRadius: "8px"
						}}
					>
						{cardImage && (
							<img
								className={styles.preview}
								id="inputImg"
								src={cardImage}
								alt="passport"
							/>
						)}
					</div>
				</div>
			</form>
			{authorize && (
				<div className={style1.btn__div}>
					<button
						className="secondary__btn"
						onClick={
							proceed
								? () => setDenyModalOpen(true)
								: () => push("/biometrics")
						}
					>
						{proceed ? "Deny" : "Check Image"}
					</button>
					<button
						className="primary__btn"
						onClick={
							proceed
								? () => setApproveModalOpen(true)
								: () => setProceed(true)
						}
					>
						{proceed ? "Approve" : "Continue"}
					</button>
				</div>
			)}
			{approveModalOpen && (
				<RegularModal showing={approveModalOpen} toggle={closeModals}>
					<p className={style1.prompt__text}>
						Are you sure you want to "
						<span style={{ color: "var(--green-02)" }}>
							APPROVE
						</span>
						" this Individual?
					</p>
					<div className={style1.modal__btn_div}>
						<button
							className="secondary__btn"
							onClick={() => closeModals()}
						>
							No
						</button>
						<button
							className="primary__btn"
							onClick={() => {
								setApproveModalOpen(false);
								handleApprove(null, 1);
							}}
						>
							Yes
						</button>
					</div>
				</RegularModal>
			)}
			{denyModalOpen && (
				<RegularModal showing={denyModalOpen} toggle={closeModals}>
					<p className={style1.prompt__text}>
						Are you sure you want to "
						<span style={{ color: "var(--red-02)" }}>DENY</span>"
						this Individual?
					</p>
					<div className={style1.modal__btn_div}>
						<button className="secondary__btn">No</button>
						<button
							className="primary__btn"
							onClick={() => {
								setDenyModalOpen(false);
								setDenialReasonModalOpen(true);
							}}
						>
							Yes
						</button>
					</div>
				</RegularModal>
			)}
			{approvedModalOpen && (
				<RegularModal showing={approvedModalOpen} toggle={closeModals}>
					<p className={style1.prompt__text}>
						This user has been approved
					</p>
					<Link to="/anssid/authorize">Go to Home</Link>
				</RegularModal>
			)}
			{denialReasonModalOpen && (
				<RegularModal
					showing={denialReasonModalOpen}
					toggle={closeModals}
				>
					<p className={style1.prompt__text}>
						Why did you deny this user?
					</p>
					<form
						className={style1.denial__reason_form}
						onSubmit={(e) => handleApprove(e, 0)}
					>
						<select
							value={denialReason}
							onChange={(e) => {
								if (e.target.value !== "Type in other reason") {
									setOtherDenialReasonInputShowing(false);
								}
								setDenialReason(e.target.value);
							}}
						>
							<option value="">Select a reason</option>
							<option>
								Unacceptable picture quality
							</option>
							<option>Incorrect Personal data (page 1)</option>
							<option>Incorrect Other Information data (page 2)</option>
							<option>Incorrect Family Information data (page 3)</option>
							<option>
								Incorrect Employment Information data (page 4)
							</option>
							<option>
								Incorrect proof of identity (page 5)
							</option>
							<option value="Type in other reason">Other</option>
						</select>
						{otherDenialReasonInputShowing && (
							<textarea
								value={otherDenialReason}
								placeholder="Type in other reason"
								onChange={(e) =>
									setOtherDenialReason(e.target.value)
								}
							></textarea>
						)}
						<button
							type="submit"
							className="primary__btn"
							disabled={
								!denialReason ||
								(denialReason === "Type in other reason" &&
									!otherDenialReason)
							}
						>
							Send
						</button>
					</form>
				</RegularModal>
			)}
			{deniedModalOpen && (
				<RegularModal showing={deniedModalOpen} toggle={closeModals}>
					<p className={style1.prompt__text}>
						This user has been denied.
					</p>
					<Link to="/anssid/authorize">Go to Home</Link>
				</RegularModal>
			)}
		</>
	);
};

export default Biometrics;
