import styles from "./final.module.css";
import DoneFileIcon from "../assets/donefile.svg";
import { useLocation, useHistory } from "react-router-dom";
const coy_ANSSID = process.env.REACT_APP_ANSSID

const Final = () => {
	const {
		state: { anssid }
	} = useLocation();
	const history = useHistory();

	return (
		<>
			<header className={styles.header}>
				<h3>{coy_ANSSID}</h3>
			</header>
			<div className={styles.main__content}>
				<img src={DoneFileIcon} alt="done file" />
				<p className={styles.finito}>Finito!</p>
				<p className={styles.message}>
					Data saved successfully.
					<br />
					Your Organization {coy_ANSSID} is{" "}
					<span style={{ fontSize: 20, fontWeight: "bolder" }}>
						{anssid}
					</span>
					.
					<br />
					Please note that this number is confidential and should be
					kept safe.
				</p>
				<button
					className="primary__btn"
					onClick={() => history.push("/anssid/corporate")}
				>
					Go to Home
				</button>
			</div>
		</>
	);
};

export default Final;
