/* eslint-disable no-unused-vars */
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import ReactToPrint from "react-to-print";
import styles from "./style.module.css";

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const CorporatePrint = () => {
	const componentRef = useRef();

	const [values, setValue] = useState({
		name: "",
		headOfficeAddress: "",
		stateOfficeAddress: "",
		city: "Anambra",
		lga: "",
		townOfResidence: "",
		cityOfResidence: "",
		phoneNumber: userInfo.phonenumber || "",
		email: userInfo.email || "",
		rcNumber: "",
		businessCategory: "",
		economicSector: "",
		numberOfStaff: "",
		registrationDate: "",
		commencementDate: "",
		taxOffice: ""
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const getCompanyDetails = async () => {
		setLoading(true);
		try {
			const info = await axios
				.get(`/corporate/company`)
				.then((res) => (res.data || {}).data);

			if (info) {
				setValue({
					...info,
					phoneNumber: info.phoneNumber || values.phoneNumber,
					email: info.email || values.email
				});
			}
		} catch (err) {
			setError("Existing company information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCompanyDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className={styles.print__details}>
			<div className={styles.header}>
				<h1>Print Details</h1>
				<ReactToPrint
					trigger={() => (
						<button
							className={["primary__btn", styles.print__btn].join(
								" "
							)}
						>
							Print Details
						</button>
					)}
					content={() => componentRef.current}
				/>
			</div>
			<main ref={componentRef}>
				<div className={styles.personal__details__div}>
					<div className={styles.personal__details}>
						<h2>Company's Details</h2>
						<table>
							<tbody>
								<tr>
									<th>Company Name</th>
									<td>{values.name}</td>
								</tr>
								<tr>
									<th>Company's Head Office Address</th>
									<td>{values.headOfficeAddress}</td>
								</tr>
								<tr>
									<th>State Office Address</th>
									<td>{values.stateOfficeAddress}</td>
								</tr>
								<tr>
									<th>City</th>
									<td>{values.city}</td>
								</tr>
								<tr>
									<th>Local Government Area</th>
									<td>{values.lga}</td>
								</tr>
								<tr>
									<th>City of Residence</th>
									<td>{values.cityOfResidence}</td>
								</tr>
								<tr>
									<th>Company Phone no</th>
									<td>{values.phoneNumber}</td>
								</tr>
								<tr>
									<th>Company Email Address</th>
									<td>{values.email}</td>
								</tr>
								<tr>
									<th>Company R.C no/BN no</th>
									<td>{values.rcNumber}</td>
								</tr>
								<tr>
									<th>Category of Business</th>
									<td>{values.businessCategory}</td>
								</tr>
								<tr>
									<th>Sector of Economy</th>
									<td>{values.economicSector}</td>
								</tr>
								<tr>
									<th>No of Employees in the State</th>
									<td>{values.numberOfStaff}</td>
								</tr>
								<tr>
									<th>Registration Date</th>
									<td>
										{new Date(
											values.registrationDate
										).toLocaleDateString()}
									</td>
								</tr>
								<tr>
									<th>Business Commencement Date</th>
									<td>
										{new Date(
											values.commencementDate
										).toLocaleDateString()}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<hr />
			</main>
		</section>
	);
};

export default CorporatePrint;
