import { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./signup.module.css";
import axios from "axios";

import Sidebar from "../../components/sidebar";
// import showPasswordIcon from "../../assets/showPassword.svg";

function formatDate(date) {
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

const GetSignup = () => {
	const [firstname, setFirstName] = useState("");
	const [lastname, setLastName] = useState("");
	const [phonenumber, setPhonenumber] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [tin, setTIN] = useState("");
	const [tinDoB, setTINDoB] = useState("");
	const [tinError, setTinError] = useState("");
	const [currentTab, setCurrentTab] = useState("individual");


	const [error, setError] = useState();
	const [fetching, setFetching] = useState(false);


	const jtbChecks = async (e) => {
		//e.preventDefault();
		setError("");

		const chk = e;
		console.log('chk >>>> ', chk)
		setTinError("")
		setFirstName("")		// clear textbox
		setLastName("")		// clear textbox
		setPhonenumber("")		// clear textbox
		setEmail("")		// clear textbox
		setTINDoB("")

		if (chk !== "") {
			try {
				// setTINname("")		// clear textbox
				let urlPrefix = ""

				const accessToken = localStorage.getItem("access_token");
				await axios
					.post(
						`${urlPrefix}/txn/jtb/verifyInd`,
						{
							"tin": chk,
							"userId": ""
						},
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => {
						console.log('TIN Name >>> ', res?.data?.Taxpayer?.last_name, ' ' + res?.data?.Taxpayer?.first_name + ' ' + res?.data?.Taxpayer?.middle_name)

						console.log(res);
						console.log('JTBValidatedData  >>>>  ', res?.data?.JTBValidatedData)
						if (res?.data?.ResponseCode === "004") {
							setTinError('TIN ' + res?.data?.ResponseDescription)
							//setBtnSubmitDisabled(true);
							setError('TIN ' + res?.data?.ResponseDescription)
						} else if (res?.data?.ResponseCode === "001") {
							//const name = res?.data?.Taxpayer?.last_name + ' ' + res?.data?.Taxpayer?.first_name + ' ' + res?.data?.Taxpayer?.middle_name
							//---
							const dg = res?.data?.Taxpayer?.date_of_birth;
							var kl = dg.split('-');
							var nDate = "";
							// console.log(kl[2].length);
							if (kl[2].length === 4) {
								nDate = kl[2] + '-' + kl[1] + '-' + kl[0];
							}
							//---
							// console.log('nDate = ', nDate)
							// console.log('nDate = ', formatDate(new Date(nDate)));

							setTinError("")
							setError("");
							setTINDoB(formatDate(new Date(nDate)));
							setFirstName(res?.data?.Taxpayer?.first_name)
							setLastName(res?.data?.Taxpayer?.last_name)
							setPhonenumber(res?.data?.Taxpayer?.phone_no)
							setEmail(res?.data?.Taxpayer?.email)
							setPassword("")
							setConfirmPassword("")
						}
					})
			} catch (err) {
				let message = "";
				if (err.response) {
					message =
						err.response.data.errors?.details[0].message ||
						err.response.data?.error ||
						err.response.data?.errors;
				} else {
					message = err.message;
				}
				setError(
					message ||
					"Something goes wrong. Unable to submit your request. "
				);
			}
		}
	};


	const signup = async (e) => {
		e.preventDefault();
		setFetching(true);

		if (password !== confirmPassword) {
			alert("Password mismatch.");
			return;
		}

		if (tinError !== "") {
			setError(`Error!!!  ${tinError}`)
		}

		try {
			const res = await axios
				.post(`/auth/signup`, {
					// ...values,
					firstname,
					lastname,
					phonenumber,
					email,
					password,
					tin,
					tinDoB,
					accounttype: currentTab,
					confirmPassword: undefined,
					ssActive: "0"
				})
				.then((res) => res.data);

			if (!res.data) throw new Error("An error has occurred");

			// localStorage.setItem("access_token", res.data.access_token);
			// localStorage.setItem("user_info", JSON.stringify({ ...res.data, access_token: undefined }));

			window.location.href = "/otp-phone";
		} catch (err) {
			console.error(err);
			let message = "An error has occurred on signup";
			if (/409/.test(err.message)) {
				message = "Email or Phone number is already in use.";
			}
			setError(message);
		} finally {
			setFetching(false);
		}
	};

	return (
		<div className={styles.signup_page}>
			<Sidebar signup />
			<main className={styles.auth__form__container}>
				{error && <div className={styles.error}>{error}</div>}
				<div className={styles.top}>
					<div className={styles.mobile__logo}>
						<img src="/anssid_logo.png" alt="anssid logo" />
					</div>
					<h1>Sign Up</h1>
					<p>
						Already have an account?{" "}
						<NavLink to="/login">Log in</NavLink>
					</p>
				</div>
				<div className={styles.bottom}>
					<p>
						Please fill in your correct details in ALL the fields on
						this screen and Click Sign Up to continue
					</p>
					<nav className={styles.tabs}>
						<button
							className={
								currentTab === "individual"
									? styles.active_tab
									: null
							}
							onClick={() => setCurrentTab("individual")}
						>
							Individual
						</button>
						{/* <button
								className={
									currentTab === "corporate"
										? styles.active_tab
										: null
								}
								onClick={() => setCurrentTab("corporate")}
							>
								Corporate
							</button> */}
					</nav>
					<form className={styles.form} onSubmit={signup}>
						<label htmlFor="tin">
							Tax Identification Number(TIN) <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="tin"
							name="tin"
							type="text"
							placeholder="Input your TIN"
							// onChange={handleChange}[]
							onChange={(e) => {
								setTIN(e.target.value);
								jtbChecks(e.target.value)
							}}
							// value={values.tin}
							value={tin}
						// required
						/>
						<label htmlFor="firstname">
							First Name <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="firstname"
							name="firstname"
							type="text"
							placeholder="John"
							// onChange={handleChange}
							// value={values.firstname}
							onChange={(e) => {
								setFirstName(e.target.value);
							}}
							value={firstname}
							required
						/>
						<label htmlFor="lastname">
							Surname <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="lastname"
							name="lastname"
							type="text"
							placeholder="Doe"
							// onChange={handleChange}
							// value={values.lastname}
							onChange={(e) => {
								setLastName(e.target.value);
							}}
							value={lastname}
							required
						/>
						<label htmlFor="email">
							Email Address{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="email"
							name="email"
							type="email"
							placeholder="john.doe@gmail.com"
							// onChange={handleChange}
							// value={values.email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							value={email}
							required
						/>
						<label htmlFor="phonenumber">
							Phone no <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="phonenumber"
							name="phonenumber"
							type="text"
							placeholder="+2348012345678"
							// onChange={handleChange}
							// value={values.phonenumber}
							onChange={(e) => {
								setPhonenumber(e.target.value);
							}}
							value={phonenumber}
							required
						/>
						<hr className={styles.hr} />
						<label htmlFor="password">
							Password <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="password"
							name="password"
							type="password"
							placeholder="******"
							// hidden={false}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							// value={values.password}
							value={password}
							required
						/>
						<label htmlFor="password">
							Confirm Password{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="confirmPassword"
							name="confirmPassword"
							type="password"
							placeholder="******"
							onChange={(e) => {
								setConfirmPassword(e.target.value);
							}}
							// value={values.confirmPassword}
							value={confirmPassword}
							required
						/>
						{/* <button>
								<img
									src={showPasswordIcon}
									alt="show password icon"
								/>
							</button>
						</div> */}
						<button
							type="submit"
							className={`${styles.signup_btn} primary__btn`}
							disabled={
								!email ||
								!phonenumber ||
								!password ||
								!confirmPassword ||
								confirmPassword !== password ||
								fetching
							}
						>
							{fetching ? "Signing up ..." : "Sign Up"}
						</button>
					</form>
				</div>
			</main>
		</div>
	);
};

// export default SignUp;
export default GetSignup;
