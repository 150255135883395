import { useHistory, useLocation } from "react-router-dom";
import styles from "../Identity-management-corporate/final.module.css";
import DoneFileIcon from "../assets/donefile.svg";

const Final = () => {
	const user = JSON.parse(localStorage.getItem("user_info"));
	const location = useLocation();
	const history = useHistory();
	const { anssid, firstname, lastname } = location.state;

	return (
		<>
			<header className={styles.header}>
				<h3>ENSCIMS</h3>
			</header>
			<div className={styles.main__content}>
				<img src={DoneFileIcon} alt="done file" />
				<p className={styles.finito}>Finito!</p>
				<p className={styles.message}>
					{user.ssActive === "2" ? (
						<>
							Hello {firstname} {lastname}, your data has been
							saved successfully.
							<br /> Your Individual ESBN is{" "}
							<span
								style={{ fontSize: 20, fontWeight: "bolder" }}
							>
								{anssid}
							</span>
							.
							<br />
							Please note that this number is confidential and
							should be kept safe.
						</>
					) : (
						<>
							Information successfully received and is now being
							sent for processing. If successful, your ANSSID
							number will be sent to your email address and phone
							number as provided. Otherwise, an email will be sent
							to you explaining the reason why it was not
							successful for you to amend accordingly.
						</>
					)}
				</p>
				<button
					className="primary__btn"
					onClick={() => {
						if (user?.ssActive === "2") {
							history.push("/anssid/individual");
						} else {
							history.push("/login");
						}
					}}
				>
					Close
				</button>
			</div>
		</>
	);
};
export default Final;
