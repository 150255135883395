/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import ArrowRight from "../../assets/arrowRight.svg";
import ArrowLeft from "../../assets/arrowLeft.svg";
import styles from "../../Identity-management-individual/style.module.css";
import style1 from "./style.module.css";

import { LGAContext } from ".";

const OtherInfo = ({ next, prev, userId, query }) => {
	const { LGA, setLGA } = useContext(LGAContext);
	const { state } = useLocation();
	const [nationality, setNationality] = useState("Nigeria");
	const [stateoforigin, setStateOfOrigin] = useState("Anambra");
	const [townOfOrigin, setTownOfOrigin] = useState("");
	const [lgaoforigin, setLgaOfOrigin] = useState("");
	const [residentialaddress, setResidentialAddress] = useState("");
	const [stateofresidence, setStateOfResidence] = useState("Anambra");
	const [lgaofresidence, setLgaOfResidence] = useState("");
	const [townofresidence, setTownOfResidence] = useState("");
	const [cityofresidence, setCityOfResidence] = useState("");
	const [nin, setNin] = useState("");
	const [bvn, setBvn] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	// const [tin, setTIN] = useState("");
	// const [tinName, setTINname] = useState("");

	const getOtherInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}
console.log('query (location) -> ', query)
			const info = await axios
				.get(`/users/location?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setNationality(info.nationality || "");
				setStateOfOrigin(info.stateoforigin || "");
				setLgaOfOrigin(info.lgaoforigin || "");
				setTownOfOrigin(info.townoforigin || "");
				setLGA(info.lgaoforigin || "");
				setResidentialAddress(info.residentialaddress || "");
				setStateOfResidence(info.stateofresidence || "");
				setLgaOfResidence(info.lgaofresidence || "");
				setTownOfResidence(info.townofresidence || "");
				// setCityOfResidence(info.cityofresidence || "");
				setNin(info.nin || "");
				setBvn(info.bvn || "");
				// setTIN(info.tin || "");
				// setTINname(info.tinName || "");
			}
		} catch (err) {
			setError("Existing information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getOtherInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={style1.title__div}>
				<Link
					to={{
						pathname: prev,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					<img src={ArrowLeft} alt="left arrow" /> Prev
				</Link>
				<h1>Other Information</h1>
				<Link
					to={{
						pathname: next,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					Next <img src={ArrowRight} alt="right arrow" />
				</Link>
			</div>
			<form className={styles.columned__form}>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="nationality"
						>
							Nationality
						</label>
						<input
							type="text"
							id="nationality"
							value={nationality}
							disabled
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="stateOfOrigin"
						>
							State of Origin
						</label>
						<input
							type="text"
							id="stateOfOrigin"
							value={stateoforigin}
							disabled
						/>
					</div>
				</div>
				{/nigeria/i.test(nationality) && stateoforigin && (
					<>
						<label
							className={styles.input__label}
							htmlFor="lgaOfOrigin"
						>
							LGA of Origin
						</label>
						<input
							type="text"
							id="lgaoforigin"
							value={lgaoforigin}
							disabled
						/>
					</>
				)}
				<div>
					<label
						className={styles.input__label}
						htmlFor="townOfOrigin"
					>
						Town of Origin
					</label>
					<input
						type="text"
						id="townOfOrigin"
						value={townOfOrigin}
						disabled
					/>
				</div>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="nin">
							NIN
						</label>
						<input type="text" id="nin" value={nin} disabled />
					</div>
					<div>
						<label className={styles.input__label} htmlFor="bvn">
							BVN
						</label>
						<input type="text" id="bvn" value={bvn} disabled />
					</div>
				</div>
				{/* <div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="tin">
							TIN
						</label>
						<input type="text" id="tin" value={tin} disabled />
					</div>
					<div>
						<label className={styles.input__label} htmlFor="nin">
							Name of TIN
						</label>
						<input type="text" id="tinName" value={tinName} disabled />
					</div>
				</div> */}
				<hr className={styles.divider} />
				<label
					className={styles.input__label}
					htmlFor="residentialAddress"
				>
					Residential Address
				</label>
				<input
					type="text"
					id="residentialAddress"
					long="true"
					value={residentialaddress}
					disabled
				/>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="stateOfResidence"
						>
							State of Residence
						</label>
						<input
							type="text"
							id="stateofresidence"
							value={stateofresidence}
							disabled
						/>
					</div>
					{stateofresidence && (
						<div>
							<label
								className={styles.input__label}
								htmlFor="lgaOfResidence"
							>
								Local Government Area of Residence
							</label>
							<input
								type="text"
								id="lgaofresidence"
								value={lgaofresidence}
								disabled
							/>
						</div>
					)}
				</div>
				<label
					className={styles.input__label}
					htmlFor="townOfResidence"
				>
					Town of Residence
				</label>
				<input
					type="text"
					id="townofresidence"
					value={townofresidence}
					disabled
				/>
				{/* <label
					className={styles.input__label}
					htmlFor="cityOfResidence"
				>
					City of Residence
				</label>
				<input
					type="text"
					id="cityofresidence"
					value={cityofresidence}
					disabled
				/> */}
			</form>
		</>
	);
};

export default OtherInfo;
