import { Link } from "react-router-dom";
import styles from "../../Identity-management-corporate/final.module.css";
import authStyles from "../Login/style.module.css";
import DoneFileIcon from "../../assets/donefile.svg";

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const Success = ({ message }) => {
	return (
		<main
			className={authStyles.auth__form__container}
			style={{ width: "500px" }}
		>
			<header className={styles.header}>
				<h3>ENSCIMS</h3>
			</header>
			<div className={styles.main__content}>
				<img src={DoneFileIcon} alt="done file" />
				<p className={styles.finito}>Success!</p>
				<p className={styles.message}>{message}</p>
				<button className="primary__btn">
					<Link to="/login">Login</Link>
				</button>
			</div>
		</main>
	);
};
export default Success;
