import React, { useState } from "react";
import {
	NavLink,
	useHistory,
	useLocation,
	useRouteMatch
} from "react-router-dom";
import ArrowUp from "../../assets/arrowUp.svg";
import ArrowDown from "../../assets/arrowDown.svg";
import Avatar from "../../assets/Sidenav/avatar.svg";
import styles from "./style.module.css";

import analyticsIcon from "../../assets/Sidenav/analytics.svg";
import anssidIcon from "../../assets/Sidenav/anssid.svg";
import billsIcon from "../../assets/Sidenav/bills.svg";
import changePasswordIcon from "../../assets/Sidenav/changePassword.svg";
import dashboardIcon from "../../assets/Sidenav/dashboard.svg";
import logoutIcon from "../../assets/Sidenav/logout.svg";
import mdasIcon from "../../assets/Sidenav/mdas.svg";
import receiptsIcon from "../../assets/Sidenav/receipts.svg";
import settingsIcon from "../../assets/Sidenav/settings.svg";
import totalCollectionIcon from "../../assets/Sidenav/totalCollections.svg";
import userManagementIcon from "../../assets/Sidenav/userManagement.svg";

// import Logo from "../../assets/logo.svg";
// let hj = "norlicLogoNew.svg"
// import Logo from hj  //"../../assets/norlicLogoNew.svg";
const coy_ANSCIMS_LOGO = process.env.REACT_APP_ANSCIMS_SIDEBBAR_LOGO
const coy_ANSSID = process.env.REACT_APP_ANSSID

// var path = require("path")
// const coy_ANSCIMS_LOGO = require(`../../assets/Home/${process.env.REACT_APP_ANSCIMS_SIDEBBAR_LOGO}`);
// const coy_ANSCIMS_LOGO = require(path.join("../../assets/Home/", process.env.REACT_APP_ANSCIMS_SIDEBBAR_LOGO));

let userInfo;
try {
	userInfo = JSON.parse(localStorage.getItem("user_info"));
} catch (e) {
	userInfo = {};
}

const Sidenav = ({ navRef, menuRef, toggleSidebar }) => {
	let { url } = useRouteMatch();
	const history = useHistory();

	const { pathname: currentLocation } = useLocation();
	const [settingsSubMenuOpen, toggleSettingsSubMenu] = useState(
		currentLocation.split("/")[1] === "settings" || false
	);
	const [hasSettings, setHasSettings] = useState(false);
	const [userMgntSubMenuOpen, toggleUserMgntSubMenu] = useState(
		currentLocation.split("/")[1] === "user-management" || false
	);
	const [hasUserMgnt, setHasUserMgnt] = useState(false);
	const [billsSubMenuOpen, toggleBillsSubMenu] = useState(
		currentLocation.split("/")[1] === "bills" || false
	);
	const [hasBills, setHasBills] = useState(false);
	const [analyticsSubMenuOpen, toggleAnalyticsSubMenu] = useState(
		currentLocation.split("/")[1] === "analytics" || false
	);
	const [hasAnalytics, setHasAnalytics] = useState(false);
	const [anssidSubMenuOpen, toggleAnssidSubMenu] = useState(
		currentLocation.split("/")[1] === "anssid" || false
	);
	const [hasAnssid, setHasAnssid] = useState(false);
	const [receiptSubMenuOpen, toggleReceiptSubMenu] = useState(
		currentLocation.split("/")[1] === "receiptprt" || false
	);
	const [hasReceipt, setHasReceipt] = useState(false);

	const allowedRoutes = (userInfo?.roles?.[0]?.Claims || []).reduce(
		(acc, cl) => {
			acc[cl.Route] = true;
			return acc;
		},
		{}
	);

	const routes = {
		[`${url}`]: {
			title: "Dashboard",
			params: {
				exact: true
			},
			icon: dashboardIcon
		},
		[`${url}total-collections`]: {
			title: "Total Collections",
			icon: totalCollectionIcon
		},
		[`${url}bills`]: {
			title: "Bills",
			icon: billsIcon,
			state: billsSubMenuOpen,
			shouldShow: hasBills,
			setShouldShow: setHasBills,
			onClick: () => {
				toggleBillsSubMenu(!billsSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "bills"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}bills/cleared-bills`]: {
					title: "Cleared Bills"
				},
				[`${url}bills/uncleared-bills`]: {
					title: "Uncleared Bills"
				}
			}
		},
		[`${url}mdas`]: {
			title: "MDAs",
			icon: mdasIcon
		},
		[`${url}anssid`]: {
			title: coy_ANSSID,
			icon: anssidIcon,
			state: anssidSubMenuOpen,
			shouldShow: hasAnssid,
			setShouldShow: setHasAnssid,
			onClick: () => {
				toggleAnssidSubMenu(!anssidSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "anssid"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}anssid/authorize`]: {
					title: "Authorize"
				},
				[`${url}anssid/individual`]: {
					title: `${coy_ANSSID} Individual`
				},
				[`${url}anssid/corporate`]: {
					title: `${coy_ANSSID} Organization`
				},
				[`${url}anssid/audit`]: {
					title: `${coy_ANSSID} - Auditor`
				}
			}
		},
		[`${url}analytics`]: {
			title: "Analytics",
			icon: analyticsIcon,
			state: analyticsSubMenuOpen,
			shouldShow: hasAnalytics,
			setShouldShow: setHasAnalytics,
			onClick: () => {
				toggleAnalyticsSubMenu(!analyticsSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "analytics"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}analytics`]: {
					title: "JTB Report"
				},
				[`${url}lgaanalytics`]: {
					title: "LGA Analytics"
				},
				[`${url}mdaanalytics`]: {
					title: "MDA Analytics"
				},
				[`${url}revenueheadanalytics`]: {
					title: "Revenue Head"
				},
				[`${url}handlingsystemanalytics`]: {
					title: "Handling System"
				},
				[`${url}billgroupanalytics`]: {
					title: "Billing Group"
				},
				[`${url}mdaoptionsanalytics`]: {
					title: "Top & Bottom MDAs"
				}
			}
		},
		[`${url}receipt`]: {
			title: "Receipt",
			icon: receiptsIcon,
			state: receiptSubMenuOpen,
			shouldShow: hasReceipt,
			setShouldShow: setHasReceipt,
			onClick: () => {
				toggleReceiptSubMenu(!receiptSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "receiptprt"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}receiptprt/receiptref`]: {
					title: "By Payment Reference"
				},
				//				[`${url}receiptprt/receiptours`]: {
				//					title: "By Norlics Receipt No."
				//				},
				[`${url}receiptprt/receiptswitch`]: {
					title: "By Receipt No."
				},
				[`${url}receiptprt/receiptinv`]: {
					title: "By Invoice Number"
				}
			}
		},
		[`${url}user-management`]: {
			title: "User Management",
			icon: userManagementIcon,
			state: userMgntSubMenuOpen,
			shouldShow: hasUserMgnt,
			setShouldShow: setHasUserMgnt,
			onClick: () => {
				toggleUserMgntSubMenu(!userMgntSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "user-management"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}user-management/manage-users`]: {
					title: "Manage Users"
				},
				[`${url}user-management/manage-claims`]: {
					title: "Manage Claims"
				},
				[`${url}user-management/manage-roles`]: {
					title: "Manage Roles"
				},
				[`${url}user-management/anssid-agent`]: {
					title: `View ${coy_ANSSID} Agent`
				},
				[`${url}user-management/deactivate-agent`]: {
					title: "Deactivated Agent"
				}
			}
		},
		[`${url}settings`]: {
			title: "Settings",
			icon: settingsIcon,
			state: settingsSubMenuOpen,
			shouldShow: hasSettings,
			setShouldShow: setHasSettings,
			onClick: () => {
				toggleSettingsSubMenu(!settingsSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "settings"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}settings/id-details`]: {
					title: "ID Details"
				},
				[`${url}settings/ANSSID-setup`]: {
					title: `${coy_ANSSID} Setup`
				},
				[`${url}settings/monthly-salary-range`]: {
					title: "Monthly Salary Range"
				},
				[`${url}settings/revenue-details`]: {
					title: "Revenue Details"
				}
			}
		}
	};

	return (
		<>
			<nav className={styles.nav} ref={navRef}>
				<div className={styles.logo}>
					{/* <img src={Logo} alt="logo" /> */}
					<img src={coy_ANSCIMS_LOGO} alt="logo" />
				</div>
				<div className={styles.mobile__top}>
					<div>
						<img src={Avatar} alt="avatar" />
						<p>
							{userInfo.fullname}
							<br /> <span>{userInfo.email}</span>
						</p>
					</div>
					<button
						aria-labelledby="close sidebar"
						onClick={toggleSidebar}
					>
						X
					</button>
				</div>
				<ul className={styles.nav__list}>
					{Object.keys(routes).map((route, i) => {
						const routeInfo = routes[route];
						if (!routeInfo.children) {
							if (!allowedRoutes[route]) return undefined;

							return (
								<li key={i}>
									<div>
										<NavLink
											exact
											to={route}
											activeClassName={styles.active}
											className={styles.nav__button}
										>
											<span>
												{routeInfo.icon && (
													<img
														src={routeInfo.icon}
														alt={`${routeInfo.title}`}
													/>
												)}
												{routeInfo.title}
											</span>
										</NavLink>
									</div>
								</li>
							);
						} else {
							return (
								<li
									className={
										!routeInfo.shouldShow
											? styles.no__display
											: ""
									}
									key={i}
								>
									{routeInfo.title === "User Management" && (
										<hr className={styles.divider} />
									)}
									<div>
										<button
											id={`${route}DropdownButton`}
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
											aria-label={routeInfo.title}
											className={routeInfo.className}
											onClick={routeInfo.onClick}
										>
											<span>
												<img
													src={routeInfo.icon}
													alt={`${routeInfo.title}`}
												/>
												{routeInfo.title}
											</span>
											<img
												src={
													routeInfo.state
														? ArrowUp
														: ArrowDown
												}
												alt="arrow icon"
											/>
										</button>
									</div>

									<div>
										<ul
											id={`${route}Dropdown`}
											aria-labelledby={`${route}DropdownButton`}
											className={styles.nav__list}
											style={{ margin: 0 }}
										>
											{Object.keys(
												routeInfo.children
											).map((child, index) => {
												const childInfo =
													routeInfo.children[child];
												if (!allowedRoutes[child])
													return undefined;

												!routeInfo.shouldShow &&
													routeInfo.setShouldShow(
														true
													);

												return (
													<>
														{routeInfo.state && (
															<li key={index}>
																<div
																	style={{
																		padding: 0
																	}}
																>
																	<NavLink
																		activeClassName={
																			styles.active
																		}
																		to={
																			child
																		}
																		className={
																			styles.nested__nav__list
																		}
																	>
																		{
																			childInfo.title
																		}
																	</NavLink>
																</div>
															</li>
														)}
													</>
												);
											})}
										</ul>
									</div>
								</li>
							);
						}
					})}
					<li>
						<hr className={styles.divider} />
						<div>
							<NavLink
								exact
								to="/password"
								activeClassName={styles.active}
								className={styles.nav__button}
							>
								<span>
									<img
										src={changePasswordIcon}
										alt="change password"
									/>
									Change Password
								</span>
							</NavLink>
						</div>
					</li>
					<li>
						<div>
							<button
								className={styles.nav__button}
								onClick={() => {
									localStorage.removeItem("user_info");
									localStorage.removeItem("access_token");
									history.push("/login");
								}}
							>
								<span>
									<img src={logoutIcon} alt="logout" />
									Logout
								</span>
							</button>
						</div>
					</li>
				</ul>
			</nav>
			<div className={styles.menu__toggle} ref={menuRef}>
				<button
					className={styles.menu__button}
					onClick={() => {
						navRef.current.classList.toggle(styles.no__display);
						menuRef.current.classList.toggle(
							styles.collapsed__menu
						);
					}}
				></button>
				<div
					onClick={() => {
						navRef.current.classList.add(styles.no__display);
						menuRef.current.classList.add(styles.collapsed__menu);
					}}
				></div>
			</div>
			<div className={styles.background} onClick={toggleSidebar} />
		</>
	);
};

export default Sidenav;
