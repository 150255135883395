import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router";

import Sidebar from "../../components/sidebar";
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";

const Login = () => {
	const location = useLocation();
	console.log('anscims --> ', location?.state?.anscims)
	const isAnscims = location?.state?.anscims

	const [values, setValue] = useState({
		email: "",
		password: ""
	});

	const [fetching, setFetching] = useState(false);
	const [error, setError] = useState();
	const coy_ANSSID_LOGO = process.env.REACT_APP_ANSSID_LOGO
	const coy_ANSCIMS_LOGO = process.env.REACT_APP_ANSCIMS_LOGO
	// const coylogo2 = process.env.REACT_APP_ANSCIMS_MOBILE_LOGO
	// const coylogo2 = process.env.REACT_APP_ANSCIMS_SIDEBBAR_LOGO


	function handleChange(e) {
		const name = e.target.name;
		const value = e.target.value;

		setValue({
			...values,
			[name]: value
		});
	}

	const signin = async (e) => {
		e.preventDefault();

		try {
			setFetching(true);
			const res = await axios
				.post(`/auth/login`, {
					...values
				})
				.then((res) => res.data);
			if (!res.data) throw new Error("Invalid Login Details.");

			// axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`;

			// console.log("access token ", res.data.access_token);
			// console.log("headers ", axios.defaults)

			localStorage.setItem("access_token", res.data.access_token);
			localStorage.setItem(
				"user_info",
				JSON.stringify({ ...res.data, access_token: undefined })
			);

			setFetching(false);
			window.location.href = "/";
		} catch (err) {
			//console.log('err  >>> ',err.response.data.message)
			setFetching(false);
			if (/401/.test(err.message)) {
				window.location.href = "/otp-phone";
			}
			//setError(/404/.test(err.message) ? "Invalid Login Details" : "An error occurred");
			setError(/404/.test(err.message) ? "Invalid Login Details" : err.response?.data.message);
			console.error(err);
		}
	};

	return (
		<section className={styles.wrapper}>
			<Sidebar login isAnscims={isAnscims} />
			<main className={styles.auth__form__container}>
				{error && <div className={styles.error}>{error}</div>}
				<div className={styles.top}>
					<div className={styles.mobile__logo}>
						<img
							src={
								isAnscims
									// ? "/anscims_logo.svg"
									// : "/anssid_logo.png"
									? coy_ANSSID_LOGO
									: coy_ANSCIMS_LOGO
							}
							alt="logo"
						/>
					</div>
					<h1 className={styles.heading__text}>Log In</h1>
					<div className={styles.not__footer}>
						{!isAnscims && (
							<p>
								{" "}
								Don't have an account?{" "}
								<NavLink to="/signup">Sign up</NavLink>
							</p>
						)}
						<p>
							Forgot your password?{" "}
							<NavLink to="/reset-password">
								Click here to reset password
							</NavLink>
						</p>
					</div>
				</div>
				<div className={styles.bottom}>
					<p className={styles.sub__heading__text}>
						Using the same details at Sign Up, kindly login with
						either your phone number or your email address.
					</p>
					<form className={styles.auth__form} onSubmit={signin}>
						<label htmlFor="phone-email">
							Phone no/Email Address
						</label>
						<input
							id="phone-email"
							type="text"
							name="email"
							value={values.email}
							onChange={handleChange}
							placeholder="john.doe@gmail.com"
							required
						/>
						<hr className={styles.hr} />
						<label htmlFor="password">Password</label>
						<input
							id="password"
							type="password"
							name="password"
							value={values.password}
							onChange={handleChange}
							placeholder="**********"
						/>
						<button
							type="submit"
							className={`${styles.primary__button} primary__btn`}
							disabled={
								!values.email || !values.password || fetching
							}
						>
							{fetching ? "Logging In..." : "Log In"}
						</button>
					</form>
				</div>
			</main>
		</section>
	);
};

export default Login;
