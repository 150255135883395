import { useState, useEffect, createRef } from "react";
import axios from "axios";

import Table from "../../components/Table";
import Modal from "../../components/Modal";
import { formatAmount } from "../../utils";
import styles from "../MDAs/style.module.css";

const ClearedBills = () => {
	const [fullTableShowing, toggleFullTable] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const filterRef = createRef();

	const [anssid, setAnssid] = useState("");
	const [invoiceCode, setInvoiceCode] = useState("");
	const [reference, setReference] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [sortOrder, setSortOrder] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);

	const coy_ANSSID = process.env.REACT_APP_ANSSID
	const headers = [
		["Reference Code", "ReferenceCode"],
		["Invoice Number", "InvoiceCode"],
		// ["ANSSID", "ANSSID"],
		[`${coy_ANSSID}`, "ANSSID"],
		["Payer name", "TaxpayerName"],
		["Amt Payable (NGN)", "AmountPayable", formatAmount],
		["Amt Paid (NGN)", "AmountPaid", formatAmount],
		["Revenue Name", "RevenueName"]
	];

	const getClearedBills =
		({ currentPage = 1 } = {}) =>
			async (e) => {
				e?.preventDefault?.();

				setLoading(true);
				if (!filterRef.current.classList.contains(styles.no__display)) {
					filterRef.current.classList.toggle(styles.no__display);
				}

				try {
					let query = `page=${currentPage}&pageSize=20&`;
					if (anssid) {
						query += `anssid=${anssid}&`;
					}

					if (invoiceCode) {
						query += `invoiceCode=${invoiceCode}&`;
					}

					if (reference) {
						query += `reference=${reference}`;
					}

					if (minAmount) {
						query += `minAmount=${minAmount}&`;
					}

					if (maxAmount) {
						query += `maxAmount=${maxAmount}&`;
					}

					if (sortOrder) {
						if (sortOrder === "a-to-z") {
							query += `sortflg=1&`;
						} else {
							query += `sortflg=2&`;
						}
					}

					const accessToken = localStorage.getItem("access_token");
					const info = await axios
						.get(`/txn/reports/invoices/cleared?${query}`, {
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						})
						.then((res) => res.data);

					if (info) {
						setData(info.data);
						setTotal(info.total);
					}
				} catch (err) {
					console.error(err);
				} finally {
					setLoading(false);
				}
			};

	const clearFilters = () => {
		setAnssid("");
		setInvoiceCode("");
		setReference("");
		setMinAmount("");
		setMaxAmount("");
		setSortOrder("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getClearedBills()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getClearedBills()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button onClick={() => toggleFullTable(!fullTableShowing)}>
					View Entire Table
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!anssid &&
								!invoiceCode &&
								!reference &&
								!minAmount &&
								!maxAmount &&
								!sortOrder
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="anssid">{coy_ANSSID}</label>
								<input
									name="anssid"
									id="anssid"
									type="text"
									value={anssid}
									onChange={(e) => setAnssid(e.target.value)}
									placeholder={coy_ANSSID}
								/>
							</div>
							<div>
								<label htmlFor="invoice_code">
									Invoice Code
								</label>
								<input
									name="invoice_code"
									id="invoice_code"
									type="text"
									value={invoiceCode}
									onChange={(e) =>
										setInvoiceCode(e.target.value)
									}
									placeholder="Invoice Code"
								/>
							</div>
							<div>
								<label htmlFor="payment_reference">
									Payment Reference
								</label>
								<input
									name="payment_reference"
									id="payment_reference"
									type="text"
									value={reference}
									onChange={(e) =>
										setReference(e.target.value)
									}
									placeholder="Payment Reference"
								/>
							</div>
						</form>
						<p>By Amount Paid</p>
						<form>
							<div>
								<label htmlFor="min_amount">Min Amount</label>
								<input
									name="min_amount"
									id="min_amount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="100,000"
								/>
							</div>
							<div>
								<label htmlFor="max_amount">Max Amount</label>
								<input
									name="max_amount"
									id="max_amount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="2,000,000"
								/>
							</div>
						</form>
						<p>Sort Taxpayer Name Alphabetically</p>
						<form className={styles.radio__form}>
							<div>
								<input
									name={sortOrder}
									id="a_to_z"
									type="radio"
									value="a-to-z"
									onChange={(e) =>
										setSortOrder(e.target.value)
									}
								/>
								<label htmlFor="a_to_z">A to Z</label>
							</div>
							<div>
								<input
									name={sortOrder}
									id="z_to_a"
									type="radio"
									value="z-to-a"
									onChange={(e) =>
										setSortOrder(e.target.value)
									}
								/>
								<label htmlFor="z_to_a">Z to A</label>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							type="submit"
							onClick={getClearedBills()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						pageSize={20}
						total={total}
						onPageChanged={getClearedBills}
					/>
				)}
			</div>
			{fullTableShowing && (
				<Modal showing={fullTableShowing} toggle={toggleFullTable}>
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getClearedBills}
					/>
				</Modal>
			)}
		</div>
	);
};

export default ClearedBills;
