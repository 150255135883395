import { createContext, useState } from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import PersonalData from "./personal.data";
import OtherInformation from "./other.information";
import FamilyInformation from "./family.info";
import EmploymentInformation from "./employment.information";
import IdentityProof from "./identity.proof";
import Biometrics from "./biometrics";
import styles from "./style.module.css";

export const LGAContext = createContext("");

const UserDetails = () => {
	const { path } = useRouteMatch();
	const location = useLocation();
	const [LGA, setLGA] = useState("");

	console.log('location?.state?.userId ->  ', location?.state?.userId)
	console.log('location ->  ', location)
	console.log('path ->  ', path)
	const userId =
		location?.state?.userId ||
		new URLSearchParams(location?.search).get("userId");
	const authorize = location?.state?.authorize;
	const query = userId ? `?userId=${userId}` : "";
	console.log('query ->  ', query)

	const pages = {
		[`${path}`]: {
			component: PersonalData,
			next: "/other-information",
			exact: true,
			query
		},
		[`${path}/other-information`]: {
			component: OtherInformation,
			next: "/family-information",
			prev: "/",
			query
		},
		[`${path}/family-information`]: {
			component: FamilyInformation,
			next: "/employment-information",
			prev: "/other-information",
			query
		},
		[`${path}/employment-information`]: {
			component: EmploymentInformation,
			next: "/proof-of-identity",
			prev: "/family-information",
			query
		},
		[`${path}/proof-of-identity`]: {
			component: IdentityProof,
			prev: "/employment-information",
			next: "/biometrics",
			query
		},
		[`${path}/biometrics`]: {
			component: Biometrics,
			prev: "/proof-of-identity",
			query
		},
		[`${path}/image-checker`]: {
			component: Biometrics,
			prev: "/biometrics",
			query
		}
	};

	return (
		<section className={styles.wrapper}>
			<LGAContext.Provider value={{ LGA, setLGA }}>
				<Switch>
					{Object.keys(pages).map((r) => {
						const config = pages[r];
						const Component = config?.component;
						const q = config?.query;
						return (
							Component && (
								<Route key={r} path={r} exact={config.exact}>
									<Component
										{...location.state}
										authorize={authorize}
										userId={userId}
										next={`${path}${config.next}`}
										query={q}
										prev={
											config.prev &&
											`${path}${config.prev}`
										}
									/>
								</Route>
							)
						);
					})}
				</Switch>
			</LGAContext.Provider>
		</section>
	);
};

export default UserDetails;
