import React from "react";
import axios from "axios";
import styles from "./style.module.css";
import { countries, countryKeys } from "../countries";
// import {toCamelCase} from "../utils"
// const state = process.env.REACT_APP_COY
const stateId = process.env.REACT_APP_COY_CODE
// const coy_ANSSID = process.env.REACT_APP_ANSSID

const useOtherInformation = ({
	setCurrentStep,
	anssid,
	userId,
	urlPrefix = ""
}) => {
	const [nationality, setNationality] = React.useState("Nigeria");
	const [stateoforigin, setStateOfOrigin] = React.useState("Enugu");
	const [lgaoforigin, setLgaOfOrigin] = React.useState("");
	const [residentialaddress, setResidentialAddress] = React.useState("");
	const [stateofresidence, setStateOfResidence] = React.useState("Enugu");
	const [lgaofresidence, setLgaOfResidence] = React.useState("");
	const [townofresidence, setTownOfResidence] = React.useState("");
	const [townoforigin, setTownOfOrigin] = React.useState("");
	const [cityofresidence, setCityOfResidence] = React.useState("");
	//femi
	// const [townoforiginflg, setTownOfOriginFlg] = React.useState("");
	// const [stateoforiginflg, setStateOfOriginFlg] = React.useState("");
	const [nin, setNin] = React.useState("");
	const [bvn, setBvn] = React.useState("");
	const [tin, setTIN] = React.useState("");
	const [tinName, setTINname] = React.useState("");
	// const [btnSubmitDisabled, setBtnSubmitDisabled] = React.useState(false);
	// const [tinError, setTinError] = React.useState("");
	// const [verifyTin, setVerifyTin] = React.useState("0");
	// femi
	const [submitted, setSubmitted] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [finishknt, setFinishKnt] = React.useState(0);
	const [townFinishknt, setTownFinishKnt] = React.useState(0);
	const [cityFinishknt, setCityFinishKnt] = React.useState(0);
	const [lgaFinishknt, setLGAFinishKnt] = React.useState(0);
	const [error, setError] = React.useState();
	const [towns, setTowns] = React.useState([]);
	// eslint-disable-next-line no-unused-vars
	const [cities, setCities] = React.useState([]);
	// eslint-disable-next-line no-unused-vars
	const [states, setStates] = React.useState([]);
	const [lgas, setLgas] = React.useState([]);

	///===============================
	// eslint-disable-next-line no-unused-vars
	const [statesArr, setStatesArr] = React.useState([]);
	const [lgasArr, setLgasArr] = React.useState([]);
	// eslint-disable-next-line no-unused-vars
	const [ArrLgas, setArrLgas] = React.useState([]);
	const [townArr, setTownsArr] = React.useState([]);
	// const [preLGAOrigin, setPreLGAOrigin] = React.useState("");
	// const [preLGAResidential, setPreLGAResidential] = React.useState("");
	const [preOriginState, setPreOriginState] = React.useState("");
	const [residentstatecode, setPreResidentState] = React.useState("");
	const [preOriginTown, setPreOriginTown] = React.useState("");
	const [preResidentTown, setPreResidentTown] = React.useState("");

	///===============================
	const [statesOrigin, setStatesOrigin] = React.useState([]);
	const [lgasOrigin, setLgasOrigin] = React.useState([]);
	const [townsOrigin, setTownsOrigin] = React.useState([]);

	///===============================
	const [statesResidence, setStatesResidence] = React.useState([]);
	const [lgasResidence, setLgasResidence] = React.useState([]);
	const [townsResidence, setTownsResidence] = React.useState([]);
	// eslint-disable-next-line no-unused-vars
	const [chkTownStatus, setChkTownStatus] = React.useState(false);
	const [lgaTownStatus, setLGATownStatus] = React.useState(false);
	// const [nationStatus, setNationStatus] = React.useState(false);
	const [defaultOriginTown, setDefaultOriginTown] = React.useState(false);

	// eslint-disable-next-line no-unused-vars
	const [townOriginStatus, setTownOriginStatus] = React.useState(false);
	// const [outsideOriginStatus, setOutsideOriginStatus] = React.useState(false);
	const [stateOriginStatus, setStateOriginStatus] = React.useState(false);
	// eslint-disable-next-line no-unused-vars
	const [townResidenceStatus, setResidenceOriginStatus] =
		React.useState(false);

	const getOtherInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`${urlPrefix}/users/location-qry?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setNationality(info.nationality || "");
				setStateOfOrigin(info.stateoforigin || "");
				setLgaOfOrigin(info.lgaoforigin || "");
				setResidentialAddress(info.residentialaddress || "");
				setStateOfResidence(info.stateofresidence || "");
				setLgaOfResidence(info.lgaofresidence || "");
				setTownOfResidence(info.townofresidence || "");
				setCityOfResidence(info.cityofresidence || "");
				setTownOfOrigin(info.townoforigin || "");
				setNin(info.nin || "");
				setBvn(info.bvn || "");
				setTIN(info.tin || "");
				setTINname(info.tinName || "");

				// added by femi
				setPreOriginTown(info.townoforigin || "");
				setPreResidentTown(info.townofresidence || "");
				setPreOriginState(info.statecode || "");
				setPreResidentState(info.residentstatecode || "");
				// setPreLGAOrigin(info.originlgacode || "");
				// setPreLGAResidential(info.reslgacode || "");
			} else {
				setPreOriginState(stateId);
				setPreResidentState(stateId);
			}
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		} finally {
			setLoading(false);
			setFinishKnt(1);
		}
	};

	const getTowns = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/locationsetup`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setTowns(info);
			setTownsArr(info);

			const tmpLgasArr = [];
			var _lgaCode = null;

			info.forEach((element) => {
				if (element.StateCode === _lgaCode) {
					tmpLgasArr.push(element);
				} else if (_lgaCode === "") {
					tmpLgasArr.push(element);
				}
			});

			setTownsResidence([]);
			setTownsOrigin([]);
			setTownsResidence(tmpLgasArr);
			setTownsOrigin(tmpLgasArr);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		} finally {
			setTownFinishKnt(1);
		}
	};

	const getCities = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/city`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setCities(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		} finally {
			setCityFinishKnt(1);
		}
	};

	const getStates = async () => {
		let stateArr = {};
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/state`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			// eslint-disable-next-line no-unused-vars
			stateArr = info;
			setStates(info);
			setStatesArr(info);
			setStatesOrigin(info);
			setStatesResidence(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getLGAs = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/lga`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setLgas(info);
			setArrLgas(info);
			setLgasArr(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}finally {
			setLGAFinishKnt(1);
		}
	};

		
	function toCamelCase(input) {
		// Split the input string by spaces or underscores
		const words = input.split(/[\s_]+/);
	
		// Capitalize the first letter of each word (except the first word)
		for (let i = 1; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
		}
	
		// Join the words back together without spaces or underscores
		return words.join('');
	}
	
	React.useEffect(() => {
		// console.log( 'finishknt  >>> ',finishknt)
		// console.log('townofresidence  [useEffect]   >>> ', townofresidence)
		// console.log('townofresidence  [useEffect]   >>> ', townofresidence)
		getOtherInformation();
		if (finishknt === 1) {
			if (nationality.toLowerCase().trim() === "nigeria") {
				setStateOriginStatus(true);
				setLGATownStatus(true);
				setDefaultOriginTown(true);
				//console.log('lgas [ useEffect ] >>>  ', lgas)
				console.log('townofresidence  [useEffect]   >>> ', townofresidence)
				console.log('stateofresidence.toLowerCase().trim()  [useEffect]   >>> ', stateofresidence.toLowerCase().trim())
				console.log('stateoforigin.toLowerCase().trim()  [useEffect]   >>> ', stateoforigin.toLowerCase().trim())
				console.log('preOriginState.toLowerCase().trim()  [useEffect]   >>> ', preOriginState.toLowerCase().trim())
				console.log('stateId #env  [useEffect]   >>> ', stateId)
				console.log('lgaFinishknt  [useEffect]   >>> ', lgaFinishknt)
				console.log('(preOriginState || stateId)  [useEffect]   >>> ', (preOriginState || stateId))
				const tmpLgasOrigArr = [];
				var tmpOriginTownArr = [];
				const tmpLgasResArr = [];
				// const tmpTownResArr = [];
				setTownsOrigin([]);
				setTownsResidence([]);

				// State of Origin & Resident LGA
				lgas.forEach((element) => {
					if (element.StateCode === preOriginState){
						tmpLgasOrigArr.push(element);
					}
					if (element.StateCode === residentstatecode) {
						tmpLgasResArr.push(element);
					}
				});
				setLgasOrigin([]);
				setLgasOrigin(tmpLgasOrigArr);
				setLgasResidence([]);
				setLgasResidence(tmpLgasResArr);

//---  Femi
towns.forEach((element) => {
	if (element.LgaCode === stateId) {
		console.log('element[towns]  -> ', element)
		tmpOriginTownArr.push(element);
	}
});
console.log('tmpOriginTownArr [town ] ===>  ', tmpOriginTownArr)
setTownsOrigin([]);
setTownsOrigin(tmpOriginTownArr);

//---  Femiiikiiiiiiiiiiiiiiiiiiiiiiiii

				if (stateoforigin.toLowerCase().trim() !== "enugu") {
					setDefaultOriginTown(false);
				} else {
					setTownsOrigin(towns);
					// setTownOfOrigin(preOriginTown);
				}
				if (stateofresidence.toLowerCase().trim() !== "enugu") {
					setTownOfResidence('Non-Indigene');
				} else {
					console.log('towns --->  ', towns)
					console.log('preOriginTown --->  ', preOriginTown)
					setTownsResidence(towns);
					setTownOfResidence(preResidentTown);
				}
				//console.log('setTownOfOrigin [useeffect] >>>>>   ', townoforigin)
				// setTownOfOrigin(townoforigin)
				// setTownsOrigin(towns);
				// setTownsResidence(towns);

				////console.log('tmpLgasResArr  >>>>>   ',tmpLgasResArr)
				//console.log('tmpLgasArr  >>>>>   ',tmpLgasOrigArr)
				//console.log('tmpOriginTownArr  >>>>>   ', tmpOriginTownArr)
				//console.log('tmpTownResArr  >>>>>   ',tmpTownResArr)
			} else {
				if (stateofresidence.toLowerCase().trim() === 'enugu') {
					const tmpLgasResArr = [];
					// const tmpTownResArr = [];

					// State of Origin & Resident LGA
					lgas.forEach((element) => {
						if (element.StateCode === residentstatecode) {
							tmpLgasResArr.push(element);
						}
					})
					setLgasResidence([]);
					setLgasResidence(tmpLgasResArr);

					setTownsResidence(towns);
					setTownOfResidence(preResidentTown);
				} else {
					setStateOriginStatus(false);
					setLGATownStatus(false);
					setDefaultOriginTown(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finishknt, lgaFinishknt, cityFinishknt,townFinishknt]);

	React.useEffect(() => {
		getStates();
		getLGAs();
		getTowns();
		getCities();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// React.useEffect(() => {
	// 	// if (stateoforigin.toLowerCase().trim()  === "enugu") {
	// 	console.log('nationality  >>>  ', nationality)
	// 	// if (finishknt === 1) {
	// 	if (nationality.toLowerCase().trim() === "nigeria") {
	// 		setStateOriginStatus(true);
	// 		setLGATownStatus(true);
	// 		GetDefaultOriginTown(stateoforigin)
	// 		// setDefaultOriginTown(true);

	// 		// if (stateoforigin.toLowerCase().trim()  === "enugu") {flga
	// 		// 	setDefaultOriginTown(true);
	// 		// }else{
	// 		// 	setDefaultOriginTown(false);
	// 		// }
	// 	} else {
	// 		console.log('non-nigerian.......')
	// 		setStateOriginStatus(false);
	// 		setLGATownStatus(false);
	// 		setDefaultOriginTown(false);
	// 	}

	// 	console.log('stateofresidence.......', stateofresidence.toLowerCase().trim())
	// 	if (stateofresidence.toLowerCase().trim() === "enugu") // && lgasArr.length) {
	// 		setLoadLgaResidence();
	// 	// }

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// 	// }, [stateoforigin, lgas, stateofresidence, lgasArr]);
	// }, [finishknt, nationality, townArr]);

	const setLoadLgaOrigin = async (e) => {
		//console.log('sLGA [e.target.lgacode] ==  ', e.target.lgaCode)
		//console.log('sLGA  ==  ', e)
		const tmpLgasArr2 = [];
		var stateCode = e
			? e.target.selectedOptions[0].getAttribute("statecode")
			: stateId;
		//console.log('stateCode  ---->  [  setLoadLgaOrigin ] ', stateCode)

		if (stateCode === stateId) {
			setChkTownStatus(true);
		} else {
			setChkTownStatus(false);
		}

		setLGATownStatus(true);
		lgas.forEach((element) => {
			if (element.StateCode === stateCode) {
				tmpLgasArr2.push(element);
			}
		});
		setLgasOrigin([]);
		setLgasOrigin(tmpLgasArr2);

		//console.log('<<<<<<  setLoadLgaOrigin   >>>>    |    setNationStatus  ---->   ',nationStatus, ';  setChkTownStatus --->  ', chkTownStatus, ';  stateCode --->  ', stateCode)
		// console.log('setLGATownStatus    ---->   ', lgaTownStatus)
		//console.log('tmpLgasArr2 [ setLoadLgaOrigin ]    ---->   ', tmpLgasArr2)
	};

	const setLoadTownsOrigin = async (e) => {
		// const setLoadTownsOrigin = async (sTown) => {
		const tmpTownArr = [];
		// var lgaCode = sTown
		var lgaCode = e.target.selectedOptions[0].getAttribute("lgacode");
		// eslint-disable-next-line no-unused-vars


		if (nationality.toLowerCase().trim() === "nigeria") {
			setTownOriginStatus(true);
		} else {
			setTownOriginStatus(false);
		}

		console.log('lgaCode -> ', lgaCode)
		townArr.forEach((element) => {
			if (element.LgaCode === lgaCode) {
				tmpTownArr.push(element);
			}
		});
		//console.log('tmpTownArr [town ] ===>  ', tmpTownArr)
		setTownsOrigin([]);
		setTownsOrigin(tmpTownArr);
	};

	const setLoadLgaResidence = async (e) => {
		const tmpLgasArr = [];
		var stateCode = e
			? e.target.selectedOptions[0].getAttribute("statecode")
			: stateId;
		lgasArr.forEach((element) => {
			if (element.StateCode === stateCode) {
				tmpLgasArr.push(element);
			}
		});

		setLgasResidence([]);
		setLgasResidence(tmpLgasArr);
	};

	const setLoadTownsResidence = async (e) => {
		const tmpCitiesArr = [];
		var lgaCode = e.target.selectedOptions[0].getAttribute("lgacode");
		townArr.forEach((element) => {
			if (element.LgaCode === lgaCode) {
				tmpCitiesArr.push(element);
			}
		});

		setTownsResidence([]);
		setTownsResidence(tmpCitiesArr);
	};

	// const jtbChecks = async (e) => {
	// 	//e.preventDefault();
	// 	setError("");

	// 	setLoading(false);
	// 	const chk = e;
	// 	console.log('chk >>>> ', chk)
	// 	setTINname("")		// clear textbox
	// 	setTinError("")

	// 	if (chk !== "") {
	// 		try {
	// 			setTINname("")		// clear textbox

	// 			const accessToken = localStorage.getItem("access_token");
	// 			await axios
	// 				.post(
	// 					`${urlPrefix}/txn/jtb/verifyInd`,
	// 					{
	// 						"tin": chk,
	// 						"userId": userId
	// 					},
	// 					{
	// 						headers: {
	// 							Authorization: `Bearer ${accessToken}`
	// 						}
	// 					}
	// 				)
	// 				.then((res) => {
	// 					console.log('TIN Name >>> ', res?.data?.Taxpayer?.last_name, ' ' + res?.data?.Taxpayer?.first_name + ' ' + res?.data?.Taxpayer?.middle_name)

	// 					console.log(res);
	// 					console.log('JTBValidatedData  >>>>  ', res?.data?.JTBValidatedData)
	// 					if (res?.data?.ResponseCode === stateId) {
	// 						// setError('TIN ' + res?.data?.ResponseDescription)
	// 						setTinError('TIN ' + res?.data?.ResponseDescription)
	// 						setBtnSubmitDisabled(true);
	// 						setError('TIN ' + res?.data?.ResponseDescription)
	// 					} else if (res?.data?.ResponseCode === "001") {
	// 						const name = res?.data?.Taxpayer?.last_name + ' ' + res?.data?.Taxpayer?.first_name + ' ' + res?.data?.Taxpayer?.middle_name
	// 						setTINname(name)

	// 						if (res?.data?.JTBValidatedData?.chkDob === "0") {
	// 							setTinError('TIN Date of Birth and Date of Birth that you entered are not the same')
	// 							setBtnSubmitDisabled(true);
	// 							setError('TIN Date of Birth and Date of Birth that you entered are not the same')
	// 						} else if (res?.data?.JTBValidatedData?.chkEmail === "0") {
	// 							setTinError('TIN Email Address and Email Address that you entered are not the same')
	// 							setBtnSubmitDisabled(true);
	// 							setError('TIN Email Address and Email Address that you entered are not the same')
	// 						} else if (res?.data?.JTBValidatedData?.chkFirstName === "0") {
	// 							setTinError('TIN First Name and First Name that you entered are not the same')
	// 							setBtnSubmitDisabled(true);
	// 							setError('TIN First Name and First Name that you entered are not the same')
	// 						} else if (res?.data?.JTBValidatedData?.chkSurname === "0") {
	// 							setTinError('TIN Surname and Surname that you entered are not the same')
	// 							setBtnSubmitDisabled(true);
	// 							setError('TIN Surname and Surname that you entered are not the same')
	// 						} else if (res?.data?.JTBValidatedData?.chkFone === "0") {
	// 							setTinError('TIN Telephone and Telephone that you entered are not the same')
	// 							setBtnSubmitDisabled(true);
	// 							setError('TIN Telephone and Telephone that you entered are not the same')
	// 						}

	// 						if ((res?.data?.JTBValidatedData?.chkDob === "0") || (res?.data?.JTBValidatedData?.chkEmail === "0") ||
	// 							(res?.data?.JTBValidatedData?.chkFirstName === "0") || (res?.data?.JTBValidatedData?.chkSurname === "0")
	// 							|| (res?.data?.JTBValidatedData?.chkFone === "0")) {
	// 							console.log('submit btn.....')
	// 							setVerifyTin("0");
	// 							// const [verifyTin, setVerifyTin] = React.useState("1");
	// 						} else {
	// 							setVerifyTin("1");
	// 							setBtnSubmitDisabled(false);
	// 						}
	// 					}
	// 				})
	// 		} catch (err) {
	// 			let message = "";
	// 			if (err.response) {
	// 				message =
	// 					err.response.data.errors?.details[0].message ||
	// 					err.response.data?.error ||
	// 					err.response.data?.errors;
	// 			} else {
	// 				message = err.message;
	// 			}
	// 			setError(
	// 				message ||
	// 				"Wrong Date of Birth: Pls enter your Date of Birth. "
	// 			);
	// 		} finally {
	// 			setLoading(false);
	// 		}
	// 	}
	// };


	const submitOtherInformation = async (e) => {
		e.preventDefault();
		setError("");
		if (anssid) {
			setSubmitted(true);
			setCurrentStep(3);
			return;
		}

		setLoading(true);

		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			console.log('lgaoforigin >>>>', lgaoforigin)
			// if (btnSubmitDisabled) {
			// 	setError(`Error!!!  ${tinError}`)
			// } else {
			const accessToken = localStorage.getItem("access_token");
			const res = await axios
				.post(
					`${urlPrefix}/users/lokation?${query}`,
					{
						nationality,
						stateoforigin,
						lgaoforigin,
						residentialaddress,
						stateofresidence,
						lgaofresidence,
						townofresidence,
						cityofresidence,
						townoforigin,
						nin,
						bvn,
						tin,
						tinName
						// verifyTin
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);

			console.log(res);

			setSubmitted(true);
			setCurrentStep(3);
			// }
		} catch (err) {
			let message = "";
			if (err.response) {
				message =
					err.response.data.errors?.details[0].message ||
					err.response.data?.error ||
					err.response.data?.errors;
			} else {
				message = err.message;
			}
			setError(
				message ||
				"Form could not be submitted. Please verify your details and try again."
			);
		} finally {
			setLoading(false);
		}
	};

	function titleCase(str) {
		return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
	}

	// const setCountryStatus = async (txtVal) => {
	// 	//var nation = e.target.selectedOptions[0].getAttribute('nationality');

	// 	// console.log("nation [ txtVal ]: " + txtVal);

	// 	if (txtVal.toLowerCase().trim() === "nigeria") {
	// 		setNationStatus(true);
	// 	} else {
	// 		setNationStatus(false);
	// 	}
	// };

	const GetDefaultOriginTown = async (txtState) => {
		//console.log(' [GetDefaultOriginTown ] =', txtState.toLowerCase().trim())
		if (txtState.toLowerCase().trim() === "enugu") {
			setDefaultOriginTown(true);
		} else {
			setDefaultOriginTown(false);
		}
		// console.log('setDefaultOriginTown [ GetDefaultOriginTown ]  >>>>   ',defaultOriginTown)
	};

	const setLoadStateOrigin = async (txtNation) => {
		if (txtNation.toLowerCase().trim() === "nigeria") {
			setStateOriginStatus(true);
		} else {
			setStateOriginStatus(false);
		}
	};

	const form = ({ footer }) => (
		<form
			className={styles.columned__form}
			onSubmit={submitOtherInformation}
		>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="nationality"
					>
						Nationality <span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="nationality"
						className={styles.select__input}
						required
						value={nationality}
						onChange={(e) => {
							setNationality(e.target.value);
							setLoadStateOrigin(e.target.value);
							setTownOfOrigin("Non-Nigerian");
							setStateOfOrigin("Outside-Nigeria");
							// setCountryStatus(e.target.value);
						}}
					>
						<option value="">--Select Nationality--</option>
						{countryKeys
							//.sort((a, b) => a.country.localeCompare(b.country))
							.map((key) => (
								<option
									value={countries[key].country}
									key={key}
								>
									{titleCase(
										// countries[key].country.toLowerCase()
										toCamelCase(countries[key].country)
									)}
								</option>
							))}
					</select>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="stateOfOrigin"
					>
						State of Origin <span style={{ color: "red" }}>*</span>
					</label>
					{/nigeria/i.test(nationality) ? (
						<select
							id="stateOfOrigin"
							className={styles.select__input}
							required
							value={stateoforigin}
							onChange={(e) => {
								setStateOfOrigin(e.target.value);
								setLgaOfOrigin("");
								setLoadLgaOrigin(e);
								GetDefaultOriginTown(e.target.value);
							}}
						>
							<option value="">--Select State of Origin--</option>
							{stateOriginStatus ? (
								statesOrigin
									.sort((a, b) =>
										a.Description.localeCompare(
											b.Description
										)
									)
									.map((state, key) => (
										<option
											value={state.Description}
											statecode={state.Code}
											key={key}
										>
											{/* {toCamelCase(state.Description)} */}
											{state.Description}
										</option>
									))
							) : (
								<option value="Outside-Nigeria">
									Outside-Nigeria
								</option>
							)}
						</select>
					) : stateOriginStatus ? (
						<input
							type="text"
							id="stateOfOrigin"
							placeholder="State"
							required
							value="Out-Of-Nigeria"
							onChange={(e) => setStateOfOrigin(e.target.value)}
						/>
					) : (
						<input
							type="text"
							id="stateOfOrigin"
							placeholder="State"
							required
							value={stateoforigin}
							onChange={(e) => setStateOfOrigin(e.target.value)}
						/>
					)}
				</div>
			</div>
			<div className={styles.double__inputs}>
				{/nigeria/i.test(nationality) && stateoforigin && (
					<div>
						<label
							className={styles.input__label}
							htmlFor="lgaOfOrigin"
						>
							LGA of Origin{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="lgaOfOrigin"
							className={styles.select__input}
							required
							value={lgaoforigin}
							onChange={(e) => {
								setLgaOfOrigin(e.target.value);
								setLoadTownsOrigin(e);
							}}
						>
							<option value="">--Select LGA of Origin--</option>
							{stateOriginStatus ? (
								//townOriginStatus ?
								lgaTownStatus ? (
									lgasOrigin
										.sort((a, b) =>
											a.Description.localeCompare(
												b.Description
											)
										)
										.map((lga, key) => (
											<option
												value={lga.Description}
												lgacode={lga.Code}
												key={key}
											>
												{titleCase(
													// lga.Description.toLowerCase()
													lga.Description
												)}
											</option>
										))
								) : (
									<option value="Non-Indigene">
										Non-Indigene
									</option>
								)
							) : (
								<option value="Non-Nigerian">
									Non-Nigerian
								</option>
							)}
						</select>
					</div>
				)}
				<div>
					<label
						className={styles.input__label}
						htmlFor="townOfResidence"
					>
						Town of Origin <span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="townoforigin"
						className={styles.select__input}
						required
						value={townoforigin}
						onChange={(e) => setTownOfOrigin(e.target.value)}
					>
						<option value="">--Select Town of Origin--</option>
						{stateOriginStatus ? (
							// townOriginStatus ?
							defaultOriginTown ? (
								townsOrigin
									.sort((a, b) =>
										a.Description.localeCompare(
											b.Description
										)
									)
									.map((town, i) => (
										<option
											value={town.Description}
											key={i}
										>
											{town.Description}
											{/* {town.Description.toLowerCase()} */}
										</option>
									))
							) : (
								<option value="Non-Indigene">
									Non-Indigene
								</option>
							)
						) : (
							<option value="Non-Nigerian">Non-Nigerian</option>
						)}
					</select>
				</div>
			</div>
			<hr className={styles.divider} />
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="nin">
						NIN
					</label>
					<input
						id="nin"
						type="text"
						value={nin}
						onChange={(e) => setNin(e.target.value)}
						placeholder="input your nin"
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="bvn">
						BVN
					</label>
					<input
						id="bvn"
						type="text"
						value={bvn}
						onChange={(e) => setBvn(e.target.value)}
						placeholder="input your bvn"
					/>
				</div>
			</div>

			{/* <div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="tin">
						Tax Identification Number (TIN)
					</label>
					<input
						id="tin"
						type="text"
						value={tin}
						onChange={(e) => {
							setTIN(e.target.value);
							jtbChecks(e.target.value)
						}}
						placeholder="input your TIN"
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="tinName">
						Tax Identification Number Name
					</label>
					<input
						id="tinName"
						type="text"
						value={tinName}
						onChange={(e) => setTINname(e.target.value)}
						placeholder="TIN Name "
						disabled
					/>
				</div>
			</div> */}

			<hr className={styles.divider} />
			<label className={styles.input__label} htmlFor="residentialAddress">
				Residential Address <span style={{ color: "red" }}>*</span>
			</label>
			<input
				type="text"
				id="residentialAddress"
				placeholder="eg Okafor's crescent, Onitsha"
				long="true"
				required
				value={residentialaddress}
				onChange={(e) => setResidentialAddress(e.target.value)}
			/>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="stateOfResidence"
					>
						State of Residence{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="stateOfResidence"
						className={styles.select__input}
						required
						value={stateofresidence}
						onChange={(e) => {
							setStateOfResidence(e.target.value);
							setLgaOfResidence("");
							setLoadLgaResidence(e);
							setStateOfResidence(e.target.value);
						}}
					>
						<option value="">--Select State of Residence--</option>
						{statesResidence
							.sort((a, b) =>
								a.Description.localeCompare(b.Description)
							)
							.map((state, key) => (
								<option
									value={state.Description}
									statecode={state.Code}
									key={key}
								>
									{state.Description}
									{/* {state.Description.toLowerCase()} */}
								</option>
							))}
					</select>
				</div>
				{stateofresidence && (
					<div>
						<label
							className={styles.input__label}
							htmlFor="lgaOfResidence"
						>
							Local Government Area of Residence{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="lgaOfResidence"
							className={styles.select__input}
							required
							value={lgaofresidence}
							onChange={(e) => {
								setLgaOfResidence(e.target.value);
								setLoadTownsResidence(e);
							}}
						>
							<option value="">
								--Select LGA of Residence--
							</option>
							{lgasResidence
								.sort((a, b) =>
									a.Description.localeCompare(b.Description)
								)
								.map((lga, key) => (
									<option
										value={lga.Description}
										lgacode={lga.Code}
										key={key}
									>
										{lga.Description}
										{/* {lga.Description.toLowerCase()} */}
									</option>
								))}
						</select>
					</div>
				)}
			</div>
			<label className={styles.input__label} htmlFor="townOfResidence">
				Town of Residence <span style={{ color: "red" }}>*</span>
			</label>
			<select
				id="townOfResidence"
				className={styles.select__input}
				required
				value={townofresidence}
				onChange={(e) => setTownOfResidence(e.target.value)}
			>
				<option value="">--Select Town of Residence--</option>
				{stateofresidence.toLowerCase().trim() === "enugu" ? (
					townsResidence
						.sort((a, b) =>
							a.Description.localeCompare(b.Description)
						)
						.map((town, i) => (
							<option value={town.Description} key={i}>
								{/* {town.Description.toLowerCase()} */}
								{town.Description}
							</option>
						))
				) : (
					<option value="Non-Indigene">Non-Indigene</option>
				)}
			</select>
			{footer}
		</form>
	);

	// eslint-disable-next-line no-unused-vars
	const townOfOriginId = async () => {
		var _lgaOfOrigin = document.getElementById("townoforigin");
		var _lgaOfOriginCode = null;
		var _lgaCode = null;

		// console.log("Lga Of Origin1: " + _lgaOfOrigin);

		if (typeof _lgaOfOrigin != "undefined" && _lgaOfOrigin != null) {
			//console.log("Lga Of Origin Found1");
			_lgaOfOriginCode =
				_lgaOfOrigin.options[_lgaOfOrigin.selectedIndex].getAttribute(
					"lgacode"
				);

			// eslint-disable-next-line no-unused-vars
			_lgaCode =
				_lgaOfOriginCode != null || _lgaOfOriginCode !== ""
					? _lgaOfOriginCode
					: "";
		} else {
			// console.log("Lga Of Origin Not Found1");
		}
	};

	return {
		title: "Other Information",
		description:
			"This is about getting to know YOU further. Please enter your personal information in the appropriate fields as required on this page, noting those marked with the asterisk (*) sign as compulsory and Click Continue to proceed OR Exit to Log out.",
		form,
		state: {
			// lgaoforigin: nationality.toLowerCase().trim() === "nigeria" ? lgaoforigin : '000021',
			lgaoforigin,
			stateoforigin,
			nationality,
			submitted,
			loading,
			error,
			disabled: !!anssid
		},
		submit: submitOtherInformation
	};
};

export default useOtherInformation;
