/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

import styles from "../../Identity-management-individual/style.module.css";
import style1 from "../UserDetails/style.module.css";

let userInfo;
try {
	userInfo = JSON.parse(localStorage.getItem("user_info"));
} catch (e) {
	userInfo = {};
}

const Support = () => {
	const [anssidInfo, setAnssidInfo] = useState({});

	const getAnssid = async () => {
		const res = await axios
			.get(`/system/getuseranssid`)
			.then((res) => (res.data || {}).data);
		console.log(res);
		setAnssidInfo(res);
	};

	useEffect(() => {
		getAnssid();
	}, []);

	return (
		<section className={style1.wrapper}>
			<div className={style1.title__div}>
				<h1>Contact Support</h1>
			</div>
			<form className={styles.columned__form}>
				<h2 className={style1.pronounced__text}>Email us on</h2>
				<a href="mailto:eirssupport@norlics.com" className={style1.special__link}>eirssupport@norlics.com</a>
				<hr className={styles.divider} />
				{/* <h2 className={style1.pronounced__text}>Or fill a form</h2> */}
			</form>
		</section>
	);
};

export default Support;
