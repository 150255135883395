/* eslint-disable no-unused-vars */
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import ReactToPrint from "react-to-print";
import PrintOut from "./printOut";
import styles from "./style.module.css";

function formatDate(date) {
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

function snakeToSentenceCase(text) {
	return text
		.replace(/_/g, " ")
		.split(" ")
		.map((t) => t.charAt(0).toUpperCase() + t.slice(1))
		.join(" ");
}

const PersonalPrint = () => {
	const componentRef = useRef();

	const [title, setTitle] = useState("");
	const [firstname, setFirstName] = useState("");
	const [middlename, setMiddleName] = useState("");
	const [surname, setSurname] = useState("");
	const [mothermaidianname, setMaidenName] = useState("");
	const [sex, setGender] = useState("");
	const past = new Date();
	past.setFullYear(new Date().getFullYear() - 18);
	const [dateofbirth, setDateOfBirth] = useState(formatDate(past));
	const [phonenumber, setPhoneNumber] = useState("");
	const [email, setEmailAddress] = useState("");
	const [nationality, setNationality] = useState("Nigeria");
	const [stateoforigin, setStateOfOrigin] = useState("Anambra");
	const [lgaoforigin, setLgaOfOrigin] = useState("");
	const [residentialaddress, setResidentialAddress] = useState("");
	const [stateofresidence, setStateOfResidence] = useState("Anambra");
	const [lgaofresidence, setLgaOfResidence] = useState("");
	const [townofresidence, setTownOfResidence] = useState("");
	const [cityofresidence, setCityOfResidence] = useState("");
	const [idInfo, setIdInfo] = useState("voters_card");
	const [idDoc, setIdDoc] = useState("");
	const [licenseIssueDate, setLicenseIssueDate] = useState(new Date());
	const [licenseExpiryDate, setLicenseExpiryDate] = useState(new Date());
	const [votersCardCode, setVotersCardCode] = useState("");
	const [idNumber, setIDNumber] = useState("");
	const [imagePreview, setImagePreview] = useState();
	const [maritalstatus, setMaritalStatus] = useState("");
	const [childrennumber, setNoOfChildren] = useState(0);
	const [spousefirstname, setSpouseFirstName] = useState("");
	const [spousemiddlename, setSpouseMiddleName] = useState("");
	const [spousesurname, setSpouseSurname] = useState("");
	const [spousephonenumber, setSpousePhoneNumber] = useState("");
	const [spouseoccupation, setSpouseOccupation] = useState("");
	const [spousebusinessaddress, setSpouseResidentialAddress] = useState("");
	const [childrenDetailsArray, setChildrenDetails] = useState([]);
	const [occupationtype, setOccupation] = useState("");
	const [employername, setEmployer] = useState("");
	const [employeraddress, setEmployerAddress] = useState("");
	const [employerstate, setEmployerState] = useState("Anambra");
	const [employercity, setEmployerCity] = useState("");
	const [monthlyincomerange, setIncomeRange] = useState("");
	const [companyanssid, setCompanyAnssid] = useState("");
	const [cardImage, setCardImage] = useState();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const getPersonalInformation = async () => {
		setLoading(true);
		try {
			const info = await axios
				.get(`/users`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setTitle(info.title || "");
				setFirstName(info.firstname || firstname);
				setMiddleName(info.middlename || middlename);
				setSurname(info.surname || surname);
				setMaidenName(info.mothermaidianname || "");
				setGender((info.sex || "").toLowerCase());
				setDateOfBirth(formatDate(new Date(info.dateofbirth || past)));
				setPhoneNumber(info.phonenumber || phonenumber);
				setEmailAddress(info.email || email);
			}
		} catch (err) {
			setError("Existing personal information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	const getOtherInformation = async () => {
		setLoading(true);
		try {
			const info = await axios
				.get(`/users/location`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setNationality(info.nationality || "");
				setStateOfOrigin(info.stateoforigin || "");
				setLgaOfOrigin(info.lgaoforigin || "");
				setResidentialAddress(info.residentialaddress || "");
				setStateOfResidence(info.stateofresidence || "");
				setLgaOfResidence(info.lgaofresidence || "");
				setTownOfResidence(info.townofresidence || "");
				setCityOfResidence(info.cityofresidence || "");
			}
		} catch (err) {
			setError("Existing information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	const getIdentityInformation = async () => {
		setLoading(true);
		try {
			const info = await axios
				.get(`/users/identity-proof`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setIdInfo(info.identityType);
				setLicenseIssueDate(
					new Date(info.licenseIssueDate || Date.now())
				);
				setLicenseExpiryDate(
					new Date(info.licenseExpiryDate || Date.now())
				);
				setVotersCardCode(info.votersCardCode || "");
				setIDNumber(info.idNumber || "");
				setImagePreview(info.identityPhoto || "");
				setCardImage(info.biometricPhoto || "");
			}
		} catch (err) {
			console.error(err);
			// setError("Existing identity proof information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	const getEmploymentInformation = async () => {
		setLoading(true);
		try {
			const info = await axios
				.get(`/users/employment`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setOccupation(info.occupationtype || "");
				setEmployer(info.employername || "");
				setEmployerAddress(info.employeraddress || "");
				setEmployerState(info.employerstate || "");
				setEmployerCity(info.employercity || "");
				setIncomeRange(info.monthlyincomerange || "");
				setCompanyAnssid(info.companyanssid || "");
			}
		} catch (err) {
			setError("Existing employment information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	const getFamilyInformation = async () => {
		setLoading(true);
		try {
			const info = await axios
				.get(`/family`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setMaritalStatus(info.maritalstatus || "");
				setNoOfChildren((info.children || []).length);
				setSpouseFirstName(info.sousefirstname || "");
				setSpouseMiddleName(info.spousemiddlename || "");
				setSpouseSurname(info.spousesurname || "");
				setSpousePhoneNumber(info.spousephonenumber || "");
				setSpouseOccupation(info.spouseoccupation || "");
				setSpouseResidentialAddress(info.spousebusinessaddress || "");
				setChildrenDetails(info.children || []);
			}
		} catch (err) {
			setError("Existing family information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPersonalInformation();
		getOtherInformation();
		getIdentityInformation();
		getEmploymentInformation();
		getFamilyInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className={styles.print__details}>
			<div className={styles.header}>
				<h1>Print Details</h1>
				<ReactToPrint
					trigger={() => (
						<button
							className={["primary__btn", styles.print__btn].join(
								" "
							)}
						>
							Print Details
						</button>
					)}
					content={() => componentRef.current}
				/>
			</div>
			<main ref={componentRef}>
				<div className={styles.personal__details__div}>
					<div className={styles.personal__details}>
						<h2>Personal Details</h2>
						<table>
							<tbody>
								<tr>
									<th>Title</th>
									<td>{title}</td>
								</tr>
								<tr>
									<th>Firstname</th>
									<td>{firstname}</td>
								</tr>
								<tr>
									<th>Middlename</th>
									<td>{middlename}</td>
								</tr>
								<tr>
									<th>Surname</th>
									<td>{surname}</td>
								</tr>
								<tr>
									<th>Mother's Maiden name</th>
									<td>{mothermaidianname}</td>
								</tr>
								<tr>
									<th>Gender</th>
									<td>{sex}</td>
								</tr>
								<tr>
									<th>Date of Birth</th>
									<td>
										{new Date(
											dateofbirth
										).toLocaleDateString()}
									</td>
								</tr>
								<tr>
									<th>Phone no</th>
									<td>{phonenumber}</td>
								</tr>
								<tr>
									<th>Email Address</th>
									<td>{email}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className={styles.biometrics}>
						<h2>Biometrics</h2>
						<img src={cardImage} alt="profile" />
					</div>
				</div>
				<hr />
				<div className={styles.other__information__div}>
					<h2>Other Information</h2>
					<table>
						<tbody>
							<tr>
								<th>Nationality</th>
								<td>{nationality}</td>
							</tr>
							<tr>
								<th>State of Origin</th>
								<td>{stateoforigin}</td>
							</tr>
							<tr>
								<th>LGA of Origin</th>
								<td>{lgaoforigin}</td>
							</tr>
							<tr>
								<th>Residential Address</th>
								<td>{residentialaddress}</td>
							</tr>
							<tr>
								<th>State of Residence</th>
								<td>{stateofresidence}</td>
							</tr>
							<tr>
								<th>Local Govt. Area of Residence</th>
								<td>{lgaofresidence}</td>
							</tr>
							<tr>
								<th>Town of Residence</th>
								<td>{townofresidence}</td>
							</tr>
							<tr>
								<th>City of Residence</th>
								<td>{cityofresidence}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr />
				<div className={styles.family__info}>
					<h2>Family Information</h2>
					<table>
						<tbody>
							<tr>
								<th>Marital Status</th>
								<td>{maritalstatus}</td>
							</tr>
							<tr>
								<th>Children</th>
								<td>{childrennumber}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr />
				<div className={styles.employment__info__div}>
					<h2>Employment Information</h2>
					<table>
						<tbody>
							<tr>
								<th>Occupation</th>
								<td>{occupationtype}</td>
							</tr>
							<tr>
								<th>Employer's name</th>
								<td>{employername}</td>
							</tr>
							<tr>
								<th>City/Town of Employer's Address</th>
								<td>{employercity}</td>
							</tr>
							<tr>
								<th>Personal Monthly Income Range</th>
								<td>{monthlyincomerange}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr />
				<div className={styles.employment__info__div}>
					<h2>Proof of Identity</h2>
					<table>
						<tbody>
							<tr>
								<th>Identity Type</th>
								<td>{snakeToSentenceCase(idInfo)}</td>
							</tr>
							<tr>
								<th>Card Number</th>
								<td>{idNumber}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</main>
		</section>
	);
};

export default PersonalPrint;
