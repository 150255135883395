import { useState, createRef, useEffect } from "react";
import axios from "axios";
import * as faceapi from "face-api.js";
import uploadIcon from "../assets/upload.svg";
import Remove from '../assets/delete.svg';
import styles from "./style.module.css";
import altStyles from "../Identity-management-individual/style.module.css";
const coy_ANSSID = process.env.REACT_APP_ANSSID

// const useBusinessOwnerAnssid = ({ setCurrentStep, anssid, userId, urlPrefix = '' }) => {
const useBusinessOwnerAnssid = ({ setCurrentStep, anssid, userId, urlPrefix = '', locationState }) => {
	const [values, setValue] = useState({
		ownerAnssid: [""],
		CACCertificate: ""
	});
	// console.log('locationState  [pg2]  >>>  ', locationState)

	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [imagePreview, setImagePreview] = useState();

	const ownerAnssids = Array.isArray(values.ownerAnssid) ? values.ownerAnssid : [values.ownerAnssid];

	const fileInputRef = createRef();

	const getCompanyDetails = async () => {
		setLoading(true);
		try {
			let query = '';
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`${urlPrefix}/korporate/company/upload?${query}`)
				.then((res) => (res.data || {}).data);

			if (info) {
				setValue({
					...values,
					ownerAnssid: info.ownerAnssid
				});
				setImagePreview(info.certificate);
			}
		} catch (err) {
			console.error(err);
			// setError("Existing company information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCompanyDetails();
		// setCurrentStep(3);   //-- femi [page 3 soln]
		// console.log(' stepMap[1].state.numberOfStaff  [pg2] >>> ', locationState.numberOfStaff)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleChange(e) {
		const name = e.target.name;
		const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

		setValue({
			...values,
			[name]: value
		});
	}

	const handleSubmit = async (e) => {
		if (e) {
			e.preventDefault();
		}
		setError("");
		if (anssid) {
			setSubmitted(true);
			setCurrentStep(3);
			return;
		}

		setLoading(true);

		try {
			var cFlg = '0'
			var onlyAnssid = ''
			const accessToken = localStorage.getItem("access_token");
			const formData = new FormData();
			// console.log(' values.ownerAnssid   >>>>   ', values.ownerAnssid)
			// console.log(' ownerAnssids [isArray]  >>>>   ', ownerAnssids.isArray)
			// console.log(' ownerAnssids   >>>>   ', ownerAnssids)
			// console.log(' values.ownerAnssid Array length  >>>>   ', values.ownerAnssid.length)

			// formData.set("ownerAnssid", values.ownerAnssid)
			// formData.set("ownerAnssid", JSON.parse(values.ownerAnssid));
			// formData.set("ownerAnssid", `[ ${values.ownerAnssid} ]`)

			if (values.CACCertificate) {
				cFlg = '0'
				// console.log(' values.ownerAnssid [with img]   >>>>   ', values.ownerAnssid)
				// console.log("ownerAnssid [JSON.stringify]   >>>>>  ", JSON.stringify(ownerAnssids))

				// formData.append("ownerAnssid",  [values.ownerAnssid]);
				// formData.append("ownerAnssid", JSON.stringify(values.ownerAnssid))
				formData.append("ownerAnssid", `[ ${ownerAnssids} ]`)

				// Object.keys(ownerAnssids).forEach(key => {
				// 	formData.append(key, obj[key]);
				// });

				formData.append("file", values.CACCertificate);
			} else {
				cFlg = '1'
				// console.log(' values.ownerAnssid [with img]   >>>>   ', values.ownerAnssid)
				onlyAnssid =
				{
					"ownerAnssid": values.ownerAnssid
				}
			}

			// if (values.CACCertificate) {
			// 	formData.append("file", values.CACCertificate);
			// }

			let query = '';
			if (userId) {
				query += `userId=${userId}`;
			}

			// console.log('formdata  >>>>   ', formData)

			const data = {};
			formData.forEach((value, key) => (data[key] = value));
			// console.log('formdata   [iterated object]   >>>  ', data);
			// console.log('cFlg      >>>  ', cFlg);

			const res = await axios.post(`${urlPrefix}/korporate/company/upload?${query}`, (cFlg === '0' ? formData : onlyAnssid), {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				}
			}).then(res => res.data);

			console.log(res);

			setSubmitted(true);
			setCurrentStep(3);
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}

			setError(message || `Business ${coy_ANSSID} form could not be submitted. Please verify your details and try again.`);
		} finally {
			setLoading(false);
		}
	};

	const form = ({ footer, businessCategory }) => (
		<form className={styles.form} onSubmit={handleSubmit}>
			<div className={styles.div__wrapper}>
				{
					ownerAnssids.map((id, i) => (
						<div key={i}>
							<label htmlFor={`ownerAnssid${i}`}>
								Business Owner / Manager {ownerAnssids.length > 1 ? `${i + 1}` : ''}
							</label>
							<div className={styles.anssid__input__mobile}>
								<input
									type="text"
									name={`ownerAnssid${i}`}
									id={`ownerAnssid${i}`}
									className={styles.input__medium}
									style={{ marginRight: '2.8rem' }}
									onChange={e => {
										const target = { ...e.target, id: 'ownerAnssid', name: 'ownerAnssid' };
										ownerAnssids.splice(i, 1, e.target.value);
										target.value = ownerAnssids;
										handleChange({ ...e, target });
									}}
									min={0}
									value={id}
									placeholder="eg 8379302918"
								/>
								{
									!['Sole Proprietor'].includes(businessCategory) && (
										<button
											type="button"
											className={styles.remove_staff}
											onClick={e => {
												const target = { ...e.target, id: 'ownerAnssid', name: 'ownerAnssid' };
												ownerAnssids.splice(i, 1);
												target.value = ownerAnssids;
												handleChange({ ...e, target });
											}}>
											<img src={Remove} alt="remove" />
										</button>
									)
								}
							</div>
						</div>
					))
				}
				{
					!['Sole Proprietor'].includes(businessCategory) && (
						<div style={{ display: 'flex' }}>
							<button
								type="button"
								className={altStyles.add__another__child__btn}
								style={{ width: 'max-content', marginBottom: '20px' }}
								onClick={e => {
									const target = { ...e.target, id: 'ownerAnssid', name: 'ownerAnssid' };
									target.value = ownerAnssids.concat("");
									handleChange({ ...e, target });
								}}
							> + Add Business Owner's {coy_ANSSID}
							</button>
						</div>
					)
				}
			</div>
			<div className={styles.upload__div} style={{ marginBottom: 30 }}>
				<input
					id="CACCertificate"
					hidden
					type="file"
					ref={fileInputRef}
					name="CACCertificate"
					onChange={async (e) => {
						setLoading(true);
						setError("");

						const size = e.target.files?.[0]?.size;
						if (size < 10240 || size > 307200) {
							setError(`Uploaded file size must be between 10kb and 300kb. Current uploaded is ${size / 1024}kb`);
							return;
						}

						const { src: image } = await faceapi.bufferToImage(e.target.files[0]);
						setImagePreview(image);
						handleChange(e);
						setLoading(false);
					}}
					accept="image/*"
				// value={values.CACCertificate}
				/>
				<p className={styles.file__name}>{values.CACCertificate?.name}</p>
				<button
					aria-label="upload"
					type="button"
					onClick={() => fileInputRef.current.click()}
				>
					<img src={uploadIcon} alt="upload icon" />
				</button>
				<label
					htmlFor="CACCertificate"
					className={styles.upload__label}
				>
					Upload copy C.A.C certificate
				</label>
			</div>
			<div className={styles.upload__div} style={{ width: "100%", height: "34vh" }}>
				{
					imagePreview && (
						<img src={imagePreview} className={styles.image__preview} alt="Uploaded document" />
					)
				}
			</div>
			{footer}
		</form>
	);

	return {
		title: "Business Owner / Manager",
		description:
			`Please supply the business owner’s ${coy_ANSSID} and/or Upload the company’s CAC certificate or business registration document  which must be minimum 10 KB and maximum 300 KB and Click Continue to proceed OR Exit & Log out.`,
		form,
		state: {
			// values,
			// numberOfStaff,
			submitted,
			loading,
			error,
			disabled: !!anssid,
		},
		submit: handleSubmit
	};
};

export default useBusinessOwnerAnssid;
