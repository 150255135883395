import React from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./style.module.css";


function formatDate(date) {
	// console.log('date  >> ', date)
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const usePersonalInformation = ({
	setCurrentStep,
	anssid,
	userId,
	urlPrefix = "",
	locationState
}) => {
	// console.log('locationState [Personal] = ', locationState)
	// console.log('userInfo  = ', userInfo)
	// console.log('userId  = ', userId)
	// console.log('userInfo.account_type  = ', userInfo.account_type)
	// console.log('ssActive  = ', locationState.ssActive)

	const [title, setTitle] = React.useState("");
	const [firstname, setFirstName] = React.useState(
		!userId || userInfo.account_type === "individual"
			? userInfo.firstname || ""
			: locationState.firstname || ""
	);
	const [middlename, setMiddleName] = React.useState(
		!userId || userInfo.account_type === "individual"
			? userInfo.middlename || ""
			: locationState.middlename || ""
	);
	const [surname, setSurname] = React.useState(
		!userId || userInfo.account_type === "individual"
			? userInfo.lastname || ""
			: locationState.lastname || ""
	);
	const [mothermaidianname, setMaidenName] = React.useState("");
	const [sex, setGender] = React.useState("");
	const past = new Date();
	past.setFullYear(new Date().getFullYear() - 18);
	// const [dateofbirth, setDateOfBirth] = React.useState(formatDate(past));
	const [dateofbirth, setDateOfBirth] = React.useState(
		// !userId || userInfo.account_type === "individual"
		userInfo.account_type === "individual"
			? formatDate(userInfo.tinDoB)
			: !locationState.fromIndividual ? formatDate(past) : formatDate(locationState.tinDoB)
	);
	const [phonenumber, setPhoneNumber] = React.useState(
		!userId || userInfo.account_type === "individual"
			? userInfo.phonenumber || ""
			: locationState.phone || ""
	);
	const [email, setEmailAddress] = React.useState(
		!userId || userInfo.account_type === "individual"
			? userInfo.email || ""
			: locationState.email || ""
	);

	const [submitted, setSubmitted] = React.useState(true);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState();

	const [titles, setTitles] = React.useState([]);

	const getPersonalInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`${urlPrefix}/users?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				// //---
				// const dg = info.dateofbirth;
				// var kl = dg.split('-');
				// var nDate = "";
				// // console.log(kl[2].length);
				// if (kl[2].length === 4) {
				// 	nDate = kl[2] + '-' + kl[1] + '-' + kl[0];
				// }
				// //---
				// console.log('nDate = ', nDate)
				// console.log('nDate = ', formatDate(new Date(nDate)));

				setTitle(info.title || "");
				setFirstName(info.firstname || firstname);
				setMiddleName(info.middlename || middlename);
				setSurname(info.surname || surname);
				setMaidenName(info.mothermaidianname || "");
				setGender((info.sex || "").toLowerCase());
				setDateOfBirth(formatDate((info.dateofbirth || past)));
				setPhoneNumber(info.phonenumber || phonenumber);
				setEmailAddress(info.email || email);
			}
		} catch (err) {
			console.error(err);
			// setError("Existing personal information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	const getTitles = async () => {
		try {
			const info = await axios
				.get(`/settings/title`)
				.then((res) => (res.data || {}).data);
			setTitles(info);
		} catch (err) {
			console.error(err);
		}
	};

	React.useEffect(() => {
		getPersonalInformation();

		getTitles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const submitPersonalData = async (e) => {
		e.preventDefault();
		setError("");
		if (anssid) {
			setSubmitted(true);
			setCurrentStep(2);
			return;
		}

		setLoading(true);

		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}
			const accessToken = localStorage.getItem("access_token");
			const res = await axios
				.post(
					`${urlPrefix}/users?${query}`,
					{
						title,
						surname,
						firstname,
						middlename,
						mothermaidianname,
						sex,
						dateofbirth
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);

			console.log(res);

			setSubmitted(true);
			setCurrentStep(2);
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}
			setError(
				message ||
				"Personal information could not be submitted. Please verify your details and try again."
			);
		} finally {
			setLoading(false);
		}
	};

	const form = ({ footer }) => (
		<form className={styles.columned__form} onSubmit={submitPersonalData}>

			<label className={styles.input__label} htmlFor="title">
				Title <span style={{ color: "red" }}>*</span>
			</label>
			<select
				id="title"
				className={styles.select__input}
				required
				value={title}
				onChange={(e) => setTitle(e.target.value)}
			>
				<option value="">--Select Title--</option>
				{titles.map((title) => (
					<option key={title.Description}>{title.Description}</option>
				))}
			</select>
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="firstName">
						First name <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="text"
						onChange={(e) => setFirstName(e.target.value)}
						id="firstName"
						placeholder="eg John"
						required
						disabled={!userId}
						value={firstname}
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="middleName">
						Middle name
					</label>
					<input
						type="text"
						onChange={(e) => setMiddleName(e.target.value)}
						id="middleName"
						placeholder="eg Jude"
						value={middlename}
					/>
				</div>
			</div>
			<label className={styles.input__label} htmlFor="surname">
				Surname <span style={{ color: "red" }}>*</span>
			</label>
			<input
				type="text"
				onChange={(e) => setSurname(e.target.value)}
				id="surname"
				placeholder="eg Doe"
				required
				disabled={!userId}
				value={surname}
			/>
			<hr className={styles.divider} />
			<label className={styles.input__label} htmlFor="maidenName">
				Mother’s Maiden name <span style={{ color: "red" }}>*</span>
			</label>
			<input
				type="text"
				onChange={(e) => setMaidenName(e.target.value)}
				id="maidenName"
				placeholder="eg John"
				value={mothermaidianname}
			/>
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="gender">
						Gender <span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="gender"
						className={styles.select__input}
						required
						value={sex}
						onChange={(e) => setGender(e.target.value)}
					>
						<option value="">--Select Gender--</option>
						<option value="female">Female</option>
						<option value="male">Male</option>
						<option value="others">Other</option>
					</select>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="dateOfBirth"
					>
						Date of Birth <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="date"
						id="dateOfBirth"
						name="dateOfBirth"
						onChange={(e) => setDateOfBirth(e.target.value)}
						max={formatDate(past)}
						value={dateofbirth}
						required
					/>
				</div>
			</div>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="phoneNumber"
					>
						Phone no
					</label>
					<input
						type="text"
						onChange={(e) => setPhoneNumber(e.target.value)}
						id="phoneNumber"
						placeholder="08012345678"
						required
						disabled={!userId}
						value={phonenumber}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="emailAddress"
					>
						Email Address
					</label>
					<input
						type="text"
						onChange={(e) => setEmailAddress(e.target.value)}
						id="emailAddress"
						placeholder="john.doe@gmail.com"
						required
						disabled={!userId}
						value={email}
					/>
				</div>
			</div>
			{footer}
		</form>
	);

	return {
		title: "Personal Data",
		description:
			"This is about YOU in details. Please enter your personal information in the appropriate fields as required on this page, noting those marked with the asterick (*) sign as compulsory and Click Continue to proceed OR Exit & Log out.",
		form,
		state: {
			firstname,
			surname,
			submitted,
			loading,
			error,
			disabled: !!anssid
		},
		submit: submitPersonalData
	};
};

export default usePersonalInformation;

