import React from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import axios from "axios";

import Sidebar from "../../components/sidebar";
import Success from "./final";
import styles from "../Login/style.module.css";

const OTP = ({ title = "" }) => {
	const history = useHistory();
	const route = useRouteMatch();
	const location = useLocation();

	const [uid, setUID] = React.useState("");
	const [code, setCode] = React.useState("");
	const [fetching, setFetching] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState();

	const [error, setError] = React.useState();

	const parseQuery = async () => {
		const query = new URLSearchParams(location.search).get("query");
		if (query) {
			try {
				const values = JSON.parse(atob(query));

				history.push(location.pathname);

				setFetching(true);
				const { success } = await axios
					.put(`/auth/otp`, values)
					.then((res) => res?.data?.data);

				setFetching(false);
				if (!success) {
					throw new Error("OTP verification failed.");
				}

				if (/phone/i.test(route.path)) {
					// history.push("/otp-email");
					setSuccessMessage(
						"Phone number verified successfully. Click log in to continue"
					);
				} else {
					// history.push("/login");
					setSuccessMessage(
						"Email verified successfully. Click log in to continue"
					);
				}
			} catch (err) {
				setFetching(false);
				const message = err?.response?.data?.error || err.message;
				setError(message);
				console.error(err);
			}
		}
	};

	React.useEffect(() => {
		parseQuery();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setUID("");
		setCode("");
	}, [title]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setFetching(true);
			const { success } = await axios
				.put(`/auth/otp`, {
					uid,
					code
				})
				.then((res) => res?.data?.data);

			setFetching(false);
			if (!success) {
				throw new Error("OTP verification failed.");
			}

			if (/phone/i.test(route.path)) {
				// history.push("/otp-email");
				setSuccessMessage(
					"Phone number verified successfully. Click log in to continue"
				);
			} else {
				// history.push("/login");
				setSuccessMessage(
					"Email verified successfully. Click log in to continue"
				);
			}
		} catch (err) {
			setFetching(false);
			const message = err?.response?.data?.error || err.message;
			setError(message);
		}
	};

	const resendOTP = async (e) => {
		e.preventDefault();

		try {
			setFetching(true);
			const { success } = await axios
				.post(`/auth/otp?type=${title.toLowerCase()}`, {
					uid
				})
				.then((res) => res?.data?.data);

			setFetching(false);
			if (!success) {
				throw new Error("OTP resend failed.");
			}
		} catch (err) {
			setFetching(false);
			const message = err?.response?.data?.error || err.message;
			setError(message);
			console.error(err);
		}
	};

	return (
		<section className={styles.wrapper}>
			<Sidebar />
			{successMessage ? (
				<Success message={successMessage} />
			) : (
				<main
					className={styles.auth__form__container}
					style={{ width: "500px" }}
				>
					{error && <div className={styles.error}>{error}</div>}
					<h1 className={styles.heading__text}>
						{title} Verification
					</h1>
					<p className={styles.sub__heading__text}>
						An OTP has been sent to your phone, kindly key it in the
						text to verify your phone number. If you do not receive
						it, log into your email and click the{" "}
						<b>"Confirm Account"</b> link to continue the
						registration process.
					</p>
					<form className={styles.auth__form} onSubmit={handleSubmit}>
						<label htmlFor="uid">{title} *</label>
						<input
							id="uid"
							type="text"
							name={/email/i.test(title) ? "email" : "text"}
							value={uid}
							onChange={(e) => setUID(e.target.value)}
							placeholder={
								/email/i.test(title)
									? "john.doe@gmail.com"
									: "+234901923003944"
							}
							required
							disabled={fetching}
						/>
						<label htmlFor="code">OTP *</label>
						<input
							id="code"
							type="text"
							name="code"
							value={code}
							onChange={(e) => setCode(e.target.value)}
							placeholder="435344"
							required
							disabled={fetching}
						/>
						<button
							type="submit"
							className={[
								"primary__btn",
								styles.primary__button
							].join(" ")}
							disabled={!code || !uid}
						>
							{fetching ? "Verifying..." : "Verify"}
						</button>
						{uid && (
							<p>
								<button
									onClick={resendOTP}
									className="link__button"
									type="button"
								>
									Resend OTP code
								</button>
							</p>
						)}
					</form>
				</main>
			)}
		</section>
	);
};

export default OTP;
