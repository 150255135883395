import React from 'react';
import { NavLink } from "react-router-dom";
// import Logo from "../../assets/logo.svg";
import styles from "./style.module.css";

const coy_ANSSID_SIDEBAR_LOGO = process.env.REACT_APP_ANSSID_SIDEBAR_LOGO
const coy_ANSCIMS_SIDEBAR_LOGO = process.env.REACT_APP_ANSCIMS_SIDEBBAR_LOGO

const Sidebar = ({ title, description, login, signup, isAnscims }) => (
  <aside className={styles.backdrop}>
    <div className={styles.logo__div}>
      {/* <img src={Logo} alt="norlics logo" /> */}
      {/* <img src={isAnscims ? '/anscims_logo.svg' : '/anssid_logo.png'} alt="anssid logo" /> */}
      <img src={isAnscims ? coy_ANSSID_SIDEBAR_LOGO : coy_ANSCIMS_SIDEBAR_LOGO} alt="anssid logo" />
      {
        title && (<hr className={styles.title__divider} />)
      }
    </div>
    <div>
      <p className={styles.minor__title__text}>{title}</p>
      <p className={styles.description__text}>{description}</p>
    </div>
    <footer className={styles.footer}>
      {
        !isAnscims && (signup || login) && (
          login
            ? (<p> Don't have an account? <NavLink to="/signup">Sign up</NavLink></p>)
            : (<p>Already have an account? <NavLink to="/login">Log in</NavLink></p>)
        )
      }
      <p>Forgot your password? <NavLink to="/reset-password">Click here to reset password</NavLink></p>
    </footer>
  </aside >
);

export default Sidebar;
