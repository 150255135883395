/* eslint-disable eqeqeq */
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Wizard from "../components/wizard";
import useProofOfIdentity from "./identity.proof.hooks";
import useEmploymentInformation from "./employment.information.hooks";
import usePersonalInformation from "./personal.information.hooks";
import useOtherInformation from "./other.information";
import useFamilyInformation from "./family.information";
import Spinner from "../components/spinner";

import useBiometrics from "../Biometrics";
import styles from "./style.module.css";

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const IndividualIdentityManagement = () => {
	const history = useHistory();
	const { state: locationState = {}, search: searchParams } = useLocation();

	const userId =
		locationState.userId ||
		new URLSearchParams(searchParams).get("userId") ||
		userInfo.id;
	const edit = locationState?.edit;

	const numberOfSteps = 6;
	const [currentStep, setCurrentStep] = React.useState(1);
	const [lgas, setLgas] = React.useState([]);
	const [anssid, setAnssid] = React.useState();
	const [, setError] = React.useState();

	const urlPrefix = userId ? "/admin/individual-taxpayer" : "";
	const stepMap = {
		1: usePersonalInformation({
			setCurrentStep,
			anssid,
			userId,
			urlPrefix,
			locationState
		}),
		2: useOtherInformation({ setCurrentStep, anssid, userId, urlPrefix }),
		3: useFamilyInformation({ setCurrentStep, anssid, userId, urlPrefix }),
		4: useEmploymentInformation({
			setCurrentStep,
			anssid,
			userId,
			urlPrefix
		}),
		5: useProofOfIdentity({ setCurrentStep, anssid, userId, urlPrefix }),
		6: useBiometrics({ setCurrentStep, anssid, userId, urlPrefix, edit })
	};

	const stepInfo = stepMap[currentStep] || {};

	const getUserInfo = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/auth/profile`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setAnssid(info.anssid);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getAnssid = async () => {
		try {
			if (anssid) return anssid;

			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			console.log('stepMap[2].state   >>>  ', stepMap[2].state)

			// const lgaoforigin = (lgas || []).find(
			// 	(l) => l.Description === stepMap[2].state.lgaoforigin
			// );
			// const lgaId = lgaoforigin.Code;

			var lgaoforigin = '';
			var lgaId = '';
			if (stepMap[2].state.nationality.toLowerCase().trim() === "nigeria") {
				console.log('famx......')
				lgaoforigin = (lgas || []).find((l) => l.Description === stepMap[2].state.lgaoforigin);
				lgaId = lgaoforigin.Code;
			} else {
				console.log('faz....')
				lgaoforigin = '000021'
				lgaId = lgaoforigin;
			}
			console.log('lgaoforigin  >>>  ', lgaoforigin)
			console.log('lgaid  >>>  ', lgaId)

			const res = await axios
				.post(`${urlPrefix}/system/generateuseranssid?${query}`, {
					lgaId,
					lgaCode: lgaId,
					lgaSerial: lgaId
				})
				.then((res) => (res.data || {}).data);

			if (!userId) {
				try {
					if (res.anssid && !userInfo.anssid) {
						localStorage.setItem(
							"user_info",
							JSON.stringify({ ...userInfo, anssid: res.anssid })
						);
					}
				} catch (e) {
					console.error(e);
				}
			}
			console.log('anssid  >>> ', anssid)
			return res.anssid;
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}
			setError(
				message || "An error occurred while generating user anssid."
			);
		}
	};

	const getLGAs = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/lga`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setLgas(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const resetAnssid = async () => {
		try {
			const req = await axios.post(`${urlPrefix}/resetRejectAnssid`, {
				anssidCode: userInfo.ssActive === "2" ? userId : userInfo.id
			});

			if (req) return req.data;
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	React.useEffect(() => {
		getUserInfo();
		getLGAs();
	}, []);

	React.useEffect(() => {
		const isLoading = Object.keys(stepMap).find(
			(key) => stepMap[key].state.loading
		);

		if (
			stepInfo?.state?.submitted &&
			!isLoading &&
			!stepInfo?.state?.error
		) {
			if (currentStep === numberOfSteps) {
				if (userInfo.ssActive === "2") {
					getAnssid().then((anssid) => {
						history.push("/IM-success", {
							anssid,
							firstname:
								locationState?.firstname || userInfo?.firstname,
							lastname:
								locationState?.lastname || userInfo?.lastname
						});
					});
				} else {
					resetAnssid().then(() => {
						history.push("/IM-success", {
							// anssid,
							firstname:
								locationState?.firstname || userInfo?.firstname,
							lastname:
								locationState?.lastname || userInfo?.lastname
						});
					})
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepInfo?.state?.submitted, stepInfo?.state?.loading]);

	return (
		<Wizard
			title={stepInfo?.title}
			description={stepInfo?.description}
			numberOfSteps={numberOfSteps}
			currentStep={currentStep}
			setCurrentStep={setCurrentStep}
			error={stepInfo?.state?.error}
		>
			{stepInfo?.form({
				footer: (
					<footer className={styles.form__footer}>
						<button
							type="button"
							className={["secondary__btn"].join(" ")}
							onClick={async () => {
								await stepInfo?.submit();
								localStorage.removeItem("user_info");
								localStorage.removeItem("access_token");
								history.push("/login");
							}}
						>
							Save & Exit
						</button>
						<button
							disabled={
								stepInfo?.state?.loading ||
								stepInfo?.state?.disabled
							}
							type="submit"
							id="buttonIdentityManagement"
							className={[
								"primary__btn",
								styles.accent__button,
								stepInfo?.state?.loading
									? styles.loading__button
									: ""
							].join(" ")}
						>
							{stepInfo?.state?.loading
								? "Saving..."
								: currentStep == numberOfSteps
									? "Finish"
									: "Continue"}
							{stepInfo?.state?.loading && <Spinner />}
						</button>
					</footer>
				),
				setCurrentStep,
				...stepInfo?.state
			})}
		</Wizard>
	);
};

export default IndividualIdentityManagement;
