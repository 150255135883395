import React from "react";
import { NavLink, useHistory } from "react-router-dom";
// import Logo from "../../assets/anssidLogo.svg";
import styles from "./style.module.css";
const Logo = process.env.REACT_APP_ANSCIMS_MOBILE_LOGO

const UserSidenav = ({ navRef, menuRef }) => {
	const history = useHistory();

	return (
		<>
			<nav className={styles.nav} ref={navRef}>
				<div className={styles.logo}>
					<img src={Logo} alt="logo" />
				</div>
				<ul className={styles.nav__list}>
					<li>
						<div>
							<NavLink
								exact
								to="/"
								activeClassName={styles.active}
								className={styles.nav__button}
							>
								<span>Home</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<NavLink
								exact
								to="/details"
								isActive={(_, loc) => /details/i.test(loc.pathname)}
								activeClassName={styles.active}
								className={styles.nav__button}
							>
								<span>User Details</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<NavLink
								exact
								to="/support"
								activeClassName={styles.active}
								className={styles.nav__button}
							>
								<span>Contact Support</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<NavLink
								exact
								to="/password"
								activeClassName={styles.active}
								className={styles.nav__button}
							>
								<span>Change Password</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					{/* <li>
					<div>
						<NavLink
							exact
							to="/anssid"
							activeClassName={styles.active}
							className={styles.nav__button}
						>
							<span>Print ANSSID</span>
						</NavLink>
					</div>
					<hr className={styles.divider} />
				</li> */}
					<li>
						<div>
							<NavLink
								exact
								to="/print"
								activeClassName={styles.active}
								className={styles.nav__button}
							>
								<span>Print Details</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<button
								className={styles.nav__button}
								onClick={() => {
									localStorage.removeItem("user_info");
									localStorage.removeItem("access_token");
									history.push("/login");
								}}>
								<span>Logout</span>
							</button>
						</div>
					</li>
				</ul>
			</nav>
			<div className={styles.menu__toggle} ref={menuRef}>
				<button className={styles.menu__button} onClick={() => {
					navRef.current.classList.toggle(styles.no__display);
					menuRef.current.classList.toggle(styles.collapsed__menu);
				}}></button>
				<div onClick={() => {
					navRef.current.classList.add(styles.no__display);
					menuRef.current.classList.add(styles.collapsed__menu);
				}}></div>
			</div>
		</>
	);
};

export default UserSidenav;
