import React from "react";
import styles from "./style.module.css";
import signupStyles from "../../Auth/SignUp/signup.module.css";
import Sidebar from "../sidebar";

const coy_ANSSID_SIDEBAR_LOGO = process.env.REACT_APP_ANSSID_SIDEBAR_LOGO


/**
 * @param {Object} props - Props object that is immediately destructured
 * @param {string} props.title - This is the title for the particular form
 * @param {string} props.description - This is the description for the form
 * @param {number} props.numberOfSteps - This is the total number of steps. It determines the number of circle indicators
 * @param {number} props.currentStep - This is the current step number for the particular form
 * @param {Function} props.setCurrentStep - This is a function (ideally a function that calls setState or a function gotten from the useState hook) capable of changing the currentStep state that should be maintained in the calling component
 * @param {JSX.Element} props.children - Actual form itself. Can either be passed explicitly with the children prop or by nesting like <Wizard><ChildrenComponent /></Wizard>
 * @param {JSX.Element} props.headerExtra - Extra component to add to header
 */
const Wizard = ({
	title,
	description,
	numberOfSteps = 3,
	currentStep = 1,
	setCurrentStep = () => { },
	children,
	error,
	headerExtra
}) => {
	currentStep -= 1;

	return (
		<section className={styles.wrapper}>
			<Sidebar title={title} description={description} />
			<section className={styles.main__container}>
				<div
					className={signupStyles.top}
					style={{ paddingBottom: "2px" }}
				>
					<div className={signupStyles.mobile__logo}>
						{/* <img src={"/anssid_logo.png"} alt="anssid logo" /> */}
						<img src={coy_ANSSID_SIDEBAR_LOGO} alt="anssid logo" />
					</div>
					<h1 className={styles.mobile__h1}>{title}</h1>
				</div>
				{error && <div className={styles.error}>{error}</div>}
				<header
					style={
						headerExtra
							? {
								display: "flex",
								justifyContent: "space-around"
							}
							: {}
					}
				>
					<div className={styles.indicator__header}>
						{numberOfSteps > 1 &&
							Array(numberOfSteps)
								.fill(0)
								.map((_, i) => (
									<div key={i}>
										<button
											className={[
												styles.circle,
												i === currentStep
													? styles.current__step__circle
													: "",
												i < currentStep
													? styles.prev__step__circle
													: ""
											].join(" ")}
											onClick={() =>
												setCurrentStep(i + 1)
											}
										>
											{i + 1}
										</button>
										{i < numberOfSteps - 1 && (
											<hr
												className={[
													styles.indicator__divider,
													i < currentStep
														? styles.current__step__divider
														: ""
												].join(" ")}
											/>
										)}
									</div>
								))}
					</div>
					{headerExtra}
				</header>
				<main>
					<h1 className={styles.major__title__text}>{title}</h1>
					<div className={styles.children__wrapper}>{children}</div>
				</main>
			</section>
		</section>
	);
};

export default Wizard;
