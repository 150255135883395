import React from "react";
import Wizard from "../../components/wizard";
import Spinner from "../../components/spinner";
import useStepOne from "./StepOne";
import useStepTwo from "./StepTwo";

import styles from "../../Identity-management-individual/style.module.css";

const ResetPassword = () => {

  const numberOfSteps = 2;
	const [currentStep, setCurrentStep] = React.useState(1);

  const stepOneInfo = useStepOne({ setCurrentStep });

  const stepMap = {
		1: stepOneInfo,
    2: useStepTwo({ setCurrentStep, ...stepOneInfo.state })
	};

  const stepInfo = stepMap[currentStep] || {};

  return (
    <Wizard
			title={stepInfo?.title}
			description={stepInfo?.description}
			numberOfSteps={numberOfSteps}
			currentStep={currentStep}
			setCurrentStep={setCurrentStep}
			error={stepInfo?.state?.error}
		>
			{stepInfo?.form({
				footer: (
					<footer className={styles.form__footer}>
						<button
							disabled={stepInfo?.state?.loading || stepInfo?.state?.disabled}
							type="submit"
							className={[styles.accent__button, stepInfo?.state?.loading ? styles.loading__button : ""].join(" ")}>
							{stepInfo?.state?.loading ? 'Saving...' : currentStep === numberOfSteps ? 'Finish' : 'Continue'}
							{stepInfo?.state?.loading && (<Spinner />)}
						</button>
					</footer>
				),
				setCurrentStep,
				...stepInfo?.state
			})}
		</Wizard>
  );
};

export default ResetPassword;
