import { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import CreateTaxPayer from "./create";
import styles from "../MDAs/style.module.css";
import anssidStyles from "./style.module.css";
const coy_ANSSID = process.env.REACT_APP_ANSSID

const TaxPayers = () => {
	const history = useHistory();

	const [createModal, setCreateModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [anssid, setAnssid] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [taxpayerName, setTaxpayername] = useState("");
	const [taxpayerEmail, setTaxpayerEmail] = useState("");
	const [taxpayerPhone, setTaxpayerPhone] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const filterRef = createRef();

	const headers = [
		[`${coy_ANSSID}`, "anssid"],
		["Individual/Contact Name", "name"],
		["Email Address", "email"],
		["Phone no", "phone"],
		["Marital Status", "maritalstatus"]
	];

	const getTaxPayers =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current.classList.contains(styles.no__display)) {
				filterRef.current.classList.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=20&`;
			if (anssid) {
				query += `anssid=${anssid}&`;
			}

			if (startDate) {
				query += `start=${startDate}&`;
			}

			if (endDate) {
				query += `end=${endDate}&`;
			}

			if (taxpayerName) {
				query += `taxpayerName=${taxpayerName}&`;
			}

			if (taxpayerEmail) {
				query += `email=${taxpayerEmail}&`;
			}

			if (taxpayerPhone) {
				query += `phone=${taxpayerPhone}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let { data, total } = await axios
					.get(`/reports/gettaxpayersindividual?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => (res.data || { data: {} }).data);

				if (!data) {
					// handle errors
					return null;
				}

				data = data.map((d) => {
					return {
						...d,
						anssid: d.anssid,
						name: `${d.firstname} ${d.lastname}`,
						corporateName: /corporate/i.test(d.accountType)
							? d?.CorporateCompanyInfo?.name
							: undefined,
						email: d.email,
						phone: d.phonenumber,
						taxpayertype: d.accountType,
						maritalstatus: d?.UserFamilyInfo?.maritalstatus
					};
				});

				setData(data);
				setTotal(total);
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	const clearFilters = () => {
		setAnssid("");
		setStartDate("");
		setEndDate("");
		setTaxpayername("");
		setTaxpayerEmail("");
		setTaxpayerPhone("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getTaxPayers()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getTaxPayers()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button
					className={["primary__btn", anssidStyles.create__btn].join(
						" "
					)}
					onClick={() => {
						history.push("/create-user", {
							taxpayers: true,
							fromIndividual: true,
							ssActive: "1"
						});
					}}
				>
					Create {coy_ANSSID}
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!anssid &&
								!startDate &&
								!endDate &&
								!taxpayerName &&
								!taxpayerEmail &&
								!taxpayerPhone
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="anssid">{coy_ANSSID}</label>
								<input
									name="anssid"
									id="anssid"
									type="text"
									value={anssid}
									onChange={(e) => setAnssid(e.target.value)}
									placeholder={coy_ANSSID}
								/>
							</div>
						</form>
						<p>By Taxpayer</p>
						<form>
							<div>
								<label htmlFor="taxpayer_name">
									Taxpayer Name
								</label>
								<input
									name="taxpayer_name"
									id="taxpayer_name"
									type="text"
									value={taxpayerName}
									onChange={(e) =>
										setTaxpayername(e.target.value)
									}
									placeholder="Eg Odogwu Okeke"
								/>
							</div>
							<div>
								<label htmlFor="taxpayer_email">
									Taxpayer Email
								</label>
								<input
									name="taxpayer_email"
									id="taxpayer_email"
									type="email"
									value={taxpayerEmail}
									onChange={(e) =>
										setTaxpayerEmail(e.target.value)
									}
									placeholder="Eg. chukwu@gmail.com"
								/>
							</div>
							<div>
								<label htmlFor="taxpayer_phone">
									Taxpayer Phone
								</label>
								<input
									name="taxpayer_phone"
									id="taxpayer_phone"
									type="number"
									value={taxpayerPhone}
									onChange={(e) =>
										setTaxpayerPhone(e.target.value)
									}
									placeholder="Eg. +2348012345678"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getTaxPayers()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getTaxPayers}
						onEdit={(data) => {
							history.push(
								`/${data.accountType}-identity-management?userId=${data.id}`,
								{
									...data,
									phone: data.phonenumber,
									userId: data.id,
									edit: true
								}
							);
						}}
						onView={(data) => {
							history.push(
								`/${data.accountType}-details?userId=${data.id}`,
								{ userId: data.id }
							);
						}}
					/>
				)}
			</div>
			{createModal && (
				<Modal small showing={createModal} toggle={setCreateModal}>
					<CreateTaxPayer />
				</Modal>
			)}
		</div>
	);
};

export default TaxPayers;
