/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import ArrowRight from "../../assets/arrowRight.svg";
import ArrowLeft from "../../assets/arrowLeft.svg";
import styles from "../../Identity-management-individual/style.module.css";
import style1 from "./style.module.css";

const FamilyInfo = ({ next, prev, userId, query }) => {
	const { state } = useLocation();
	const [maritalstatus, setMaritalStatus] = React.useState("");
	const [childrennumber, setNoOfChildren] = React.useState(0);
	const [spousefirstname, setSpouseFirstName] = React.useState("");
	const [spousemiddlename, setSpouseMiddleName] = React.useState("");
	const [spousesurname, setSpouseSurname] = React.useState("");
	const [spousephonenumber, setSpousePhoneNumber] = React.useState("");
	const [spouseoccupation, setSpouseOccupation] = React.useState("");
	const [spousebusinessaddress, setSpouseResidentialAddress] =
		React.useState("");
	const [childrenDetailsArray, setChildrenDetails] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState();

	const getFamilyInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`/users/family?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setMaritalStatus(info.maritalstatus || "");
				setNoOfChildren((info.children || []).length);
				setSpouseFirstName(info.spousefirstname || "");
				setSpouseMiddleName(info.spousemiddlename || "");
				setSpouseSurname(info.spousesurname || "");
				setSpousePhoneNumber(info.spousephonenumber || "");
				setSpouseOccupation(info.spouseoccupation || "");
				setSpouseResidentialAddress(info.spousebusinessaddress || "");
				setChildrenDetails(info.children || []);
			}
		} catch (err) {
			setError("Existing family information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		getFamilyInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={style1.title__div}>
				<Link
					to={{
						pathname: prev,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					<img src={ArrowLeft} alt="left arrow" /> Prev
				</Link>
				<h1>Family Information</h1>
				<Link
					to={{
						pathname: next,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					Next <img src={ArrowRight} alt="next arrow" />
				</Link>
			</div>
			<form className={styles.columned__form}>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="maritalStatus"
						>
							Marital Status
						</label>
						<input
							type="text"
							id="marital status"
							value={maritalstatus}
							disabled
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="noOfChildren"
						>
							Children
						</label>
						<input
							type="number"
							id="noOfChildren"
							min={0}
							disabled
							value={childrennumber}
						/>
					</div>
				</div>
				<hr className={styles.divider} />
				{!/single/i.test(maritalstatus) && (
					<>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="spouseFirstName"
								>
									{maritalstatus === "Divorced" ? "Ex-" : ""}
									Spouse first name{" "}
								</label>
								<input
									type="text"
									id="spouseFirstName"
									disabled
									value={spousefirstname}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="spouseMiddleName"
								>
									{maritalstatus === "Divorced" ? "Ex-" : ""}
									Spouse Middle name
								</label>
								<input
									type="text"
									id="spouseMiddleName"
									disabled
									value={spousemiddlename}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="spouseSurname"
								>
									{maritalstatus === "Divorced" ? "Ex-" : ""}
									Spouse Surname{" "}
								</label>
								<input
									type="text"
									id="spouseSurname"
									disabled
									value={spousesurname}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="spousePhoneNumber"
								>
									{maritalstatus === "Divorced" ? "Ex-" : ""}
									Spouse Phone no{" "}
								</label>
								<input
									type="text"
									id="spousePhoneNumber"
									disabled
									value={spousephonenumber}
								/>
							</div>
						</div>
						<hr className={styles.divider} />
						<label
							className={styles.input__label}
							htmlFor="spouseOccupation"
						>
							{maritalstatus === "Divorced" ? "Ex-" : ""}Spouse
							Occupation
						</label>
						<input
							type="text"
							id="spouse occupation"
							value={spouseoccupation}
							disabled
						/>
						<label
							className={styles.input__label}
							htmlFor="spouseResidentialAddress"
						>
							Spouse Residential Address{" "}
						</label>
						<input
							type="text"
							id="spouseResidentialAddress"
							long="true"
							disabled
							value={spousebusinessaddress}
						/>
						<hr className={styles.divider} />
					</>
				)}
				{!!childrennumber && +childrennumber > 0 && (
					<>
						{childrenDetailsArray.map((child, i) => (
							<React.Fragment key={i}>
								<p>Child {i + 1}</p>
								<div className={styles.double__inputs}>
									<div>
										<label
											className={styles.input__label}
											htmlFor="firstname"
										>
											Child's First name
										</label>
										<input
											type="text"
											name="firstname"
											id="firstname"
											disabled
											value={child.firstname}
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="middlename"
										>
											Child’s Middle name
										</label>
										<input
											type="text"
											name="middlename"
											id="middlename"
											value={child.middlename}
											disabled
										/>
									</div>
								</div>
								<label
									className={styles.input__label}
									htmlFor="surname"
								>
									Child's surname
								</label>
								<input
									type="text"
									name="surname"
									id="surname"
									disabled
									value={child.surname}
								/>
								<label
									className={styles.input__label}
									htmlFor="school"
								>
									Child's school
								</label>
								<input
									type="text"
									name="school"
									id="school"
									long="true"
									value={child.school}
									disabled
								/>
								<label
									className={styles.input__label}
									htmlFor="schooladdress"
								>
									Child's school address
								</label>
								<input
									type="text"
									name="schooladdress"
									id="schooladdress"
									long="true"
									disabled
									value={child.schooladdress}
								/>
								<hr className={styles.divider} />
							</React.Fragment>
						))}
					</>
				)}
			</form>
		</>
	);
};

export default FamilyInfo;
