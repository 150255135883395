/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line, Bar, Pie } from "react-chartjs-2";
import { useHistory } from "react-router";
import { formatAmount, percentIncrease } from "../../utils";

import redBill from "../../assets/SpecialDashboard/red.svg";
import blueBill from "../../assets/SpecialDashboard/blue.svg";
import greenBill from "../../assets/SpecialDashboard/green.svg";
import purpleBill from "../../assets/SpecialDashboard/purple.svg";

import greenProfile from "../../assets/SpecialDashboard/greenProfile.svg";
import blueProfile from "../../assets/SpecialDashboard/blueProfile.svg";
import redProfile from "../../assets/SpecialDashboard/redProfile.svg";

import styles from "./style.module.css";
import chartStyles from "../summary/style.module.css";
import { format } from "date-fns";

const coy_ANSSID = process.env.REACT_APP_ANSSID

const getCurrentQuarter = () => {
	const today = new Date();
	const currentMonth = today.getMonth() + 1;
	if (currentMonth <= 3) {
		return 1;
	} else if (currentMonth <= 6) {
		return 2;
	} else if (currentMonth <= 9) {
		return 3;
	} else {
		return 4;
	}
};

const Dashboard = () => {
	const history = useHistory();
	const monthlyLabels = [
		"JAN",
		"FEB",
		"MAR",
		"APR",
		"MAY",
		"JUN",
		"JUL",
		"AUG",
		"SEPT",
		"OCT",
		"NOV",
		"DEC"
	];
	const dailyLabels = ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"];
	const quarterlyLabels = ["Q1", "Q2", "Q3", "Q4"];
	const yearlyLabels = [
		new Date().getFullYear(),
		new Date().getFullYear() - 1,
		new Date().getFullYear() - 2,
		new Date().getFullYear() - 3
	];

	const [todaysRevenue, setTodaysRevenue] = useState(0);
	const [yesterdaysRevenue, setYesterdaysRevenue] = useState(0);
	const [currWeekRevenue, setCurrWeekRevenue] = useState(0);
	const [currWeekRevenueBreakdown, setCurrWeekRevenueBreakdown] = useState(
		[]
	);
	const [prevWeekRevenue, setPrevWeekRevenue] = useState(0);
	const [currMonthsRevenue, setCurrMonthsRevenue] = useState(0);
	const [prevMonthsRevenue, setPrevMonthsRevenue] = useState(0);
	const [revenuesByMonth, setRevenuesByMonth] = useState([]);
	const [currMonthRevBreakdown, setCurrMonthRevBreakdown] = useState([]);
	const [prevMonthRevBreakdown, setPrevMonthRevBreakdown] = useState([]);
	const [quarterlyRevenue, setQuarterlyRevenue] = useState([]);
	const [currYearsRevenue, setCurrYearsRevenue] = useState(0);
	const [prevYearsRevenue, setPrevYearsRevenue] = useState(0);
	const [prevYearRevenueByMonth, setPrevYearRevenueByMonth] = useState([]);
	const [prevYearRevenueByQtr, setPrevYearRevenueByQtr] = useState([]);
	const [revenuesByYear, setRevenuesByYear] = useState([]);
	const [anssidBreakdown, setAnssidBreakdown] = useState({});
	const [todaysAnssid, setTodaysAnssid] = useState(0);
	const [yesterdaysAnssid, setYesterdaysAnssid] = useState(0);
	const [todaysTaxpayersRevenue, setTodaysTaxpayersRevenue] = useState([]);
	const [yesterdaysTaxpayersRevenue, setYesterdaysTaxpayersRevenue] =
		useState([]);

	const [loading, setLoading] = useState(false);
	const [revenueHeads, setRevenueHeads] = useState([]);

	const individualRevenues = (todaysTaxpayersRevenue || []).find(
		(d) => d.AccountType === "individual"
	);
	const corporateRevenues = (todaysTaxpayersRevenue || []).find(
		(d) => d.AccountType === "corporate"
	);

	const getSpecialReports = async () => {
		setLoading(true);
		try {
			const result = await axios
				.get("/admin/reports/specialreports")
				.then((res) => (res.data || {}).data);

			setTodaysRevenue(result.todaysRevenue);
			setYesterdaysRevenue(result.yesterdaysRevenue);
			setCurrWeekRevenue(result.currWeek);
			setCurrWeekRevenueBreakdown(
				(() => {
					let weekData = {};
					result?.weekRevenueBrkDn?.forEach((each) => {
						if (each.dayz === "Sun") {
							return (weekData.SUN = parseFloat(each.Amount));
						} else if (each.dayz === "Mon") {
							return (weekData.MON = parseFloat(each.Amount));
						} else if (each.dayz === "Tue") {
							return (weekData.TUE = parseFloat(each.Amount));
						} else if (each.dayz === "Wed") {
							return (weekData.WED = parseFloat(each.Amount));
						} else if (each.dayz === "Thu") {
							return (weekData.THUR = parseFloat(each.Amount));
						} else if (each.dayz === "Fri") {
							return (weekData.FRI = parseFloat(each.Amount));
						} else if (each.dayz === "Sat") {
							return (weekData.SAT = parseFloat(each.Amount));
						}
						return null;
					});

					return dailyLabels.map(
						(day) => parseFloat(weekData[day]) || 0
					);
				})()
			);
			setPrevWeekRevenue(result.prevWeekRevenue);
			setCurrMonthsRevenue(result.currMonthsRevenue);
			setPrevMonthsRevenue(result.prevMonthsRevenue);
			setRevenuesByMonth(
				result.yearsRevenueMthBkDn.map((month) => month.Amount)
			);
			setCurrMonthRevBreakdown(result.MthRevenueHeadBrkDn);
			setPrevMonthRevBreakdown(result.PrevMthRevenueHeadBrkDn);
			setQuarterlyRevenue(() => {
				let qtrData = {};
				result.qtrReport.map((quarter) => {
					if (quarter.qtrNo === 1) {
						return (qtrData.Q1 = parseFloat(quarter.Amount));
					} else if (quarter.qtrNo === 2) {
						return (qtrData.Q2 = parseFloat(quarter.Amount));
					} else if (quarter.qtrNo === 3) {
						return (qtrData.Q3 = parseFloat(quarter.Amount));
					} else if (quarter.qtrNo === 4) {
						return (qtrData.Q4 = parseFloat(quarter.Amount));
					}
					return null;
				});

				return quarterlyLabels.map(
					(qtr) => parseFloat(qtrData[qtr]) || 0
				);
			});
			setCurrYearsRevenue(result.currYearsRevenue);
			setPrevYearsRevenue(result.prevYearsRevenue);
			setRevenuesByYear([
				result.currYearsRevenue,
				result.prevYearsRevenue,
				result.twoYearsAgoRevenue,
				result.threeYearsAgoRevenue
			]);
			setPrevYearRevenueByMonth(
				result.prevYearsRevenueMth.map((month) => month.Amount)
			);
			setPrevYearRevenueByQtr(
				result.prevYearsRevenueQtr.map((qtr) => qtr.Amount)
			);
			setAnssidBreakdown(() => {
				const obj = {
					individualCount: 0,
					corporateCount: 0,
					totalCount: 0
				};

				result.anssidNow.forEach((anssid) => {
					if (anssid.Account_type === "individual") {
						obj.totalCount += anssid.kount;
						return (obj.individualCount = anssid.kount);
					} else {
						obj.totalCount += anssid.kount;
						return (obj.corporateCount = anssid.kount);
					}
				});

				return obj;
			});
			setTodaysAnssid(result.todaysAnssid);
			setYesterdaysAnssid(result.yesterdaysAnssid);
			setTodaysTaxpayersRevenue(result.todaysTaxpayersRevenue);
			setYesterdaysTaxpayersRevenue(result.yesterdaysTaxpayersRevenue);
			setRevenueHeads(result.verticalCollectionsRevenue);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const getReport = (url) => {
		return axios.get(url).then((res) => (res.data || {}).data);
	};

	useEffect(() => {
		getSpecialReports();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const options = {
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					}
					// display: false // this will hide vertical lines
				}
			],
			yAxes: [
				{
					gridLines: {
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					display: false // this will hide vertical lines
				}
			]
		}
	};

	const data = (values, labels, header, colors) => {
		return {
			labels: labels,
			datasets: [
				{
					label: header,
					data: values,
					fill: true,
					backgroundColor: colors,
					// borderColor: "#6204DC"
					// color: '#0242C2',
					borderWidth: 1
				}
			]
		};
	};

	return (
		<section>
			<section className={styles.top__bar}>
				<div className={styles.top__card}>
					<img src={blueBill} alt="blue bill" />
					<div className={styles.numbers}>
						<p>Revenue Generated</p>
						<p>{formatAmount(todaysRevenue)}</p>
					</div>
					<p>
						Today{" "}
						<span
							style={{
								color:
									percentIncrease(
										yesterdaysRevenue,
										todaysRevenue
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(yesterdaysRevenue, todaysRevenue)}%
						</span>
					</p>
				</div>
				<div className={styles.top__card}>
					<img src={redBill} alt="red bill" />
					<div className={styles.numbers}>
						<p>Revenue Generated</p>
						<p>{formatAmount(currWeekRevenue)}</p>
					</div>
					<p>
						WTD{" "}
						<span
							style={{
								color:
									percentIncrease(
										prevWeekRevenue,
										currWeekRevenue
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(prevWeekRevenue, currWeekRevenue)}%
						</span>
					</p>
				</div>
				<div className={styles.top__card}>
					<img src={greenBill} alt="green bill" />
					<div className={styles.numbers}>
						<p>Revenue Generated</p>
						<p>{formatAmount(currMonthsRevenue)}</p>
					</div>
					<p>
						MTD{" "}
						<span
							style={{
								color:
									percentIncrease(
										prevMonthsRevenue,
										currMonthsRevenue
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(
								prevMonthsRevenue,
								currMonthsRevenue
							)}
							%
						</span>
					</p>
				</div>
				<div className={styles.top__card}>
					<img src={purpleBill} alt="purple bill" />
					<div className={styles.numbers}>
						<p>Revenue Generated</p>
						<p>{formatAmount(currYearsRevenue)}</p>
					</div>
					<p>
						YTD{" "}
						<span
							style={{
								color:
									percentIncrease(
										prevYearsRevenue,
										currYearsRevenue
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(
								prevYearsRevenue,
								currYearsRevenue
							)}
							%
						</span>
					</p>
				</div>
			</section>
			<section className={styles.breakdowns}>
				<div>
					<h3>Current Month Revenue Heads Collections</h3>
					<ul>
						{currMonthRevBreakdown?.map((item) => (
							<li>
								<span>{item.Description}</span>
								<span>&#8358;{formatAmount(item.Amount)}</span>
							</li>
						))}
					</ul>
				</div>
				<div>
					<h3>Previous Month Revenue Heads Collections</h3>
					<ul>
						{prevMonthRevBreakdown
							?.sort((a, b) => {
								if (
									parseFloat(a.Amount) > parseFloat(b.Amount)
								) {
									return -1;
								} else if (
									parseFloat(a.Amount) < parseFloat(b.Amount)
								) {
									return 1;
								} else {
									return 0;
								}
							})
							.map((item) => (
								<li key={item.Description}>
									<span>{item.Description}</span>
									<span>
										&#8358;{formatAmount(item.Amount)}
									</span>
								</li>
							))}
					</ul>
				</div>
			</section>
			<div className={styles.anssid__div}>
				<div className={styles.anssid__stat}>
					<img src={greenProfile} alt="green profile" />
					<div>
						<p>Total {coy_ANSSID} Created</p>
						<p>{todaysAnssid}</p>
					</div>
					<p>
						Today
						<span
							style={{
								color:
									percentIncrease(
										yesterdaysAnssid,
										todaysAnssid
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(yesterdaysAnssid, todaysAnssid)}%
						</span>
					</p>
				</div>
				<div className={styles.anssid__stat}>
					<img src={blueProfile} alt="blue profile" />
					<div>
						<p>Total {coy_ANSSID} Organisation</p>
						<p>
							{formatAmount(
								anssidBreakdown?.corporateCount,
								true
							)}
						</p>
					</div>
					<p>
						<span></span>
					</p>
				</div>
				<div className={styles.anssid__stat}>
					<img src={redProfile} alt="red profile" />
					<div>
						<p> Total {coy_ANSSID} Individuals</p>
						<p>
							{formatAmount(
								anssidBreakdown?.individualCount,
								true
							)}
						</p>
					</div>
					<p>
						<span></span>
					</p>
				</div>
			</div>

			<div
				className={chartStyles.stats}
				style={{ marginTop: "36px", marginBottom: "36px" }}
			>
				<div className={chartStyles.top__stats}>
					<div className={chartStyles.stat}>
						<p>Total Collections - Daily</p>
						<p>
							&#8358; {formatAmount(todaysRevenue) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											yesterdaysRevenue,
											todaysRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									yesterdaysRevenue,
									todaysRevenue
								)}
								%
							</span>
						</p>
						<div>
							<Line
								data={data(
									currWeekRevenueBreakdown,
									dailyLabels,
									"",
									["#6204DC"]
								)}
								options={options}
							/>
						</div>
					</div>
					<div className={chartStyles.stat}>
						<p>
							Total Collections - Monthly (
							{new Date().getFullYear()})
						</p>
						<p>
							&#8358; {formatAmount(currMonthsRevenue) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											prevMonthsRevenue,
											currMonthsRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									prevMonthsRevenue,
									currMonthsRevenue
								)}
								%
							</span>
						</p>
						<div>
							<Bar
								data={data(
									revenuesByMonth,
									monthlyLabels,
									"Total Collections - Monthly",
									["#3ABDA5", "#6204DC"]
								)}
								options={options}
							/>
						</div>
					</div>
				</div>
				<div className={chartStyles.top__stats}>
					<div className={chartStyles.stat}>
						<p>
							Total Collections - Monthly (
							{new Date().getFullYear() - 1})
						</p>
						<p style={{ visibility: "hidden" }}>
							{formatAmount(currMonthsRevenue) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											prevMonthsRevenue,
											currMonthsRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									prevMonthsRevenue,
									currMonthsRevenue
								)}
								%
							</span>
						</p>
						<div>
							<Bar
								data={data(
									prevYearRevenueByMonth,
									monthlyLabels,
									`Total Collections - Monthly (${
										new Date().getFullYear() - 1
									})`,
									["#3ABDA5", "#6204DC"]
								)}
								options={options}
							/>
						</div>
					</div>
				</div>
				<div className={chartStyles.top__stats}>
					<div className={chartStyles.stat}>
						<p>
							Total Collections - Quarterly (
							{new Date().getFullYear()})
						</p>
						<p>
							&#8358;{" "}
							{formatAmount(
								quarterlyRevenue[getCurrentQuarter() - 1]
							) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											quarterlyRevenue[
												getCurrentQuarter() - 2
											],
											quarterlyRevenue[
												getCurrentQuarter() - 1
											]
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									quarterlyRevenue[getCurrentQuarter() - 2],
									quarterlyRevenue[getCurrentQuarter() - 1]
								)}
								%
							</span>
						</p>
						<div className={styles.pie}>
							<Pie
								data={data(
									quarterlyRevenue,
									quarterlyLabels,
									"",
									["#3ABDA5", "#2F9986", "#267C6C", "#1F6458"]
								)}
								options={options}
							/>
						</div>
					</div>
					<div className={chartStyles.stat}>
						<p>
							Total Collections - Quarterly (
							{new Date().getFullYear() - 1})
						</p>
						<p style={{ visibility: "hidden" }}>
							{formatAmount(currYearsRevenue) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											prevYearsRevenue,
											currYearsRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									prevYearsRevenue,
									currYearsRevenue
								)}
								%
							</span>
						</p>
						<div className={styles.pie}>
							<Pie
								data={data(
									prevYearRevenueByQtr,
									quarterlyLabels,
									"",
									["#3ABDA5", "#2F9986", "#267C6C", "#1F6458"]
								)}
								options={options}
							/>
						</div>
					</div>
				</div>
				<div className={chartStyles.top__stats}>
					<div className={chartStyles.stat}>
						<p>Total Collections - Yearly</p>
						<p>
							&#8358; {formatAmount(currYearsRevenue) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											prevYearsRevenue,
											currYearsRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									prevYearsRevenue,
									currYearsRevenue
								)}
								%
							</span>
						</p>
						<div className={styles.pie}>
							<Pie
								data={data(
									revenuesByYear,
									yearlyLabels,
									"Total Collections - Yearly",
									["#7F2536", "#9D2D42", "#C23852", "#EF4565"]
								)}
								options={options}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Dashboard;
