import React, { useState } from "react";
import axios from "axios";
import styles from "../../Identity-management-individual/style.module.css";

const useStepOne = ({ setCurrentStep }) => {

  const [uid, setUID] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const sendOTP = async (e) => {
    e?.preventDefault();
    setLoading(true);

    const isEmail = /\S+@\S+/.test(uid);
    const type = isEmail ? 'email' : 'phone';

    try {
      const result = await axios.post(`/auth/otp?type=${type}`, {
        uid
      }).then(res => (res.data || {}).data);
  
      if (result.success) {
        setCurrentStep(2);
      }
    } catch (err) {
      console.error(err);
      setError("Could not send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const form = ({ footer }) => (
    <form className={styles.columned__form} onSubmit={sendOTP}>
      <label className={styles.input__label} htmlFor="uid">Email Address/ Phone Number *</label>
      <input
        type="text"
        onChange={(e) => setUID(e.target.value)}
        id="uid"
        placeholder="Email Address or Phone Number"
        required
        value={uid} />
      <hr className={styles.divider} />
      {footer}
    </form>
  );

  return {
    title: "Email / Phone Number",
    form,
    state: {
      uid,
      error,
      loading
    }
  }
};

export default useStepOne;
