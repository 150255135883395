import React, { useState } from "react";
import axios from "axios";
import * as faceapi from "face-api.js";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./style.module.css";
import Spinner from "../components/spinner";
// import Moment from 'react-moment';


function formatDate(date) {
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

const idDocMap = {
	voters_card: "Voter’s Card",
	national_id: "National ID Card",
	drivers_license: "Driver’s License",
	international_passport: "Int’l Passport"
};

const description = (
	<>
		Upload your preferred valid means of identification which must be:
		<br />
		<br />
		- Image Format – JPEG <br /> - Size of Image – Minimum 10 KB and Maximum
		300 KB <br />
		- The height and width of the Photo must be equal.
		<br /> - Dimensions in width and heights should minimum 350 by 350
		pixels and maximum 1000 by 1000 pixels. <br />
		<br />
		Click Continue to proceed OR Exit & Log out.
	</>
);

const useProofOfIdentity = ({
	setCurrentStep,
	anssid,
	userId,
	urlPrefix = ""
}) => {
	const [idInfo, setIdInfo] = React.useState("not_applicable");
	const [idDoc, setIdDoc] = React.useState("");
	const [licenseIssueDate, setLicenseIssueDate] = useState(new Date());
	const [licenseExpiryDate, setLicenseExpiryDate] = useState(new Date());
	const [votersCardCode, setVotersCardCode] = useState("");
	const [idNumber, setIDNumber] = useState("");
	const [submitted, setSubmitted] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState();
	const [imagePreview, setImagePreview] = React.useState();

	const fileInputRef = React.createRef();

	const getIdentityInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`${urlPrefix}/users/identity-proof?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				// console.log(Moment(info.licenseExpiryDate, 'YYYY-MM-DD').format('yyyy-MM-dd'))
				setIdInfo(info.identityType);
				setLicenseIssueDate(
					formatDate(info.licenseIssueDate) || formatDate(Date.now())
				);
				setLicenseExpiryDate(
					formatDate(info.licenseExpiryDate) || formatDate(Date.now())
				);
				setVotersCardCode(info.votersCardCode || "");
				setIDNumber(info.idNumber || "");
				setImagePreview(info.identityPhoto || "");
			}
		} catch (err) {
			console.error(err);
			// setError("Existing identity proof information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		getIdentityInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitUserProofOfIdentity = async (e) => {
		e.preventDefault();
		setError("");
		if (anssid) {
			setSubmitted(true);
			setCurrentStep(6);
			return;
		}

		setLoading(true);

		try {
			const accessToken = localStorage.getItem("access_token");
			const formData = new FormData();
			if (idInfo) {
				formData.append("identityType", idInfo);
			}
			if (idNumber) {
				formData.append("idNumber", idNumber);
			}
			if (idDoc) {
				formData.append("file", idDoc);
			}
			if (votersCardCode) {
				formData.append("votersCardCode", votersCardCode);
				if (!idNumber) {
					formData.append("idNumber", votersCardCode);
				}
			}
			if (licenseIssueDate) {
				formData.append(
					"licenseIssueDate",
					new Date(licenseIssueDate).getTime()
				);
			}
			if (licenseExpiryDate) {
				formData.append(
					"licenseExpiryDate",
					new Date(licenseExpiryDate).getTime()
				);
			}

			formData.append("confirmPhoneNumber", true);
			formData.append("confirmEmailAddress", true);

			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			// console.log('formdata  >>>>   ', formData)
			const res = await axios
				.post(`${urlPrefix}/users/identity-proof?${query}`, formData, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data);

			console.log(res);

			setSubmitted(true);
			setCurrentStep(6);
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}

			setError(
				message ||
				"Identity proof documents could not be submitted. Please verify your details and try again."
			);
		} finally {
			setLoading(false);
		}
	};

	const form = ({ footer, idInfo, idDoc, loading }) => (
		<form onSubmit={submitUserProofOfIdentity}>
			<p className={styles.regular__text}>
				Proof of Identity <span style={{ color: "red" }}>*</span>
			</p>
			<ul className="flat__list">
				<li>
					<input
						type="radio"
						onChange={(e) => setIdInfo(e.target.value)}
						id="votersCard"
						name="idInfo"
						value="voters_card"
						checked={idInfo === "voters_card"}
					/>
					<label className={styles.radio__label} htmlFor="votersCard">
						Voter’s Card
					</label>
				</li>
				<li>
					<input
						type="radio"
						onChange={(e) => setIdInfo(e.target.value)}
						id="national_idCard"
						name="idInfo"
						value="national_id"
						checked={idInfo === "national_id"}
					/>
					<label
						className={styles.radio__label}
						htmlFor="national_idCard"
					>
						National ID Card
					</label>
				</li>
				<li>
					<input
						type="radio"
						onChange={(e) => setIdInfo(e.target.value)}
						id="drivers_license"
						name="idInfo"
						value="drivers_license"
						checked={idInfo === "drivers_license"}
					/>
					<label
						className={styles.radio__label}
						htmlFor="drivers_license"
					>
						Driver’s License
					</label>
				</li>
				<li>
					<input
						type="radio"
						onChange={(e) => setIdInfo(e.target.value)}
						id="international_passport"
						name="idInfo"
						value="international_passport"
						checked={idInfo === "international_passport"}
					/>
					<label
						className={styles.radio__label}
						htmlFor="international_passport"
					>
						Int’l Passport
					</label>
				</li>
				<li>
					<input
						type="radio"
						onChange={(e) => setIdInfo(e.target.value)}
						id="not_applicable"
						name="idInfo"
						value="not_applicable"
						checked={idInfo === "not_applicable"}
					/>
					<label
						className={styles.radio__label}
						htmlFor="not_applicable"
					>
						Not Applicable
					</label>
				</li>
			</ul>
			{idInfo !== "not_applicable" && (
				<>
					<hr className={styles.divider} />
					{idInfo === "voters_card" && (
						<div className={styles.div__wrapper}>
							<div>
								<label htmlFor="votersCardCode">
									Voters Card Code <span>*</span>
								</label>
								<input
									type="number"
									id="votersCardCode"
									className={styles.input__medium}
									name="votersCardCode"
									onChange={(e) =>
										setVotersCardCode(e.target.value)
									}
									value={votersCardCode}
									placeholder="eg 9030012345"
									required
									min={0}
								/>
							</div>
						</div>
					)}
					{idInfo !== "voters_card" && (
						<div
							className={
								idInfo !== "voters_card"
									? styles.div__wrapper
									: styles.div__wrapper__input__short
							}
						>
							<div>
								<label htmlFor="IDNumber">
									ID Number <span>*</span>
								</label>
								<input
									type="text"
									id="IDNumber"
									className={styles.input__medium}
									name="IDNumber"
									onChange={(e) =>
										setIDNumber(e.target.value)
									}
									value={idNumber}
									placeholder="eg abc123"
									required
								/>
							</div>
							<div>
								<label
									htmlFor="licenseIssueDate"
									className={styles.input__label}
								>
									Issue Date
								</label>
								<input
									type="date"
									id="licenseIssueDate"
									name="licenseIssueDate"
									className={styles.input__short}
									onChange={(e) =>
										setLicenseIssueDate(e.target.value)
									}
									value={licenseIssueDate}
								/>
							</div>
							<div>
								<label
									htmlFor="companyEmail"
									className={styles.input__label}
								>
									Expiry Date
								</label>
								<input
									type="date"
									id="licenseExpiryDate"
									name="licenseExpiryDate"
									className={styles.input__short}
									onChange={(e) =>
										setLicenseExpiryDate(e.target.value)
									}
									value={licenseExpiryDate}
								/>
							</div>
						</div>
					)}
					<input
						hidden
						ref={fileInputRef}
						onChange={async (e) => {
							setLoading(true);
							setError("");
							try {
								const size = e.target.files?.[0]?.size;
								if (size < 10240 || size > 307200) {
									setError(
										`Uploaded file size must be between 10kb and 300kb. Current uploaded is ${size / 1024
										}kb`
									);
									return;
								}
								const { src: image } =
									await faceapi.bufferToImage(
										e.target.files[0]
									);
								const img = new Image();
								img.onload = function () {
									if (
										this.width < 350 ||
										this.height < 350 ||
										this.width > 1000 ||
										this.height > 1000
									) {
										setError(
											`Please upload an image that is according to the specified dimensions. Current uploaded is ${this.width}x${this.height}px`
										);
										return;
									}
									setIdDoc(e.target.files[0]);
									setImagePreview(image);
								};
								img.src = image;
							} catch (err) {
								console.error(err);
							} finally {
								setLoading(false);
							}
						}}
						type="file"
						name="idDoc"
						accept="image/*"
					/>
					<p className={styles.file__name}>{idDoc.name}</p>
					<button
						type="button"
						className={styles.file__input__button}
						onClick={() => {
							fileInputRef.current.click();
						}}
					>
						{imagePreview && (
							<img
								src={imagePreview}
								className={styles.image__preview}
								alt="Uploaded document"
							/>
						)}
						{loading ? (
							<Spinner large />
						) : (
							<span className={styles.file__input__button__inner}>
								<span className={styles.camera__icon}></span>
								Upload {idDocMap[idInfo]} image
							</span>
						)}
					</button>
				</>
			)}
			{footer}
		</form>
	);

	return {
		title: "Proof of Identity",
		description,
		form,
		state: {
			idInfo,
			idDoc,
			submitted,
			loading,
			error,
			disabled: !!anssid
		},
		submit: submitUserProofOfIdentity
	};
};

export default useProofOfIdentity;
