/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowRight from "../../assets/arrowRight.svg";
import styles from "../../Identity-management-individual/style.module.css";
import style1 from "./style.module.css";

function formatDate(date) {
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

const PersonalData = ({ next, userId, query }) => {
	const { state } = useLocation();
	const [title, setTitle] = useState("");
	const [firstname, setFirstName] = useState("");
	const [middlename, setMiddleName] = useState("");
	const [surname, setSurname] = useState("");
	const [mothermaidianname, setMaidenName] = useState("");
	const [sex, setGender] = useState("");
	const past = new Date();
	past.setFullYear(new Date().getFullYear() - 18);
	const [dateofbirth, setDateOfBirth] = useState(formatDate(past));
	const [phonenumber, setPhoneNumber] = useState("");
	const [email, setEmailAddress] = useState("");

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const getPersonalInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}
			const info = await axios
				.get(`/users?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setTitle(info.title || "");
				setFirstName(info.firstname || firstname);
				setMiddleName(info.middlename || middlename);
				setSurname(info.surname || surname);
				setMaidenName(info.mothermaidianname || "");
				setGender((info.sex || "").toLowerCase());
				setDateOfBirth(formatDate(new Date(info.dateofbirth || past)));
				setPhoneNumber(info.phonenumber || phonenumber);
				setEmailAddress(info.email || email);
			}
		} catch (err) {
			setError("Existing personal information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPersonalInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={style1.title__div}>
				<h1>Personal Data</h1>
				<Link
					to={{
						pathname: next,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					Next <img src={ArrowRight} alt="right arrow" />
				</Link>
			</div>
			<form className={styles.columned__form}>
				<label className={styles.input__label} htmlFor="title">
					Title
				</label>
				<input type="text" id="title" required disabled value={title} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="firstName"
						>
							First name
						</label>
						<input
							type="text"
							id="firstName"
							required
							disabled
							value={firstname}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="middleName"
						>
							Middle name
						</label>
						<input
							type="text"
							id="middleName"
							value={middlename}
							disabled
						/>
					</div>
				</div>
				<label className={styles.input__label} htmlFor="surname">
					Surname
				</label>
				<input type="text" id="surname" disabled value={surname} />
				<hr className={styles.divider} />
				<label className={styles.input__label} htmlFor="maidenName">
					Mother’s Maiden name
				</label>
				<input
					type="text"
					id="maidenName"
					value={mothermaidianname}
					disabled
				/>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="gender">
							Gender
						</label>
						<select
							id="gender"
							className={styles.select__input}
							value={sex}
							disabled
						>
							<option value="">--Select Gender--</option>
							<option value="female">Female</option>
							<option value="male">Male</option>
							<option value="others">Other</option>
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="dateOfBirth"
						>
							Date of Birth
						</label>
						<input
							type="date"
							id="dateOfBirth"
							name="dateOfBirth"
							max={formatDate(past)}
							value={dateofbirth}
							disabled
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="phoneNumber"
						>
							Phone no
						</label>
						<input
							type="text"
							id="phoneNumber"
							disabled
							value={phonenumber}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="emailAddress"
						>
							Email Address
						</label>
						<input
							type="text"
							id="emailAddress"
							required
							disabled
							value={email}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default PersonalData;
