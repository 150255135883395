import { useState, createRef } from "react";
import xlsx from "xlsx";
// import { useHistory } from "react-router-dom";
import PreviewTable from "./previewTable";
import uploadIcon from "../../assets/upload.svg";

import axios from "axios";
import styles from "../style.module.css";
const coy_ANSSID = process.env.REACT_APP_ANSSID

const useUploadStaffRecords = ({ userId, urlPrefix = '' }) => {
	const [record, setRecord] = useState(null);
	const [parsedRecord, setParsedRecord] = useState(null);
	const fileInputRef = createRef();
	// const history = useHistory();

	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const previewFile = (record) => {
		const reader = new FileReader();

		reader.addEventListener(
			"load",
			async () => {
				const parsed = await Promise.resolve(
					xlsx.read(reader.result, {
						type: "buffer"
					})
				);
				const sheetName = parsed?.SheetNames?.[0];
				if (!sheetName) return;

				const result = [];

				const sheet = parsed.Sheets[sheetName];
				const sheetKeys = Object.keys(sheet);
				for (let i = 0; (i < sheetKeys.length && result.length <= 5); i++) {
					const [, col] = sheetKeys[i].split(/^[a-zA-Z]+/);
					const [row] = sheetKeys[i].split(/[0-9]+$/)
					if (!/^[a-zA-Z]/i.test(sheetKeys[i]) || Number(col) === 1) continue;
					
					if (!result[col - 2]) {
						result[col - 2] = {};
					}
					
					const header = sheet[`${row}1`].v;
					result[col - 2][header] = sheet[sheetKeys[i]].v;
				}
				result.pop();
				setParsedRecord(result);
			},
			false
		);

		if (record) reader.readAsArrayBuffer(record);
	};

	const headers = {
		FIRST_NAME: "First Name",
		LAST_NAME: "Surname",
		EMAIL: "Email Address",
		PHONE_NUMBER: "Phone Number",
		ANSSID: `${coy_ANSSID} Number`
	};

	const handleChange = (e) => {
		setRecord(e.target.files[0]);
		previewFile(e.target.files[0]);
	};

	const handleSubmit = async (e) => {
		e?.preventDefault();
		setLoading(true);
		setError("");

		try {
			const accessToken = localStorage.getItem("access_token");
			const formData = new FormData();
			formData.append("file", record);

			let query = '';
      if (userId) {
        query += `userId=${userId}`;
      }

			const res = await axios
				.post(
					`${urlPrefix}/corporate/company/staff/upload/bulk?${query}`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);

			console.log(res);

			setSubmitted(true);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			let message = "";
      if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}

			setError(message || "An error occurred while trying to upload the staff records. Please confirm the format of the file being uploaded and try again.");
		}
	};

	const form = ({ footer }) => (
		<form
			className={styles.form}
			onSubmit={handleSubmit}
			key={Math.random() * 0.5}
		>
			<p>
				Upload a Spreadsheet with the columns - FIRST_NAME, LAST_NAME,
				EMAIL, PHONE_NUMBER, {coy_ANSSID}
			</p>
			<div className={styles.upload__div}>
				<input
					id="record"
					hidden
					type="file"
					ref={fileInputRef}
					name="record"
					onChange={(e) => handleChange(e)}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				/>
				<button
					aria-label="upload"
					type="button"
					onClick={() => fileInputRef.current.click()}
				>
					<img src={uploadIcon} alt="upload icon" />
				</button>
				<label htmlFor="record" className={styles.upload__label}>
					Upload Staff Record
				</label>
				{record && <p className={styles.file__name}>{record.name}</p>}
			</div>
			{parsedRecord && (
				<PreviewTable headers={headers} data={parsedRecord} />
			)}
			{footer}
		</form>
	);

	return {
		title: "Upload Staff Records",
		description:
			`You can fill these forms to input your company’s individual staff records or provide a file to upload your Staff Records. Then Click Finish to finally generate your ${coy_ANSSID} number OR Exit & Log out.`,
		form,
		state: {
			sendingStatus: loading,
			record,
			submitted,
			loading,
			error
		},
		submit: handleSubmit
	};
};

export default useUploadStaffRecords;
