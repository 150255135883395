import React, { useState } from "react";
import styles from "./style.module.css";
import altStyles from "../Identity-management-individual/style.module.css";
import axios from "axios";
const coy_ANSSID = process.env.REACT_APP_ANSSID

const useStaffRecords = ({ anssid, userId, urlPrefix = '' }) => {
	const [values, setValue] = useState([{
		anssid: "",
		firstName: "",
		surname: "",
		email: "",
		phoneNumber: ""
	}]);
	// console.log('locationState  [pg3]  >>>  ', locationState)
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [error, setError] = useState();
	const [employeesToUpload, setEmployeesToUpload] = useState([]);
	// const employeesToUpload = Array.isArray(values.ownerAnssid) ? values.ownerAnssid : [values.ownerAnssid];

	const getCompanyStaff = async () => {
		setLoading(true);
		try {
			let query = '';
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`${urlPrefix}/corporate/company/staff?${query}`)
				.then((res) => (res.data || {}).data);

			if (info) {
				setEmployeesToUpload(info);
			}
		} catch (err) {
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		getCompanyStaff();
		// console.log('LocationState [ pg3 ]  >>> ', locationState)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleChange(index, e) {
		const name = e.target.name || e.target.id;
		const value = e.target.type === "file" ? e.target.file : e.target.value;
		var currentEmployee = employeesToUpload[index] || {};
		currentEmployee = { ...currentEmployee, [name]: value };
		employeesToUpload[index] = currentEmployee;
		setValue([...employeesToUpload]);
	}

	const handleSubmit = async (e) => {
		if (e) {
			e.preventDefault();
		}
		setLoading(true);
		setError("");

		try {
			let query = '';
			if (userId) {
				query += `userId=${userId}`;
			}

			console.log('employeesToUpload  >>>>  ', employeesToUpload)
			console.log('Array.isArray(employeesToUpload)  >>>>  ', Array.isArray(employeesToUpload))
			console.log(' employeesToUpload.length >>>>  ', employeesToUpload.length)

			var bn = []
			for (let i = 0; i < employeesToUpload.length; i++) {
				console.log('employeesToUpload [loop]  >>>>  ', employeesToUpload[i])
				bn.push(employeesToUpload[i])
			};
			console.log('bn  >>>  ', bn)

			// await axios.post(`${urlPrefix}/korporate/company/staff?${query}`, JSON.stringify(bn))
			await axios.post(`${urlPrefix}/korporate/company/staff?${query}`, bn)
				// { ...values, name: '' })
				// `[ ${...values, name: ''} ]`
				.then(res => res.data);

			setSubmitted(true);
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}

			setError(message || `Staff ${coy_ANSSID} form could not be submitted. Please verify your details and try again.`);
		} finally {
			setLoading(false);
		}
	};

	const skip = () => {
		setLoading(true);
		setSubmitted(true);
		setLoading(false);
	};

	const form = ({ footer, numberOfStaff = 0 }) => {
		const addStaffForm = () => {
			if (numberOfStaff > employeesToUpload.length) {
				setEmployeesToUpload([...employeesToUpload, {}]);
			} else {
				setError(`you can't upload more than ${numberOfStaff || 0} employees as provided in step one`);
			}
		};

		const deleteEmployee = (index) => {
			employeesToUpload.splice(index, 1);
			setEmployeesToUpload([...employeesToUpload]);
		};

		const generateEmployeeForm = () => {
			return employeesToUpload.map((x, i) => (
				<React.Fragment key={"staff_upload_" + i}>
					<div className={styles.div__wrapper}>
						<div>
							<label htmlFor="surname">
								Staff's Last Name
							</label>
							<input
								type="text"
								name="surname"
								id="surname"
								className={styles.input__medium}
								onChange={(e) => handleChange(i, e)}
								value={x.surname}
								placeholder="eg John"
							/>
						</div>
						<div>
							<label htmlFor="firstName">
								Staff's First Name
							</label>
							<input
								type="text"
								name="firstName"
								id="firstName"
								className={styles.input__medium}
								onChange={(e) => handleChange(i, e)}
								value={x.firstName}
								placeholder="eg John"
							/>
						</div>
						<div>
							<label htmlFor="anssid">
								Staff's {coy_ANSSID}
							</label>
							<input
								type="number"
								name="anssid"
								id="anssid"
								className={styles.input__medium}
								onChange={(e) => handleChange(i, e)}
								value={x.anssid}
								placeholder="eg 8379302918"
								min={0}
							/>
						</div>
						<div>
							<label htmlFor="email">
								Staff's Email Address
							</label>
							<input
								type="email"
								name="email"
								id="email"
								className={styles.input__medium}
								onChange={(e) => handleChange(i, e)}
								value={x.email}
								placeholder="eg email@staff.com"
							/>
						</div>
						<div>
							<label htmlFor="phoneNumber">
								Staff's Phone Number
							</label>
							<input
								type="text"
								name="phoneNumber"
								id="phoneNumber"
								className={styles.input__medium}
								onChange={(e) => handleChange(i, e)}
								value={x.phoneNumber}
								placeholder="eg +2349012345678"
							/>
						</div>
						{
							!anssid && (
								<div style={{ display: 'flex' }}>
									<button
										type="button"
										className={altStyles.add__another__child__btn}
										style={{ color: '#F0372E', textAlign: 'left' }}
										onClick={(e) => deleteEmployee(i)}
									>
										- Remove Staff {i + 1}
									</button>
								</div>
							)
						}
					</div>
				</React.Fragment>
			));
		};

		return (
			<form className={styles.form} onSubmit={handleSubmit}>
				{generateEmployeeForm()}
				{
					anssid ? footer : (
						employeesToUpload.length ?
							<>
								<div style={{ position: 'relative' }}>
									<button
										type="button"
										className={altStyles.add__another__child__btn}
										onClick={addStaffForm}
									>
										+ Add Staff
									</button>
								</div>
								{footer}
							</>
							:
							<div style={{ display: 'flex', gap: 20, justifyContent: 'flex-end' }}>
								{
									numberOfStaff > 0 && (
										<button type="button" className={styles.primary__button} onClick={addStaffForm}>Add</button>
									)
								}
								<button type="button" className={styles.accent__button} onClick={skip}>Skip</button>
							</div>
					)
				}
			</form>
		);
	};

	return {
		title: "Staff Records",
		description:
			`You can fill these forms to input your company’s individual staff records or provide a file to upload your Staff Records. Then Click Finish to finally generate your ${coy_ANSSID} number OR Exit & Log out.`,
		form,
		state: {
			values,
			submitted,
			loading,
			error,
		},
		submit: handleSubmit
	};
};

export default useStaffRecords;