import React, { useState, createRef } from "react";
import axios from "axios";
import * as faceapi from "face-api.js";
// import { Camera } from "./camera";
import identityStyles from "../Identity-management-individual/style.module.css";

import styles from "./styles.module.css";
import Spinner from "../components/spinner";
const coy_ANSSID = process.env.REACT_APP_ANSSID

function isFaceDetectionModelLoaded() {
	// return faceapi.nets.ssdMobilenetv1.params;
}

// var currentButtonState = false;
// console.log('locationstate [biometric]', locationState.ssActive)


const minConfidence = 0.7;

const description = (
	<>
		Upload a picture of your face or take a Live shot of your face with your
		webcam which must be:
		<br /> - Image Format – JPEG. <br />- Size of Image – Minimum 10 KB and
		Maximum 300 KB
		<br /> -The height and width of the Photo must be equal.
		<br />
		-Dimensions in width and heights should minimum 350 by 350 pixels and
		maximum 1000 by 1000 pixels.
		<br />
		<br />
		Click Continue to proceed OR Exit & Log out.
		<br />
		Click Finish to finally generate your {coy_ANSSID} number OR Exit & Log out.
	</>
);

var videoEle = createRef();
var canvasEle = createRef();
// var imageEle = createRef();
var cameraContainer = createRef();
// var videoRef = createRef();
// var canvasRef = createRef();

// const divStyle = "main-video-capture-container-hide";
// const changeColor = () => {
// 	this.setState({ black: !this.state.black });
// };

function useBiometrics({ anssid, userId, urlPrefix = "", edit }) {
	const [isCameraOpen, setIsCameraOpen] = useState(false);
	const [cardImage, setCardImage] = useState();
	// const [detectionResult] = useState([]);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [initialImage, setInitialImage] = useState();
	const fileInputRef = createRef();
	var [style, setStyle] = useState("main-video-capture-container-hide");
	// var [styleCanvasCam, canvasImageStyle] = useState("");
	// let videoStream;
	// var [setStyleCamera, setStyleCameraBox] = useState("");

	var [, setCamerastreamBox] = useState();

	const getIdentityInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`${urlPrefix}/users/identity-proof?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setCardImage(info.biometricPhoto);
				setInitialImage(info.biometricPhoto);
				setIsCameraOpen(true);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const showCameraContainer = () => {
		setStyle("main-video-capture-container-show");
		getUserMedia();
	};

	const hideCameraContainer = () => {
		setStyle("main-video-capture-container-hide");
		clearCanvasCamera();
		//stopGetUserMedia();
		//setIsCameraOpen(false);
		// stopGetUserMedia();
	};

	const clearCanvasCamera = () => {
		var canvasClear = document.getElementById("canvas1");
		var contextClear = canvasClear.getContext("2d");
		contextClear.clearRect(0, 0, canvasClear.width, canvasClear.height);
	};

	const getUserMedia = async () => {
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: true
			});
			// videoStream = mediaStream;
			videoEle.current.srcObject = mediaStream;
			window.localStream = mediaStream;
			setCamerastreamBox(mediaStream);
		} catch (err) {
			console.log(err);
		}
	};

	// const stopGetUserMedia = async () => {
	// 	//videoStream.getTracks()[0].stop();
	// 	// [setCamerastream, setCamerastreamBox]

	// 	// var cameraStream = setCamerastream;
	// 	//video.src = window.URL.createObjectURL(stream);
	// 	// var videoStop = document.getElementById("video");
	// 	// videoStop.pause();
	// 	// setCamerastream.stop();

	// 	console.log("camera switching off...");

	// 	// const video = document.querySelector('video');

	// 	// // A video's MediaStream object is available through its srcObject attribute
	// 	// const mediaStream = video.srcObject;

	// 	// // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
	// 	// const tracks = mediaStream.getTracks();

	// 	// // Tracks are returned as an array, so if you know you only have one, you can stop it with:
	// 	// tracks[0].stop();

	// 	// // Or stop all like so:
	// 	// tracks.forEach(track => track.stop())

	// 	var video1 = document.querySelector("video");

	// 	window.localStream.getVideoTracks()[0].stop();
	// 	video1.src = "";

	// 	// A video's MediaStream object is available through its srcObject attribute
	// 	// const mediaStream = video.srcObject;

	// 	// // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
	// 	// const tracks = mediaStream.getTracks();

	// 	// // Tracks are returned as an array, so if you know you only have one, you can stop it with:
	// 	// tracks[0].stop();

	// 	// // Or stop all like so:
	// 	// tracks.forEach(track => track.stop())

	// 	console.log("Camera Reach Here...");
	// };

	const captureImage = () => {
		try {
			// get the context object of hidden canvas
			const ctx = canvasEle.current.getContext("2d");

			var detect_mobile = window
				.getComputedStyle(
					document.getElementById("detect_mobile"),
					null
				)
				.getPropertyValue("display");
			if (detect_mobile !== "none") {
				//alert("Mobile Display 1 : " + detect_mobile);
				ctx.drawImage(
					videoEle.current,
					80,
					110, //X And Y Position Of The Captured Area
					330,
					430, //Width And Height Of The Captured Area
					0,
					0, //X And Y Position Of The Captured Area To Be Displayed On The Canvas
					300,
					400 //Width And Height Of The Captured Area To Be Displayed On The Canvas
				);
			} else {
				ctx.drawImage(
					videoEle.current,
					180,
					110, //X And Y Position Of The Captured Area
					330,
					430, //Width And Height Of The Captured Area
					0,
					0, //X And Y Position Of The Captured Area To Be Displayed On The Canvas
					300,
					400 //Width And Height Of The Captured Area To Be Displayed On The Canvas
				);
			}

			// Get an image dataURL from the canvas.
			const imageDataURL = canvasEle.current.toDataURL("image/png");
			setCardImage(imageDataURL);
			setIsCameraOpen(true);

			if (imageDataURL !== null && imageDataURL !== "") {
				//removeAttribute
				document
					.getElementById("buttonIdentityManagement")
					.removeAttribute("disabled");
				// document.getElementById("buttonIdentityManagement").setAttribute("disabled","false");
				console.log("Disabled Removed");
			}

			// setStyleCameraBox("'width: 300px; height: 400px;'");
		} catch (error) {
			console.log("Error Message: " + error);
		}
	};

	React.useEffect(() => {
		faceapi.nets.ssdMobilenetv1.load("/");
		getIdentityInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (cardImage && cardImage !== initialImage) {
			updateResults();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardImage]);

	const uploadPassport = async (e) => {
		e.preventDefault();

		// if (!detectionResult.length) return;

		// if (anssid) {
		//   setSubmitted(true);
		//   return;
		// }

		// if (!isValidPassport(detectionResult)) {
		//   setError("Upload a valid passport photograph");
		//   return;
		// }

		if (cardImage == null || cardImage === "") {
			setError("Upload a valid passport photograph");
			return;
		}

		setLoading(true);
		setError(undefined);

		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const accessToken = localStorage.getItem("access_token");
			await axios
				.post(
					`${urlPrefix}/users/passport?${query}`,
					{
						passport: cardImage
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);

			setSubmitted(true);
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}

			setError(
				message || "Passport could not be updated. Please try again."
			);
		} finally {
			setLoading(false);
		}
	};

	// const isValidPassport = (detectionResult) =>
	// 	detectionResult.length === 1 &&
	// 	detectionResult[0].score > minConfidence;

	async function updateResults() {
		setError(undefined);
		setLoading(true);

		try {
			if (!isFaceDetectionModelLoaded()) {
				await faceapi.nets.ssdMobilenetv1.load("/");
			}

			const inputImgEl = document.getElementById("inputImg");
			if (!inputImgEl || !inputImgEl.getAttribute("src")) return;

			const results = await faceapi.detectAllFaces(
				inputImgEl,
				new faceapi.SsdMobilenetv1Options({ minConfidence })
			);
			// setDetectionResult(results);

			// if (!isValidPassport(results)) {
			//   setError("Upload a valid passport photograph");
			// }

			const canvas = document.getElementById("overlay");
			if (!canvas) return;

			faceapi.matchDimensions(canvas, inputImgEl);
			faceapi.draw.drawDetections(
				canvas,
				faceapi.resizeResults(results, inputImgEl)
			);
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	}

	const form = ({ footer, isCameraOpen, loading }) => (
		<form onSubmit={uploadPassport}>
			<main>
				<div
					className={style}
					ref={cameraContainer}
					id="main_video_capture_container"
				>
					<div
						className="jumbotron"
						id="main_video_capture_container_sub"
					>
						<p>
							<span id="errorMsg" />
						</p>
						<div className="row">
							<div className="col-lg-12" id="container_continue">
								<button
									type="button"
									className="btn btn-danger"
									style={{ float: "right" }}
									onClick={hideCameraContainer}
									id="closeAndContinue1"
								>
									Continue
								</button>
							</div>
							<div className="col-lg-12">
								<span>
									<b>Note: </b>
									<span>
										Please note that profiles with wrong
										passport images will be suspended.
									</span>
								</span>
							</div>
							<div className="col-lg-6">
								<div
									className="col-lg-12"
									style={{ padding: "0px" }}
								>
									<h4 className="text-center">
										Video Coming From Webcam{" "}
										<span id="detect_mobile"></span>
									</h4>
								</div>
								<div
									className="col-lg-12"
									style={{ padding: "0px" }}
								>
									<button
										type="button"
										className="btn btn-primary btnCapture"
										id="btnCapture"
										onClick={captureImage}
										style={{ fontWeight: "bold" }}
									>
										CAPTURE PASSPORT
									</button>
									<h1
										id="detectingFace"
										className="detecting_Face"
									>
										Detecting Face [
										<span id="detecting_Face_Counter">
											0
										</span>
										]<br />
										Please Position And Steady Your Face
										Within the <br />
										<b>Red Dotted Box</b> To Capture.
									</h1>
								</div>
								<div
									className="col-lg-12"
									style={{
										padding: "0px",
										marginTop: "10px"
									}}
								>
									<div className="video-container">
										<div className="video-snap-top-container" />
										<div className="video-snap-left-container" />
										<div className="video-snap-right-container" />
										<div className="video-snap-bottom-container" />
										<div className="video-snap-position" />
										<video
											ref={videoEle}
											id="video"
											playsInline
											autoPlay
											muted
											style={{
												width: "100%",
												height: "100%"
											}}
										/>
										<canvas
											id="canvasFaceDetector"
											className="canvas-play"
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<h4 className="text-center">
									Captured Image From Webcam
								</h4>
								<div>
									<center>
										<button
											type="button"
											id="clear_captured_passport"
											className="btn btn-primary btnClear"
											onClick={clearCanvasCamera}
										>
											CLEAR CAPTURED PASSPORT
										</button>
										{/* <button className="btn btn-success" id="btnSave" name="btnSave" style={{ marginLeft: '20px' }}>Post To Server</button> */}
									</center>
								</div>
								<div id="canvas_container">
									<br />
									<center>
										<canvas
											ref={canvasEle}
											style={{
												border: "solid 1px #ddd",
												backgroundColor: "white",
												boxShadow:
													"0px 0px 7px 4px #ccc"
											}}
											id="canvas1"
											width={300}
											height={343}
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.top__bar}>
					<input
						hidden
						ref={fileInputRef}
						onChange={async (e) => {
							setLoading(true);
							setError("");
							try {
								const size = e.target.files?.[0]?.size;
								if (size < 10240 || size > 307200) {
									setError(
										`Uploaded file size must be between 10kb and 300kb. Current uploaded is ${size / 1024
										}kb`
									);
									return;
								}
								const { src: image } =
									await faceapi.bufferToImage(
										e.target.files[0]
									);
								const img = new Image();
								img.onload = function () {
									if (
										this.width < 350 ||
										this.height < 350 ||
										this.width > 1000 ||
										this.height > 1000
									) {
										setError(
											`Please upload an image that is according to the specified dimensions. Current uploaded is ${this.width}x${this.height}px`
										);
										return;
									}
									setIsCameraOpen(true);
									setCardImage(image);
								};
								img.src = image;
							} catch (err) {
								console.error(err);
							} finally {
								setLoading(false);
							}
						}}
						type="file"
						name="idDoc"
						accept="image/*"
						style={{ display: "none" }}
					/>
					<button
						type="button"
						className={styles.options__button}
						onClick={() => {
							fileInputRef.current.click();
						}}
						style={{ display: "none" }}
					>
						<span className={styles.upload__icon}></span>
						Upload Picture
					</button>
					{
						// isCameraOpen && cardImage && (
						//   <button  type="button"
						//     className={styles.options__button}
						//     onClick={() => {
						//       //setIsCameraOpen(true);
						//       showCameraContainer();
						//       setCardImage(null);
						//     }}>
						//     {
						//       loading ? (<Spinner large />) : (
						//         <>
						//           <span className={styles.snap__icon}></span>
						//           Take Another Picture
						//         </>
						//       )
						//     }
						//   </button>
						// )
					}

					{/* {
              //Added this one here
              !isCameraOpen || cardImage == null && (
                <button type="button"
                  className={styles.options__button}
                  onClick={() => {
                    //setIsCameraOpen(true);
                    showCameraContainer();
                    setCardImage(null);
                  }}>
                  {
                    loading ? (<Spinner large />) : (
                      <>
                        <span className={styles.snap__icon}></span>
                        Take Another Picture
                      </>
                    )
                  }
                </button>
              )
            } */}

					{
						//Added this one here
						isCameraOpen && (
							<button
								type="button"
								className={styles.options__button}
								onClick={() => {
									//setIsCameraOpen(true);
									showCameraContainer();
									setCardImage(null);
								}}
							>
								{loading ? (
									<Spinner large />
								) : (
									<>
										<span
											className={styles.snap__icon}
										></span>
										Take Another Picture
									</>
								)}
							</button>
						)
					}
				</div>

				{isCameraOpen && (
					<div
						className={styles.camera__box}
						style={{ width: "300px", height: "400px" }}
					>
						{
							// !cardImage ? (
							//   <Camera onCapture={dataURL => setCardImage(dataURL)} onClear={() => setCardImage(undefined)} />
							// ) : (
							<div
								style={{
									position: "relative",
									width: "100%",
									height: "100%",
									borderRadius: "8px"
								}}
							>
								<img
									className={styles.preview}
									id="inputImg"
									src={cardImage}
									alt="passport"
								/>
								<canvas
									style={{
										position: "absolute",
										top: 0,
										left: 0,
										display: "inline-block",
										verticalAlign: "baseline"
									}}
									className={styles.mobile__canvas}
									id="overlay"
								/>
							</div>
							// )
						}
					</div>
				)}
				{!isCameraOpen && (
					<button
						type="button"
						className={[
							identityStyles.file__input__button,
							styles.file__input__button
						].join(" ")}
						onClick={() => {
							showCameraContainer();
						}}
					>
						{loading ? (
							<Spinner large />
						) : (
							<>
								<span
									className={identityStyles.camera__icon}
								></span>
								Take Passport Photo
							</>
						)}
					</button>
				)}
			</main>
			{footer}
		</form>
	);

	return {
		title: "Upload/Take Picture",
		description,
		form,
		state: {
			isCameraOpen,
			error,
			loading,
			submitted,
			disabled: !cardImage
		},
		submit: uploadPassport
	};
}

export default useBiometrics;
