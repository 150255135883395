import React, { useState } from "react";
import ProfilePhoto from "../../assets/profilePhoto.svg";
import Hamburger from "../../assets/Header/hamburger.svg";
import AnscimsLogo from "../../assets/Header/anscimsLogo.svg";
import styles from "./style.module.css";
import { useHistory } from "react-router-dom";

const Header = ({ title, icon, toggleSidebar }) => {
	const [logoutModalShowing, toggleLogoutModal] = useState(false);
	const history = useHistory();
	let userInfo = localStorage.getItem("user_info");
	if (!userInfo) {
		history.push("/login");
	}

	try {
		userInfo = JSON.parse(userInfo);
	} catch (e) {
		console.log(e);
	}

	return (
		<header className={styles.header}>
			<button aria-label="hamburger menu" onClick={toggleSidebar}>
				<img src={Hamburger} alt="Hamburger menu" />
			</button>
			<p className={styles.title}>
				{icon && (
					<img
						src={icon}
						alt={`${title} icon`}
						style={{ marginRight: "8px" }}
					/>
				)}{" "}
				{title}
			</p>
			<nav className={styles.nav}>
				<div className={styles.profile__information}>
					<span className={styles.bold__text}>
						{userInfo.fullname}
					</span>
					<span className={styles.regular__text}>
						{userInfo.email}
					</span>
				</div>
				<button
					className={styles.profile__toggle}
					onClick={() => toggleLogoutModal(!logoutModalShowing)}
				>
					<img src={ProfilePhoto} alt="profile" />
				</button>
				{logoutModalShowing && (
					<div className={styles.logout__modal}>
						<button
							style={{
								width: "100%",
								height: "100%",
								background: "none",
								border: "none"
							}}
							onClick={() => {
								const isTaxpayer = /individual|corporate/i.test(
									userInfo.account_type
								);
								localStorage.removeItem("user_info");
								localStorage.removeItem("access_token");
								history.push("/login", {
									anscims: !isTaxpayer
								});
							}}
						>
							<span>Logout</span>
						</button>
					</div>
				)}
			</nav>
			<img src={AnscimsLogo} alt="ANSCIMS logo" />
		</header>
	);
};

export default Header;
