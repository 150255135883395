import React from "react";
import axios from "axios";
import styles from "./style.module.css";
const state = process.env.REACT_APP_COY
const stateId = process.env.REACT_APP_COY_CODE
// const coy_ANSSID = process.env.REACT_APP_ANSSID

const useEmploymentInformation = ({
	setCurrentStep,
	anssid,
	userId,
	urlPrefix = ""
}) => {
	const [occupationtype, setOccupation] = React.useState("");
	const [employername, setEmployer] = React.useState("");
	const [employeraddress, setEmployerAddress] = React.useState("");
	const [employerstate, setEmployerState] = React.useState(state);
	const [employercity, setEmployerCity] = React.useState("");
	const [monthlyincomerange, setIncomeRange] = React.useState("");
	const [companyanssid, setCompanyAnssid] = React.useState("");
	const [submitted, setSubmitted] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState();
	const [cities, setCities] = React.useState([]);
	const [states, setStates] = React.useState([]);
	const [salaryRanges, setSalaryRanges] = React.useState([]);
	const [occupations, setOccupations] = React.useState([]);

	//femi
	const [empStateCode, setEmpStateCode] = React.useState([]);

	// eslint-disable-next-line no-unused-vars
	const [empCityCode, setEmpCityCode] = React.useState([]);
	const [finishknt1, setFinishKnt1] = React.useState(0);
	const [citiesArr, setCitiesArr] = React.useState([]);
	// eslint-disable-next-line no-unused-vars
	const [statesArr, setStatesArr] = React.useState([]);

	///===============================
	const [citiesStatus, setCitiesStatus] = React.useState(false);

	const setLoadCitiesOrigin = async (e) => {
		var stateCode = e.target.selectedOptions[0].getAttribute("stateCode");
		if (stateCode === stateId) {
			setCitiesStatus(true);
		} else {
			setCitiesStatus(false);
		}
	};

	const getEmploymentInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`${urlPrefix}/users/employment-qry?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setOccupation(info.occupationtype || "");
				setEmployer(info.employername || "");
				setEmployerAddress(info.employeraddress || "");
				setEmployerState(info.employerstate || "");
				setEmployerCity(info.employercity || "");
				setIncomeRange(info.monthlyincomerange || "");
				setCompanyAnssid(info.companyanssid || "");

				setEmpStateCode(info.employeeStateCode || "");
				setEmpCityCode(info.employeeCityCode || "");
			} else {
				setEmpStateCode(stateId);
			}
		} catch (err) {
			console.error(err);
			// setError("Existing employment information could not be retrieved.");
		} finally {
			setLoading(false);
			setFinishKnt1(1);
		}
	};

	const getCities = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/city`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setCities(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getCitysEmp = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/cityEmp`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setCitiesArr(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getStates = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/state`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setStates(info);
			setStatesArr(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getSalaryRanges = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/monthlysalaryrange`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setSalaryRanges(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getOccupations = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/occupation`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setOccupations(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	React.useEffect(() => {
		getEmploymentInformation();
		getStates();
		getCities();
		getCitysEmp(); // add by Femi
		getSalaryRanges();
		getOccupations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (finishknt1 === 1) {
			//console.log(' finishknt1 [useEffect] >>>  ',finishknt1 )
			//console.log(' citiesArr [useEffect] >>>  ',citiesArr )
			//console.log(' employeeStateCode [useEffect] >>>  ',empStateCode )
			const tmpEmpCityArr = [];

			if (empStateCode === stateId) {
				setCitiesStatus(true);
			} else {
				setCitiesStatus(true);
			}

			// employee city
			citiesArr.forEach((element) => {
				if (element.Stateode === empStateCode) {
					tmpEmpCityArr.push(element);
				}
			});

			setCities([]);
			setCities(tmpEmpCityArr);

			//console.log('tmpEmpCityArr ===>  ', tmpEmpCityArr)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finishknt1, citiesArr]);

	const submitEmploymentInformation = async (e) => {
		e.preventDefault();
		setError("");
		if (anssid) {
			setSubmitted(true);
			setCurrentStep(5);
			return;
		}

		setLoading(true);

		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const accessToken = localStorage.getItem("access_token");
			const res = await axios
				.post(
					`${urlPrefix}/users/employment?${query}`,
					{
						occupationtype,
						employername,
						employeraddress,
						employerstate,
						employercity,
						monthlyincomerange,
						companyanssid,
						employmenttype: "Employed"
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);

			console.log(res);

			setSubmitted(true);
			setCurrentStep(5);
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}

			setError(
				message ||
					"The employment information form could not be submitted. Please verify your details and try again."
			);
		} finally {
			setLoading(false);
		}
	};

	const form = ({ footer }) => (
		<form
			className={styles.columned__form}
			onSubmit={submitEmploymentInformation}
		>
			<label className={styles.input__label} htmlFor="occupation">
				Occupation <span style={{ color: "red" }}>*</span>
			</label>
			<select
				id="occupation"
				className={styles.select__input}
				required
				value={occupationtype}
				onChange={(e) => setOccupation(e.target.value)}
			>
				<option value="">--Select Occupation--</option>
				{occupations
					.sort((a, b) => a.Description.localeCompare(b.Description))
					.map((occ, key) => (
						<option value={occ.Description} key={key}>
							{occ.Description.toLowerCase()}
						</option>
					))}
			</select>
			<hr className={styles.divider} />
			<label className={styles.input__label} htmlFor="employer">
				Employer’s name <span style={{ color: "red" }}>*</span>
			</label>
			<input
				type="text"
				onChange={(e) => setEmployer(e.target.value)}
				id="employer"
				placeholder="eg God's will construction company"
				required
				value={employername}
			/>
			<hr className={styles.divider} />
			<label className={styles.input__label} htmlFor="employerAddress">
				Address of Employer <span style={{ color: "red" }}>*</span>
			</label>
			<input
				type="text"
				id="employerAddress"
				placeholder="eg Okafor's crescent, Onitsha"
				long="true"
				required
				value={employeraddress}
				onChange={(e) => setEmployerAddress(e.target.value)}
			/>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="employerState"
					>
						State of employer's Address{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="employerState"
						className={styles.select__input}
						required
						value={employerstate}
						onChange={(e) => {
							setEmployerState(e.target.value);
							setLoadCitiesOrigin(e);
						}}
					>
						<option value="">--Select Employer State--</option>
						{states
							.sort((a, b) =>
								a.Description.localeCompare(b.Description)
							)
							.map((state, key) => (
								<option
									value={state.Description}
									stateCode={state.Code}
									key={key}
								>
									{state.Description.toLowerCase()}
								</option>
							))}
					</select>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="employerCity"
					>
						City of employer's Address{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="employerCity"
						className={styles.select__input}
						required
						value={employercity}
						onChange={(e) => setEmployerCity(e.target.value)}
					>
						<option value="">--Select City of Employer--</option>
						{citiesStatus ? (
							cities
								.sort((a, b) =>
									a.Description.localeCompare(b.Description)
								)
								.map((city, i) => (
									<option value={city.Description} key={i}>
										{city.Description.toLowerCase()}
									</option>
								))
						) : (
							<option value="Non-Indigene" key="10101">
								Non-Indigene
							</option>
						)}
					</select>
				</div>
			</div>
			<hr className={styles.divider} />
			<label className={styles.input__label} htmlFor="incomeRange">
				Personal Monthly income range{" "}
				<span style={{ color: "red" }}>*</span>
			</label>
			<select
				id="incomeRange"
				className={styles.select__input}
				required
				value={monthlyincomerange}
				onChange={(e) => setIncomeRange(e.target.value)}
			>
				<option value="">--Select Income Range--</option>
				{salaryRanges.map((conf, i) => (
					<option
						key={i}
					>{`${conf.StartSalaryRange} - ${conf.EndSalaryRange}`}</option>
				))}
			</select>
			<label className={styles.input__label} htmlFor="companyAnssid">
				Company ESBN
			</label>
			<input
				type="text"
				id="companyAnssid"
				placeholder="eg 12345678"
				value={companyanssid}
				onChange={(e) => setCompanyAnssid(e.target.value)}
			/>
			<hr className={styles.divider} />
			{footer}
		</form>
	);

	return {
		title: "Employment Information",
		description:
			"This screen requires details about your occupation and current employment/employer’s details. Please enter the correct information in the appropriate fields as required, noting those marked with the asterick (*) sign as compulsory and Click Continue to proceed OR Exit & Log out.",
		form,
		state: {
			submitted,
			loading,
			error,
			disabled: !!anssid
		},
		submit: submitEmploymentInformation
	};
};

export default useEmploymentInformation;
