import React, { createRef, useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import Header from "../components/header";
import Sidenav from "../components/sidenav";
import Home from "./Home";
import MDA from "./MDAs";
import IndividualANSSID from "./ANSSID/individual";
import CorporateANSSID from "./ANSSID/corporate";
import ANSSIDAudit from "./ANSSID/audit";
import AuthorizeANSID from "./ANSSID/authorize";
import IndividualAuditDetails from "./ANSSID/individualAuditDetails";
import CorporateAuditDetails from "./ANSSID/corporateAuditDetails";
import RATs from "./RATs";
import JBTs from "./JBTs";
import TotalCollections from "./Total-Collections";
import ClearedBills from "./Bills/cleared";
import UnclearedBills from "./Bills/uncleared";
import CreateMDA from "./MDAs/create";
import CreateJTB from "./JBTs/create";
import User from "./user";
import Claim from "./Claim";
import Role from "./roles";
import CreateUser from "./user/create";
import CreateClaim from "./Claim/create";
import CreateRole from "./roles/create";
import Analytics from "./analysis";
import LGAAnalytics from "./analysis/lga";
import MDAAnalytics from "./analysis/mda";
import RevenueHeadAnalytics from "./analysis/revenuehead";
import HandlingSystemAnalytics from "./analysis/handlingsystem";
import BillingGroupAnalytics from "./analysis/billinggroup";
import MDAOptionsAnalytics from "./analysis/mda.options";
import Summary from "./summary";
import ANSSIDAgent from "./ANSSIDAgent";
import DeactivatedAgents from "./DeactivatedAgent";
import UserDetails from "./UserDetails";
import CompanyDetails from "./CompanyDetails";
import UserSidenav from "../components/sidenav/user";
import Support from "./Support";
import PrintANSSID from "./PrintANSSID";
import PrintDetails from "./PrintDetails";

import Receipt from "./Receipt";
import ReceiptPymtRef from "./ReceiptPymtRef/receiptref";
// import ReceiptPymtOurs from "./ReceiptPymtRef/receiptours";
import ReceiptPymtSwitch from "./ReceiptPymtRef/receiptswitch";
import ReceiptPymtInv from "./ReceiptPymtRef/receiptinv";
import IDDetails from "./Settings/id.details";
import ANSSIDSetup from "./Settings/anssid.setup";
import MonthlySalaryRange from "./Settings/monthly.salary.range";
import RevenueDetails from "./Settings/revenue.details";
import ChangePassword from "./ChangePassword";

import SpecialDashboard from "./SpecialDashboard";

import TotalCollectionsIcon from "../assets/Header/totalCollections.svg";
import BillsIcon from "../assets/Header/bills.svg";
import MDAIcon from "../assets/Header/mdas.svg";
import ANSSIDIcon from "../assets/Header/anssid.svg";
import AnalyticsIcon from "../assets/Header/analytics.svg";
import DashboardIcon from "../assets/Header/dashboard.svg";
import UserManagementIcon from "../assets/Header/userManagement.svg";
import ReceiptIcon from "../assets/Header/receipts.svg";
import SettingsIcon from "../assets/Header/settings.svg";
import ChangePasswordIcon from "../assets/Header/changePassword.svg";

import styles from "./style.module.css";
const coy_ANSSID = process.env.REACT_APP_ANSSID

let userInfo;
try {
	userInfo = JSON.parse(localStorage.getItem("user_info"));
} catch (e) {
	userInfo = {};
}


const Dashboard = () => {
	const [sidebarOpen, toggleSidebar] = useState(window.innerWidth > 768);
	const { pathname } = useLocation();
	const { path } = useRouteMatch();

	const navRef = createRef();
	const menuRef = createRef();

	const isUser = /^(individual)|(corporate)$/i.test(userInfo?.account_type);
	const isCorporate = /^corporate$/i.test(userInfo?.account_type);

	const isGovernor = userInfo?.roles?.[0]?.Claims?.find((cl) =>
		/governor/i.test(cl.Name)
	);

	const allowedRoutes = (userInfo?.roles?.[0]?.Claims || []).reduce(
		(acc, cl) => {
			acc[cl.Route] = true;
			return acc;
		},
		{}
	);

	const hasDashboard = Boolean(
		Object.keys(allowedRoutes).find((key) => key === "/")
	);

	const routes = {
		[`${path}`]: {
			title: isUser ? "Home" : "Dashboard"
		},
		[`${path}details`]: {
			title: "Details",
			component: isCorporate ? CompanyDetails : UserDetails
		},
		[`${path}password`]: {
			title: "Change Password",
			component: ChangePassword
		},
		[`${path}support`]: {
			title: "Contact Support",
			component: Support
		},
		[`${path}anssid`]: {
			title: "Contact Support",
			component: PrintANSSID
		},
		[`${path}print`]: {
			title: "Print Details",
			component: PrintDetails
		}
	};

	const adminRoutes = {
		[`${path}`]: {
			title: "Dashboard",
			icon: DashboardIcon
		},
		[`${path}total-collections`]: {
			title: "Total Collections",
			component: TotalCollections,
			icon: TotalCollectionsIcon
		},
		[`${path}bills/cleared-bills`]: {
			title: "Cleared Bills",
			icon: BillsIcon,
			component: ClearedBills
		},
		[`${path}bills/uncleared-bills`]: {
			title: "Uncleared Bills",
			icon: BillsIcon,
			component: UnclearedBills
		},
		[`${path}mdas`]: {
			title: "MDA",
			icon: MDAIcon,
			component: MDA
		},
		[`${path}create-mda`]: {
			title: "Create MDA",
			icon: MDAIcon,
			component: CreateMDA
		},
		[`${path}anssid/individual`]: {
			// title: "ANSSID Individual",
			title: `${coy_ANSSID} - Individual`,
			icon: ANSSIDIcon,
			component: IndividualANSSID
		},
		[`${path}anssid/corporate`]: {
			// title: "ANSSID Organization",
			title: `${coy_ANSSID} - Organization`,
			icon: ANSSIDIcon,
			component: CorporateANSSID
		},
		[`${path}anssid/audit`]: {
			// title: "ANSSID - Auditor",
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: ANSSIDAudit
		},
		[`${path}anssid/authorize`]: {
			// title: "ANSSID - Authorize",
			title: `${coy_ANSSID} - Authorize`,
			icon: ANSSIDIcon,
			component: AuthorizeANSID
		},
		[`${path}audit-anssid/individual/`]: {
			// title: "ANSSID - Auditor",
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: IndividualAuditDetails
		},
		[`${path}audit-anssid/corporate/`]: {
			// title: "ANSSID - Auditor",
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: CorporateAuditDetails
		},
		[`${path}rats`]: {
			title: "RAT",
			component: RATs
		},
		[`${path}jtbs`]: {
			title: "JTB",
			component: JBTs
		},
		[`${path}analytics`]: {
			title: "Analytics",
			icon: AnalyticsIcon,
			component: Analytics
		},
		[`${path}lgaanalytics`]: {
			title: "LGA Analytics",
			icon: AnalyticsIcon,
			component: LGAAnalytics
		},
		[`${path}mdaanalytics`]: {
			title: "MDA Analytics",
			icon: AnalyticsIcon,
			component: MDAAnalytics
		},
		[`${path}revenueheadanalytics`]: {
			title: "Revenue Head Analytics",
			icon: AnalyticsIcon,
			component: RevenueHeadAnalytics
		},
		[`${path}handlingsystemanalytics`]: {
			title: "Handling System Analytics",
			icon: AnalyticsIcon,
			component: HandlingSystemAnalytics
		},
		[`${path}billgroupanalytics`]: {
			title: "Billing Group Analytics",
			icon: AnalyticsIcon,
			component: BillingGroupAnalytics
		},
		[`${path}mdaoptionsanalytics`]: {
			title: "Top & Bottom MDAs",
			icon: AnalyticsIcon,
			component: MDAOptionsAnalytics
		},
		[`${path}create-jtb`]: {
			title: "Create JTB",
			component: CreateJTB
		},
		[`${path}user-management/manage-users`]: {
			title: "Manage Users",
			icon: UserManagementIcon,
			component: User
		},
		[`${path}user-management/manage-claims`]: {
			title: "Manage Claims",
			icon: UserManagementIcon,
			component: Claim
		},
		[`${path}user-management/anssid-agent`]: {
			title: `${coy_ANSSID} Agent`,
			icon: UserManagementIcon,
			component: ANSSIDAgent
		},
		[`${path}user-management/deactivate-agent`]: {
			title: "Deactivated Agent",
			icon: UserManagementIcon,
			component: DeactivatedAgents
		},
		[`${path}user-management/manage-roles`]: {
			title: "Manage Roles",
			icon: UserManagementIcon,
			component: Role
		},
		[`${path}create-user`]: {
			title: "Create User",
			icon: UserManagementIcon,
			component: CreateUser
		},
		[`${path}create-claim`]: {
			title: "Create Claim",
			component: CreateClaim
		},
		[`${path}create-role`]: {
			title: "Create Role",
			component: CreateRole
		},
		[`${path}receipt`]: {
			title: "Receipt",
			icon: ReceiptIcon,
			component: Receipt
		},
		[`${path}receiptprt/receiptref`]: {
			title: "Generate Receipt by Payment Reference",
			icon: ReceiptIcon,
			component: ReceiptPymtRef
		},
		/*		[`${path}receipt/receiptours`]: {
			title: "Generate Receipt by Norlics Receipt Number",
			icon: ReceiptIcon,
			component: ReceiptPymtOurs
		},
		*/
		[`${path}receiptprt/receiptswitch`]: {
			title: "Generate Receipt by Provider Receipt Number",
			icon: ReceiptIcon,
			component: ReceiptPymtSwitch
		},
		[`${path}receiptprt/receiptinv`]: {
			title: "Generate Receipt by Vertical's Invoice Number",
			icon: ReceiptIcon,
			component: ReceiptPymtInv
		},
		[`${path}settings/id-details`]: {
			title: "ID Details",
			icon: SettingsIcon,
			component: IDDetails
		},
		[`${path}settings/ANSSID-setup`]: {
			title: `${coy_ANSSID} Setup`,
			icon: SettingsIcon,
			component: ANSSIDSetup
		},
		[`${path}settings/monthly-salary-range`]: {
			title: "Monthly Salary Range",
			icon: SettingsIcon,
			component: MonthlySalaryRange
		},
		[`${path}settings/revenue-details`]: {
			title: "Revenue Details",
			icon: SettingsIcon,
			component: RevenueDetails
		},
		[`${path}password`]: {
			title: "Change Password",
			icon: ChangePasswordIcon,
			component: ChangePassword
		},
		[`${path}individual-details`]: {
			title: "Details",
			component: UserDetails
		},
		[`${path}corporate-details`]: {
			title: "Details",
			component: CompanyDetails
		}
	};

	const routeBucket = isUser ? routes : adminRoutes;

	const route = routeBucket[pathname];

	const firstAllowedRoute = Object.keys(allowedRoutes || {}).find((key) =>
		Boolean(routeBucket[key])
	);

	const comp = routeBucket[firstAllowedRoute];

	useEffect(() => {
		function handleResize() {
			toggleSidebar(window.innerWidth > 768);
		}

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	function handleSidebarToggle() {
		toggleSidebar(!sidebarOpen);
	}

	return (
		<section className={styles.wrapper}>
			{sidebarOpen &&
				(isUser ? (
					<UserSidenav
						navRef={navRef}
						menuRef={menuRef}
						toggleSidebar={handleSidebarToggle}
					/>
				) : (
					<Sidenav
						navRef={navRef}
						menuRef={menuRef}
						toggleSidebar={handleSidebarToggle}
					/>
				))}
			<div className={styles.faux__sidenav} />
			<div className={styles.main__right}>
				<Header
					title={route?.title}
					icon={route?.icon && route?.icon}
					toggleSidebar={handleSidebarToggle}
				/>
				<Switch>
					<Route path={path} exact>
						{isUser ? (
							<Home />
						) : !hasDashboard && !isGovernor && comp?.component ? (
							<comp.component />
						) : isGovernor || userInfo?.account_type === "admin" ? (
							<SpecialDashboard />
						) : (
							<Summary />
						)}
					</Route>
					{Object.keys(routeBucket).map((r) => {
						const Component = routeBucket[r]?.component;
						return (
							Component && (
								<Route key={r} path={r}>
									<Component />
								</Route>
							)
						);
					})}
				</Switch>
			</div>
		</section>
	);
};

export default Dashboard;
