import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import styles from "../../Identity-management-individual/style.module.css";

const useStepTwo = ({ setCurrentStep, uid }) => {
  const history = useHistory();

  const [code, setCode] = useState("");
  const [newPassword, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const resetPassword = async (e) => {
    e?.preventDefault();
    setLoading(true);

    try {
      const result = await axios.post('/auth/forgot-password', {
        uid,
        code,
        newPassword
      }).then(res => (res.data || {}).data);

      if (result.status) {
        history.push("/login")
      }
    } catch (err) {
      console.error(err);
      setError("Could not reset password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const form = ({ footer }) => (
    <form className={styles.columned__form} onSubmit={resetPassword}>
      <div className={styles.double__inputs}>
        <div>
          <label className={styles.input__label} htmlFor="code">Code *</label>
          <input
            type="text"
            onChange={(e) => setCode(e.target.value)}
            id="code"
            placeholder="OTP code"
            required
            value={code} />
        </div>
        <div>
          <label className={styles.input__label} htmlFor="password">New Password *</label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            placeholder="*************"
            required
            value={newPassword} />
        </div>
      </div>
      <hr className={styles.divider} />
      {footer}
    </form>
  );

  return {
    title: "Enter OTP Code",
    form,
    state: {
      uid,
      error,
      loading
    }
  }
};

export default useStepTwo;
