import { useEffect, useState } from "react";
import axios from "axios";
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";
const coy_ANSSID = process.env.REACT_APP_ANSSID

let userInfo;
try {
	userInfo = JSON.parse(localStorage.getItem("user_info"));
} catch (e) {
	userInfo = {};
}

const Home = () => {
	const [anssidInfo, setAnssidInfo] = useState({});

	const getAnssid = async () => {
		const res = await axios.get(`/system/getuseranssid`).then(res => (res.data || {}).data);
		console.log(res);
		setAnssidInfo(res);
	};

	useEffect(() => {
		getAnssid();
	}, []);

	return (
		<div className={styles.home}>
			<h1 className={styles.salutation}>Welcome {userInfo.firstname},</h1>
			<p className={styles.basic}>
				Your data has been saved successfully.
				<br />
				Your {coy_ANSSID} number: <b>{anssidInfo.Anssid}</b>
				<br />
				Your registration date: <b>{new Date(anssidInfo.createdAt || Date.now()).toLocaleDateString()}</b>
			</p>
			<p className={styles.details}>
				Your email address: {userInfo.email} <br />
				{/* &lt;Verified&gt; */}
			</p>
			<p className={styles.details}>
				Your phone number: {userInfo.phonenumber}
				<br />
				{/* &lt;Unverified&gt; */}
				<br />
				{/* Click <NavLink to="/otp-phone">here</NavLink> to Verify */}
			</p>
			<p className={styles.details}>
				Please contact <NavLink to="/support">support</NavLink>, if you have any issues.
			</p>
		</div>
	);
};

export default Home;
