import React from 'react';
import PersonalPrint from './personal';
import CorporatePrint from './corporate';

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const PrintDetails = () => {

  return userInfo.account_type === 'individual'
    ? <PersonalPrint />
    : <CorporatePrint />
};

export default PrintDetails;
