import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import axios from "axios";
import styles from "./style.module.css";
import backArrow from "../../assets/ANSSID/backArrow.svg";
import coatOfArm from "../../assets/coatOfArm.svg";
import anssidLogo from "../../assets/anssidLogo.svg";
import AnambraLogo from "../../assets/anambraLogo.svg";
import PrintStamp from "../../assets/ANSSID/detailsPrintStamp.svg";
const coy_ANSSID = process.env.REACT_APP_ANSSID

const CorporateAuditDetails = () => {
	const printableDetailsRef = useRef();
	const history = useHistory();
	const location = useLocation();
	const [details, setDetails] = useState(null);
	const [loading, setLoading] = useState(false);

	async function fetchDetails() {
		try {
			const accessToken = localStorage.getItem("access_token");
			let { data } = await axios
				.get(`/anssid?anssid=${location.state.anssid}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data);

			if (!data) {
				// handle errors
				return null;
			}

			setDetails(data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div style={{ display: "none" }}>
				<div
					className={styles.print__details}
					ref={printableDetailsRef}
				>
					<div className={styles.print__details__header}>
						<img src={coatOfArm} alt="coat of arm" />
						<img src={anssidLogo} alt="ANSSID logo" />
						<img src={AnambraLogo} alt="Anambra state logo" />
					</div>
					<main className={styles.print__main}>
						<div className={styles.print__left}>
							<div className={styles.main__top}>
								<div>
									<h2>{coy_ANSSID} Details</h2>
									<div>{details?.anssid || "N/A"}</div>
									<div>
										{(details?.createdAt &&
											new Date(
												details?.createdAt
											).toLocaleDateString("en-GB", {
												dateStyle: "short"
											})) ||
											"N/A"}
									</div>
									<div>{details?.status || "N/A"}</div>
								</div>
							</div>
							<h2>Company Details</h2>
							<div className={styles.deets}>
								Company's name
								<span>{details?.name}</span>
							</div>
							<div className={styles.deets}>
								Company's Head Office Address
								<span>
									{details?.headOfficeAddress || "N/A"}
								</span>
							</div>
							<div className={styles.deets}>
								State Office Address
								<span>
									{details?.stateOfficeAddress || "N/A"}
								</span>
							</div>
							<div className={styles.deets}>
								City
								<span>{details?.city || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								LGA
								<span>{details?.lga || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Town of Residence
								<span>{details?.townOfResidence || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								City of Residence
								<span>{details?.cityOfResidence || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Company's Phone No
								<span>{details?.phoneNumber || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Company's Email Address
								<span>{details?.email || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Company's R.C No/BN No
								<span>{details?.rcNumber || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Category of Business
								<span>
									{details?.businessCategory || "N/A"}
								</span>
							</div>
							<div className={styles.deets}>
								Sector of Economy
								<span>{details?.economicSector || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								No. of Employees in the State
								<span>{details?.numberOfStaff || "N/A"}</span>
							</div>
							<div className={styles.deets}>
								Registration Date
								<span>
									{(details?.registrationDate &&
										new Date(
											details?.registrationDate
										).toLocaleString("en-GB", {
											dateStyle: "short"
										})) ||
										"N/A"}
								</span>
							</div>
							<div className={styles.deets}>
								Business Commencement Date
								<span>
									{(details?.commencementDate &&
										new Date(
											details?.commencementDate
										).toLocaleString("en-GB", {
											dateStyle: "short"
										})) ||
										"N/A"}
								</span>
							</div>
						</div>
						<div className={styles.print__right}>
							<img src={PrintStamp} alt="Stamp" />
						</div>
					</main>
				</div>
			</div>
			<div className={styles.top}>
				<button onClick={() => history.goBack(-1)}>
					<img src={backArrow} alt="back arrow" /> Back
				</button>
				<ReactToPrint
					trigger={() => <button>Print Details</button>}
					content={() => printableDetailsRef.current}
				/>
			</div>
			<h1 className={styles.header}>Organisation Details</h1>
			<section className={styles.details}>
				<div className={styles.left}>
					<div className={styles.details__block}>
						<h2>{coy_ANSSID} Details</h2>
						<div>
							{coy_ANSSID}{" "}
							<span>
								{loading ? "..." : details?.anssid || "N/A"}
							</span>
						</div>
						<div>
							Registration Date{" "}
							<span>
								{loading
									? "..."
									: (details?.createdAt &&
											new Date(
												details?.createdAt
											).toLocaleDateString("en-GB", {
												dateStyle: "full"
											})) ||
									  "N/A"}
							</span>
						</div>
						<div>
							Created By{" "}
							<span>
								{loading ? "..." : details?.createdby || "N/A"}
							</span>
						</div>
						<div>
							Modified By{" "}
							<span>{details?.lastmodifiedby || "N/A"}</span>
						</div>
						<div>
							Status <span>{details?.status || "N/A"}</span>
						</div>
					</div>
					<div className={styles.details__block}>
						<h2>Company Details</h2>
						<div>
							Company's name{" "}
							<span>
								{loading ? "..." : details?.name || "N/A"}
							</span>
						</div>
						<div>
							Company’s Head Office Address{" "}
							<span>
								{loading
									? "..."
									: details?.headOfficeAddress || "N/A"}
							</span>
						</div>
						<div>
							State Office Address{" "}
							<span>
								{loading
									? "..."
									: details?.stateOfficeAddress || "N/A"}
							</span>
						</div>
						<div>
							City{" "}
							<span>
								{loading ? "..." : details?.city || "N/A"}
							</span>
						</div>
						<div>
							LGA{" "}
							<span>
								{loading ? "..." : details?.lga || "N/A"}
							</span>
						</div>
						<div>
							Town Of Residence{" "}
							<span>
								{loading
									? "..."
									: details?.townOfResidence || "N/A"}
							</span>
						</div>
						<div>
							City Of Residence{" "}
							<span>
								{loading
									? "..."
									: details?.cityOfResidence || "N/A"}
							</span>
						</div>
						<div>
							Company’s Phone No{" "}
							<span>
								{loading
									? "..."
									: details?.phoneNumber || "N/A"}
							</span>
						</div>
						<div>
							Company’s Email Address{" "}
							<span>
								{loading ? "..." : details?.email || "N/A"}
							</span>
						</div>
						<div>
							Company’s R.C No/ BN No
							<span>
								{loading ? "..." : details?.rcNumber || "N/A"}
							</span>
						</div>
						<div>
							Category Of Business
							<span>
								{loading
									? "..."
									: details?.businessCategory || "N/A"}
							</span>
						</div>
						<div>
							Sector Of Economy
							<span>
								{loading
									? "..."
									: details?.economicSector || "N/A"}
							</span>
						</div>
						<div>
							No. Of Employees In The State
							<span>
								{loading
									? "..."
									: details?.numberOfStaff || "N/A"}
							</span>
						</div>
						<div>
							Registration Date
							<span>
								{loading
									? "..."
									: details?.commencementDate
									? new Date(
											details?.registrationDate
									  ).toLocaleDateString("en-GB", {
											dateStyle: "medium"
									  })
									: "N/A"}
							</span>
						</div>
						<div>
							Business Commencement Date
							<span>
								{loading
									? "..."
									: details?.commencementDate
									? new Date(
											details?.commencementDate
									  ).toLocaleDateString("en-GB", {
											dateStyle: "medium"
									  })
									: "N/A"}
							</span>
						</div>
					</div>
				</div>
			</section>
			<ReactToPrint
				trigger={() => (
					<button
						className={`primary__btn ${styles.mobile__print_btn}`}
					>
						Print Details
					</button>
				)}
				content={() => printableDetailsRef.current}
			/>
		</div>
	);
};

export default CorporateAuditDetails;
