import styles from "../../components/Table/style.module.css";

const PreviewTable = ({ headers = {}, data = [] }) => {
	const headerKeys = Object.keys(headers);
	
	return (
		<table className={styles.table}>
			<thead>
				<tr className={styles.table__row}>
					{headerKeys.map((key, i) => (
						<th key={i}>{headers[key]}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((row, i) => (
					<tr className={styles.table__row} key={i}>
						{headerKeys.map((key, i) => (
							<td key={i}>{row[key]}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default PreviewTable;
