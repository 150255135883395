import { Route, Switch, useLocation, useRouteMatch } from "react-router";
import BusinessAnssid from "./business.anssid";
import CompanyDetails from "./company.details";
import StaffRecords from "./staff.records";
import styles from "./style.module.css";

const CorporateDetails = () => {
	const { path } = useRouteMatch();
	const location = useLocation();

	const userId = location?.state?.userId || new URLSearchParams(location?.search).get('userId');
	const query = userId ? `?userId=${userId}` : '';

	const pages = {
		[`${path}`]: {
			component: CompanyDetails,
			next: '/business' + query,
			exact: true
		},
		[`${path}/business`]: {
			component: BusinessAnssid,
			next: '/staff' + query,
			prev: '/' + query
		},
		[`${path}/staff`]: {
			component: StaffRecords,
			prev: '/business' + query
		},
	};

	return (
		<section className={styles.wrapper}>
			<Switch>
				{Object.keys(pages).map((r) => {
					const config = pages[r];
					const Component = config?.component;
					return (
						Component && (
							<Route key={r} path={r} exact={config.exact}>
								<Component {...location.state} userId={userId} next={`${path}${config.next}`} prev={config.prev && `${path}${config.prev}`} />
							</Route>
						)
					);
				})}
			</Switch>
		</section>
	);
};

export default CorporateDetails;
