import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import styles from "../../Identity-management-corporate/style.module.css";
import style1 from "./style.module.css";
import ArrowRight from "../../assets/arrowRight.svg";
import ArrowLeft from "../../assets/arrowLeft.svg";

const BusinessAnssid = ({ next, prev, userId }) => {
	const [imgPreview, setImgPreview] = useState();
	const [imagePreview, setImagePreview] = useState();
	const [finalKnt, setFinalKnt] = useState("0");
	const [values, setValue] = useState({
		ownerAnssid: [""],
		CACCertificate: ""
	});

	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);

	const ownerAnssids = Array.isArray(values.ownerAnssid)
		? values.ownerAnssid
		: [values.ownerAnssid];

	const getCompanyDetails = async () => {
		setLoading(true);
		try {
			let query = '';
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`/korporate/company/upload?${query}`)
				.then((res) => (res.data || {}).data);

			if (info) {
				let ownerAnssid = info.ownerAnssid;
				setImgPreview(info.certificate || "")

				try {
					ownerAnssid = JSON.parse(ownerAnssid);
				} catch (e) { }
				setValue({
					...values,
					ownerAnssid,
					CACCertificate: info.certificate
				});
				setImagePreview(info.certificate);
			}
		} catch (err) {
			console.error(err);
			// setError("Existing company information could not be retrieved.");
		} finally {
			setLoading(false);
			setFinalKnt("1")
		}
	};

	useEffect(() => {
		getCompanyDetails();
		if (finalKnt === "1") {
			setImagePreview(imgPreview)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finalKnt])

	return (
		<>
			<div className={style1.title__div}>
				<Link to={prev}>
					<img src={ArrowLeft} alt="left arrow" /> Prev
				</Link>
				<h1>Business Owner / Manager</h1>
				<Link to={next}>
					Next <img src={ArrowRight} alt="right arrow" />
				</Link>
			</div>
			<form className={styles.form}>
				<div className={styles.div__wrapper} style={{ width: "54rem" }}>
					{ownerAnssids.map((id, i) => (
						<div key={i}>
							<label htmlFor={`ownerAnssid${i}`}>
								Business Owner / Manager{" "}
								{ownerAnssids.length > 1 ? `${i + 1}` : ""}{" "}
							</label>
							<input
								type="number"
								name={`ownerAnssid${i}`}
								id={`ownerAnssid${i}`}
								className={styles.input__medium}
								style={{ marginRight: "2.8rem" }}
								min={0}
								value={id}
								placeholder="eg 8379302918"
								disabled
							/>
						</div>
					))}
				</div>
				<div
					className={styles.upload__div}
					style={{ marginBottom: 30 }}
				>
					<input
						id="CACCertificate"
						hidden
						type="file"
						name="CACCertificate"
						accept="image/*"
						// value={values.CACCertificate}
						disabled
					/>
					<p className={styles.file__name}>
						{values.CACCertificate?.name}
					</p>
				</div>
				<div
					className={styles.upload__div}
					style={{ width: "100%", height: "34vh" }}
				>
					{imagePreview && (
						<img
							src={imagePreview}
							className={styles.image__preview}
							style={{ opacity: 1 }}
							alt="Uploaded document"
						/>
					)}
				</div>
			</form>
		</>
	);
};

export default BusinessAnssid;
