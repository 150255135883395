import {
	Switch,
	BrowserRouter as Router,
	Route,
	Redirect
} from "react-router-dom";
import Login from "./Auth/Login/Login";
import Signup from "./Auth/SignUp/signup";
import ResetPassword from "./Auth/ResetPassword";
import IndividualIdentityManagement from "./Identity-management-individual";
import CorporateIdentityManagement from "./Identity-management-corporate";
import CorporateIdentityManagementSuccess from "./Identity-management-corporate/final";
import IndividualIdentityManagementSuccess from "./Identity-management-individual/final";
import Otp from "./Auth/OTP";
import Dashboard from "./Dashboard";
import Home from "./Home";

function App() {
	const accessToken = localStorage.getItem("access_token");
	let userInfo = localStorage.getItem("user_info");
	if (userInfo) {
		userInfo = JSON.parse(userInfo);
	}

	return (
		<Router>
			<Switch>
				<Route path="/home">
					<Home />
				</Route>
				<Route path="/identity-management">
					{userInfo?.account_type === "corporate" ? (
						<CorporateIdentityManagement />
					) : (
						<IndividualIdentityManagement />
					)}
				</Route>
				<Route path="/CIM-success">
					<CorporateIdentityManagementSuccess />
				</Route>
				<Route path="/IM-success">
					<IndividualIdentityManagementSuccess />
				</Route>
				<Route path="/login">
					<Login />
				</Route>
				<Route path="/reset-password">
					<ResetPassword />
				</Route>
				<Route path="/signup">
					<Signup />
				</Route>
				<Route path="/otp-phone">
					<Otp title="Phone" />
				</Route>
				<Route path="/otp-email">
					<Otp title="Email" />
				</Route>
				<Route path="/individual-identity-management">
					{
						!/^(individual)|(corporate)$/i.test(userInfo?.account_type) ? (
							<IndividualIdentityManagement />
						) : <div>You should not be here...</div>
					}
				</Route>
				<Route path="/corporate-identity-management">
					{
						!/^(individual)|(corporate)$/i.test(userInfo?.account_type) ? (
							<CorporateIdentityManagement />
						) : <div>You should not be here...</div>
					}
				</Route>
				<Route path="/">
					{(userInfo?.account_type && (!/^(individual)|(corporate)$/i.test(userInfo?.account_type))) || userInfo?.anssid ? (
						<Dashboard />
					) : accessToken ? (
						<Redirect to="/identity-management" />
					) : (
						<Redirect to="/home" />
					)}
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
