import React from "react";
import axios from "axios";
import styles from "./style.module.css";

const inputRef = React.createRef();

// const childrenDetailsArray = [];

const useFamilyInformation = ({
	setCurrentStep,
	anssid,
	userId,
	urlPrefix = ""
}) => {
	const [maritalstatus, setMaritalStatus] = React.useState("");
	const [childrennumber, setNoOfChildren] = React.useState(0);
	const [spousefirstname, setSpouseFirstName] = React.useState("");
	const [spousemiddlename, setSpouseMiddleName] = React.useState("");
	const [spousesurname, setSpouseSurname] = React.useState("");
	const [spousephonenumber, setSpousePhoneNumber] = React.useState("");
	const [spouseoccupation, setSpouseOccupation] = React.useState("");
	const [spousebusinessaddress, setSpouseResidentialAddress] =
		React.useState("");
	const [childrenDetailsArray, setChildrenDetails] = React.useState([]);
	const [childDetails, setChildDetails] = React.useState({
		firstname: "",
		middlename: "",
		surname: "",
		school: "",
		schooladdress: ""
	});
	// const [formLoad, setFormLoad] = React.useState(false);
	const [loadChildNo, setLoadChildNo] = React.useState(0);

	const [submitted, setSubmitted] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState();

	const [occupations, setOccupations] = React.useState([]);

	const getFamilyInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`${urlPrefix}/users/family?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setMaritalStatus(info.maritalstatus || "");
				setNoOfChildren((info.children || []).length);
				setSpouseFirstName(info.spousefirstname || "");
				setSpouseMiddleName(info.spousemiddlename || "");
				setSpouseSurname(info.spousesurname || "");
				setSpousePhoneNumber(info.spousephonenumber || "");
				setSpouseOccupation(info.spouseoccupation || "");
				setSpouseResidentialAddress(info.spousebusinessaddress || "");
				// setChildDetails(info.children || []);
				setChildrenDetails(info.children || []);
				// setFormLoad(true)
				setLoadChildNo((info.children || []).length);
			}
		} catch (err) {
			console.error(err);
			// setError("Existing family information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	const getOccupations = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/occupation`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setOccupations(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	React.useEffect(() => {
		getFamilyInformation();
		getOccupations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitFamilyInformation = async (e) => {
		e.preventDefault();
		setError("");
		if (anssid) {
			setSubmitted(true);
			setCurrentStep(4);
			return;
		}

		setLoading(true);

		const numberOfChildren = parseInt(childrennumber);
		// const children = numberOfChildren
		// 	? childDetails.firstname &&
		// 	childDetails.surname &&
		// 	(childrenDetailsArray || []).concat(childDetails)
		// 	: [];
		const children = numberOfChildren ? (childrenDetailsArray || []).concat(childDetails) : [];

		console.log('numberOfChildren  ==>  ', numberOfChildren)
		console.log('children  ==>  ', children)

		const nChild = children.filter(ch =>
			ch.firstname !== "" && ch.surname !== "" && ch.school !== ""
		);
		console.log('nChild  ==>  ', nChild)

		const nChildNow = nChild

		console.log('nChildNow  ==>  ', nChildNow)
		console.log('loadChildNo  ==>  ', loadChildNo)

		const keys = Object.keys(nChildNow);
		keys.forEach((key) => {
			delete nChildNow[key].lastModifiedBy
		});
		console.log('nChildNow [after delete] ==>  ', nChildNow)


		// eslint-disable-next-line eqeqeq
		// if (numberOfChildren != children.length) {
		if (numberOfChildren !== nChild.length) {
			setError("Number of children must match number of added children");
			return;
		}

		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const accessToken = localStorage.getItem("access_token");
			const res = await axios
				.post(
					`${urlPrefix}/users/family?${query}`,
					{
						maritalstatus,
						spousefirstname,
						spousemiddlename,
						spousesurname,
						spousephonenumber,
						spouseoccupation,
						spousebusinessaddress,
						children: nChildNow,
						childrennumber			// femi
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);

			console.log(res);

			setSubmitted(true);
			setCurrentStep(4);
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}

			setError(
				message ||
				"Family information could not be submitted. Please verify your details and try again."
			);
		} finally {
			setLoading(false);
		}
	};

	function handleChildInfoChange(e) {
		const value = e.target.value;
		const name = e.target.name;

		setChildDetails({
			...childDetails,
			[name]: value
		});
	}

	function handleChildInArrayChange(e, i) {
		const value = e.target.value;
		const name = e.target.name;

		const newChildrenArray = childrenDetailsArray.map((child, index) => {
			return index === i ? { ...child, [name]: value } : child;
		});

		setChildrenDetails(newChildrenArray);
	}

	function addAnotherChild() {
		setChildrenDetails([...childrenDetailsArray, childDetails]);
		setChildDetails({
			...childDetails,
			firstname: "",
			middlename: "",
			surname: "",
			school: "",
			schooladdress: ""
		});

		inputRef.current.focus();

		window.scrollTo(0, inputRef.current.offsetTop - 50);
	}

	function removeChild(index) {
		const newChildrenArray = [...childrenDetailsArray];
		newChildrenArray.splice(index, 1);
		setChildrenDetails(newChildrenArray);
	}

	const form = ({ footer }) => (
		<form
			className={styles.columned__form}
			onSubmit={submitFamilyInformation}
		>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="maritalStatus"
					>
						Marital Status <span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="maritalStatus"
						className={styles.select__input}
						required
						value={maritalstatus}
						onChange={(e) => {
							setMaritalStatus(e.target.value);
							if (e.target.value === 'Single') {
								setNoOfChildren(0)
								setChildDetails([])
								setSpouseFirstName("");
								setSpouseMiddleName("");
								setSpouseSurname("");
								setSpousePhoneNumber("");
								setSpouseOccupation("");
								setSpouseResidentialAddress("");
							}
						}}
					>
						<option value="">--Select Marital Status--</option>
						<option>Single</option>
						<option>Married</option>
						<option>Divorced</option>
						<option>Widowed</option>
					</select>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="noOfChildren"
					>
						Children <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="number"
						onChange={(e) => {
							setNoOfChildren(e.target.value);
							if (Number(e.target.value) === 0) {
								setChildDetails({
									firstname: "",
									middlename: "",
									surname: "",
									school: "",
									schooladdress: ""
								});
								setChildrenDetails([]);
							}
						}}
						id="noOfChildren"
						min={0}
						required
						value={childrennumber}
					/>
				</div>
			</div>
			<hr className={styles.divider} />
			{!/single/i.test(maritalstatus) && (
				<>
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="spouseFirstName"
							>
								{maritalstatus === "Divorced" ? "Ex-" : ""}
								Spouse first name{" "}
								{maritalstatus === "Married" ? "*" : ""}
							</label>
							<input
								type="text"
								onChange={(e) =>
									setSpouseFirstName(e.target.value)
								}
								id="spouseFirstName"
								placeholder="eg Jane"
								value={spousefirstname}
								required={maritalstatus === "Married"}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="spouseMiddleName"
							>
								{maritalstatus === "Divorced" ? "Ex-" : ""}
								Spouse Middle name
							</label>
							<input
								type="text"
								onChange={(e) =>
									setSpouseMiddleName(e.target.value)
								}
								id="spouseMiddleName"
								placeholder="eg Chinenye"
								value={spousemiddlename}
							/>
						</div>
					</div>
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="spouseSurname"
							>
								{maritalstatus === "Divorced" ? "Ex-" : ""}
								Spouse Surname{" "}
								{maritalstatus === "Married" ? "*" : ""}
							</label>
							<input
								type="text"
								onChange={(e) =>
									setSpouseSurname(e.target.value)
								}
								id="spouseSurname"
								placeholder="eg Okonkwo"
								value={spousesurname}
								required={maritalstatus === "Married"}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="spousePhoneNumber"
							>
								{maritalstatus === "Divorced" ? "Ex-" : ""}
								Spouse Phone no
							</label>
							<input
								type="text"
								onChange={(e) =>
									setSpousePhoneNumber(e.target.value)
								}
								id="spousePhoneNumber"
								placeholder="08012345678"
								value={spousephonenumber}
							/>
						</div>
					</div>
					<hr className={styles.divider} />
					<label
						className={styles.input__label}
						htmlFor="spouseOccupation"
					>
						{maritalstatus === "Divorced" ? "Ex-" : ""}Spouse
						Occupation {maritalstatus === "Married" ? "*" : ""}
					</label>
					<select
						id="spouseOccupation"
						className={styles.select__input}
						value={spouseoccupation}
						required={maritalstatus === "Married"}
						onChange={(e) => setSpouseOccupation(e.target.value)}
					>
						<option value="">--Select Occupation--</option>
						{occupations.map((occ, key) => (
							<option value={occ.Description} key={key}>
								{occ.Description}
							</option>
						))}
					</select>
					<label
						className={styles.input__label}
						htmlFor="spouseResidentialAddress"
					>
						Spouse Residential Address{" "}
						{maritalstatus === "Married" ? "*" : ""}
					</label>
					<input
						type="text"
						id="spouseResidentialAddress"
						placeholder="eg Okafor's crescent, Onitsha"
						long="true"
						value={spousebusinessaddress}
						required={maritalstatus === "Married"}
						onChange={(e) =>
							setSpouseResidentialAddress(e.target.value)
						}
					/>
					<hr className={styles.divider} />
				</>
			)}
			{!!childrennumber && +childrennumber > 0 && (
				<>
					{childrenDetailsArray.map((child, i) => (
						<React.Fragment key={i}>
							<p>Child {i + 1}</p>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="firstname"
									>
										Child's First name *
									</label>
									<input
										type="text"
										name="firstname"
										onChange={(e) =>
											handleChildInArrayChange(e, i)
										}
										id="firstname"
										placeholder="eg John"
										required
										value={child.firstname}
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="middlename"
									>
										Child’s Middle name
									</label>
									<input
										type="text"
										name="middlename"
										onChange={(e) =>
											handleChildInArrayChange(e, i)
										}
										id="middlename"
										placeholder="eg Chidi"
										value={child.middlename}
									/>
								</div>
							</div>
							<label
								className={styles.input__label}
								htmlFor="surname"
							>
								Child's surname *
							</label>
							<input
								type="text"
								name="surname"
								onChange={(e) => handleChildInArrayChange(e, i)}
								id="surname"
								placeholder="eg Jane"
								required
								value={child.surname}
							/>
							<label
								className={styles.input__label}
								htmlFor="school"
							>
								Child's school *
							</label>
							<input
								type="text"
								name="school"
								id="school"
								placeholder="eg Great school"
								long="true"
								required
								value={child.school}
								onChange={(e) => handleChildInArrayChange(e, i)}
							/>
							<label
								className={styles.input__label}
								htmlFor="schooladdress"
							>
								Child's school address *
							</label>
							<input
								type="text"
								name="schooladdress"
								id="schooladdress"
								placeholder="eg Okafor's crescent, Onitsha"
								long="true"
								required
								value={child.schooladdress}
								onChange={(e) => handleChildInArrayChange(e, i)}
							/>
							<button
								type="button"
								className={styles.add__another__child__btn}
								style={{
									color: "#F0372E",
									textAlign: "left",
									padding: 0,
									marginBottom: 10,
									marginTop: 10
								}}
								onClick={() => removeChild(i)}
							>
								- Remove Child {i + 1}
							</button>
							<hr className={styles.divider} />
						</React.Fragment>
					))}

					{(+childrennumber > +loadChildNo) && (
						<>
							<p>Child {childrenDetailsArray.length + 1}</p>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="firstname"
									>
										Child's First name *
									</label>
									<input
										type="text"
										name="firstname"
										onChange={handleChildInfoChange}
										id="firstname"
										placeholder="eg John"
										required
										value={childDetails.firstname}
										ref={inputRef}
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="middlename"
									>
										Child’s Middle name
									</label>
									<input
										type="text"
										name="middlename"
										onChange={handleChildInfoChange}
										id="middlename"
										placeholder="eg John"
										value={childDetails.middlename}
									/>
								</div>
							</div>
							<label className={styles.input__label} htmlFor="surname">
								Child's surname *
							</label>
							<input
								type="text"
								name="surname"
								onChange={handleChildInfoChange}
								id="surname"
								placeholder="eg John"
								required
								value={childDetails.surname}
							/>
							<label className={styles.input__label} htmlFor="school">
								Child's school *
							</label>
							<input
								type="text"
								name="school"
								id="school"
								placeholder="eg Great school"
								long="true"
								required
								value={childDetails.school}
								onChange={handleChildInfoChange}
							/>
							<label
								className={styles.input__label}
								htmlFor="schooladdress"
							>
								Child's school address *
							</label>
							<input
								type="text"
								name="schooladdress"
								id="schooladdress"
								placeholder="eg 15 River’s Road, Preston, Enugu State"
								long="true"
								required
								value={childDetails.schooladdress}
								onChange={handleChildInfoChange}
							/>
							<button
								type="button"
								className={styles.add__another__child__btn}
								style={{
									color: "#F0372E",
									textAlign: "left",
									padding: 0,
									marginBottom: 10,
									marginTop: 10
								}}
								onClick={() => {
									setChildDetails({
										firstname: "",
										middlename: "",
										surname: "",
										school: "",
										schooladdress: ""
									});
								}}
							>
								- Remove Child {childrenDetailsArray.length + 1}
							</button>
							<hr className={styles.divider} />
						</>
					)}

					<button
						type="button"
						className={styles.add__another__child__btn}
						onClick={addAnotherChild}
						disabled={
							!childDetails.firstname ||
							!childDetails.surname ||
							!childDetails.school ||
							!childDetails.schooladdress ||
							childrennumber <= childrenDetailsArray.length
						}
					>
						+ Add another Child
					</button>
				</>
			)}

			{footer}
		</form>
	);

	return {
		title: "Family Information",
		description:
			"Include details about your immediate family as it applies to you. Please enter the correct information in the appropriate fields as required, noting those marked with the asterick (*) sign as compulsory and Click Continue to proceed OR Exit & Log out.",
		form,
		state: {
			submitted,
			loading,
			error,
			disabled: !!anssid
		},
		submit: submitFamilyInformation
	};
};

export default useFamilyInformation;
