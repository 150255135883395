/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import constants from "../constants";
import axios from "axios";
import Wizard from "../components/wizard";
import useCompanyDetails from "./company.details.hooks";
import useUploadStaffRecords from "./UploadStaffRecord/upload.staff.records.hooks";
import useBusinessOwnerAnssid from "./business.anssid.hooks";
import useStaffRecords from "./staff.records.hooks";
import styles from "../Identity-management-corporate/style.module.css";
// import constants from "../constants";
import Spinner from "../components/spinner";

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const CorporateIdentityManagement = () => {
	const history = useHistory();
	const { state: locationState = {}, search: searchParams } = useLocation();

	const userId =
		locationState.userId || new URLSearchParams(searchParams).get("userId");

	const [currentStep, setCurrentStep] = useState(1);
	const [lgas, setLgas] = useState([]);
	const [isBulk, setIsBulk] = useState(false);
	const [anssid, setAnssid] = useState();
	const [error, setError] = useState();

	const numberOfSteps = 3;

	const urlPrefix = userId ? "/admin/corporate-taxpayer" : "";
	const bulkStaffRecords = useUploadStaffRecords({
		setCurrentStep,
		anssid,
		userId,
		urlPrefix
	});
	const staffRecords = useStaffRecords({ anssid, userId, urlPrefix });

	const stepMap = {
		1: useCompanyDetails({
			setCurrentStep,
			anssid,
			userId,
			urlPrefix,
			locationState
		}),
		2: useBusinessOwnerAnssid({
			setCurrentStep,
			anssid,
			userId,
			urlPrefix,
			locationState
		}),
		3: isBulk ? bulkStaffRecords : staffRecords
	};
	const stepInfo = stepMap[currentStep] || {};

	const getUserInfo = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/auth/profile`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setAnssid(info.anssid);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getAnssid = async () => {
		try {
			// console.log("famz.....   ");
			if (anssid) return anssid;

			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const lgaoforigin = (lgas || []).find(l => l.Description === stepMap[1].state.lga);
			const lgaId = lgaoforigin.Code;
			// console.log("lgaoforigin.....   ", lgaoforigin, ';  lgaId  >>>   ', lgaId);

			const res = await axios
				.post(
					`${constants.BASE_URL}/system/generateuseranssid?${query}`,
					{
						lgaId,
						lgaCode: lgaId,
						lgaSerial: lgaId
					}
				)
				.then((res) => (res.data || {}).data);

			if (!userId) {
				try {
					if (res.anssid && !userInfo.anssid) {
						localStorage.setItem(
							"user_info",
							JSON.stringify({ ...userInfo, anssid: res.anssid })
						);
					}
				} catch (e) {
					console.error(e);
				}
			}

			return res.anssid;
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}
			setError(
				message ||
				"An error occurred while generating organization anssid."
			);
		}
	};

	useEffect(() => {
		// console.log('stepMap[key].state.loading [index]  >>  ', stepMap)
		const isLoading = Object.keys(stepMap).find(
			(key) => stepMap[key].state.loading
		);
		if ((stepInfo?.state?.submitted && !isLoading && !stepInfo?.state?.error) || (currentStep == 2 && stepInfo.state?.submitted && !isLoading)) {
			if (currentStep === numberOfSteps) {
				// console.log("finally ", anssid);
				getAnssid().then((anssid) => {
					// console.log("finally [after call] ", anssid);
					history.push("/CIM-success", {
						anssid,
						firstname: locationState?.firstname || userInfo?.firstname,
						lastname: locationState?.lastname || userInfo?.lastname
					});
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepInfo?.state?.submitted, stepInfo?.state?.loading]);

	const getLGAs = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/lga`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setLgas(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	useEffect(() => {
		getUserInfo();
		getLGAs();
	}, []);

	return (
		<Wizard
			title={stepInfo?.title}
			description={stepInfo?.description}
			numberOfSteps={numberOfSteps}
			currentStep={currentStep}
			setCurrentStep={setCurrentStep}
			error={stepInfo?.state?.error || error}
			headerExtra={
				<button
					type="button"
					className={["primary__btn", styles.primary__button].join(
						" "
					)}
					onClick={() => {
						setIsBulk(!isBulk);
					}}
				>
					{!isBulk
						? "Upload Staff Records"
						: "Enter Staff Records Manually"}
				</button>
			}
		>
			{stepInfo.form({
				footer: (
					<footer className={styles.form__footer}>
						<button
							type="button"
							className="secondary__btn"
							onClick={async () => {
								await stepInfo?.submit();
								localStorage.removeItem("user_info");
								localStorage.removeItem("access_token");
								history.push("/login");
							}}
						>
							Save & Exit
						</button>
						<button
							disabled={
								stepInfo?.state?.loading ||
								stepInfo?.state?.disabled
							}
							type="submit"
							className={[
								"primary__btn",
								styles.accent__button,
								stepInfo?.state?.loading
									? styles.loading__button
									: ""
							].join(" ")}
						>
							{stepInfo.state.loading
								? "Saving..."
								: currentStep == numberOfSteps
									? "Finish"
									: "Continue"}
							{stepInfo.state.loading && <Spinner />}
						</button>
					</footer>
				),
				values: stepInfo?.state?.values,
				businessCategory: stepMap[1]?.state?.values?.businessCategory,
				// numberOfStaff: stepMap[1]?.state?.values?.numberOfStaff
				numberOfStaff: stepMap[1]?.state?.numberOfStaff
			})}
		</Wizard>
	);
};

export default CorporateIdentityManagement;
