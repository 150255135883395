import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import constants from "../constants";

import styles from "./style.module.css";

function formatDate(date) {
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const useCompanyDetails = ({
	setCurrentStep,
	anssid,
	userId,
	urlPrefix = "",
	locationState
}) => {
	let location = useLocation();
	// const [values, setValue] = useState({
	// 	name: locationState?.CorporateCompanyInfo?.name || "",
	// 	headOfficeAddress:
	// 		locationState?.CorporateCompanyInfo?.headOfficeAddress || "",
	// 	stateOfficeAddress:
	// 		locationState?.CorporateCompanyInfo?.stateOfficeAddress || "",
	// 	city: locationState?.CorporateCompanyInfo?.city || "Anambra",
	// 	lga: locationState?.CorporateCompanyInfo?.lga || "",
	// 	townOfResidence:
	// 		locationState?.CorporateCompanyInfo?.townOfResidence || "",
	// 	cityOfResidence:
	// 		locationState?.CorporateCompanyInfo?.cityOfResidence || "", // TODO Add to backend
	// 	phoneNumber: locationState?.CorporateCompanyInfo?.phoneNumber || "",
	// 	email: locationState?.CorporateCompanyInfo?.email || "",
	// 	rcNumber: locationState?.CorporateCompanyInfo?.rcNumber || "",
	// 	businessCategory:
	// 		locationState?.CorporateCompanyInfo?.businessCategory || "",
	// 	economicSector:
	// 		locationState?.CorporateCompanyInfo?.economicSector || "",
	// 	numberOfStaff: locationState?.CorporateCompanyInfo?.numberOfStaff || "",
	// 	registrationDate:
	// 		locationState?.CorporateCompanyInfo?.registrationDate || "",
	// 	commencementDate:
	// 		locationState?.CorporateCompanyInfo?.commencementDate || "",
	// 	tin:
	// 		locationState?.CorporateCompanyInfo?.tin || "",
	// 	tinName:
	// 		locationState?.CorporateCompanyInfo?.tinName || "",
	// 	taxOffice: locationState?.CorporateCompanyInfo?.taxOffice || "", // TODO Add to backend
	// 	verifyMode:
	// 		locationState?.CorporateCompanyInfo?.verifyMode || ""
	// });

	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const [towns, setTowns] = useState([]);
	const [, setCities] = useState([]);
	const [states, setStates] = useState([]);
	const [lgas, setLgas] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [categories, setCategories] = useState([]);
	const [economicSectors, setEconomicSectors] = useState([]);

	///===============================
	const [, setLgasArr] = useState([]);
	const [townArr, setTownsArr] = useState([]);
	const [lgasOrigin, setLgasOrigin] = useState([]);
	const [townsOrigin, setTownsOrigin] = useState([]);
	const [lgaOriginStatus, setLgaOriginStatus] = useState(false);

	//======================
	const [preCorpState, setCorpState] = useState("");
	// const [, setPreLGACorp] = useState("");
	// const [, setPreTownResidential] = useState("");
	// const [, setPreCityResidential] = useState("");
	const [finishCnt, setFinishCnt] = useState(0);
	//femi
	const [tin, setTIN] = useState("");
	const [tinName, setTINname] = useState("");
	const [coyname, setCoyName] = useState("");
	const [headOfficeAddress, setHeadOfficeAddress] = useState("");
	const [stateOfficeAddress, setStateOfficeAddress] = useState("");
	const [city, setCity] = useState("");
	const [lga, setLGA] = useState("");
	const [townOfResidence, setTownOfResidence] = useState("");
	const [cityOfResidence, setCityOfResidence] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [businessCategory, setBusinessCategory] = useState("");
	const [rcNumber, setRCNumber] = useState("");
	const [economicSector, setEconomicSector] = useState("");
	const [numberOfStaff, setNumberOfStaff] = useState("");
	const [registrationDate, setRegistrationDate] = useState("");
	const [commencementDate, setCommencementDate] = useState("");
	const [taxOffice, setTaxOffice] = useState("");

	const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);
	const [tinError, setTinError] = useState("");
	// const [verifyTin, setVerifyTin] = useState("0");
	const state = process.env.REACT_APP_COY
	const stateId = process.env.REACT_APP_COY_CODE
	// const coy_Code = process.env.REACT_APP_COY_CODE
	// femi

	const setLoadLgaOrigin = async (e) => {
		const tmpLgasArr = [];
		// var statecode = e
		// 	? e.target.selectedOptions[0].getAttribute("statecode")
		// 	: stateId;

		lgas.forEach((element) => {
			if (element.StateCode === stateId) {
				tmpLgasArr.push(element);
			}
		});

		setLgasOrigin([]);
		setLgasOrigin(tmpLgasArr);
	};

	const setLoadTownsOrigin = async (e) => {
		const tmpTownArr = [];
		var lgaCode = e.target.selectedOptions[0].getAttribute("lgacode");
		townArr.forEach((element) => {
			if (element.LgaCode === lgaCode) {
				tmpTownArr.push(element);
			}
		});

		setTownsOrigin([]);
		setTownsOrigin(tmpTownArr);
	};

	const getCompanyDetails = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				//.get(`${urlPrefix}/corporate/company?${query}`)
				.get(`${urlPrefix}/corporate/company?${query}`)
				.then((res) => (res.data || {}).data);

			if (info) {
				// setValue({
				// 	...info,
				// 	phoneNumber: info.phoneNumber || values.phoneNumber,
				// 	email: info.email || values.email,
				// 	tin: setTIN(info.tin),
				// 	tinName: setTIN(info.tinName)
				// });
				setTIN(info.tin || "")
				// setTINname(info.tinName || "")
				setCoyName(info.name || "")
				setHeadOfficeAddress(info.headOfficeAddress || "")
				setStateOfficeAddress(info.stateOfficeAddress || "")
				setCity(info.city || "")
				setLGA(info.lga || "")
				setTownOfResidence(info.townOfResidence || "")
				setCityOfResidence(info.cityOfResidence || "")
				setPhoneNumber(info.phoneNumber || "")
				setEmail(info.email || "")
				setBusinessCategory(info.businessCategory || "")
				setRCNumber(info.rcNumber || "")
				setEconomicSector(info.economicSector || "")
				setNumberOfStaff(info.numberOfStaff || "")
				setRegistrationDate(formatDate(info.registrationDate) || "")
				setCommencementDate(formatDate(info.commencementDate) || "")
				// new Date(date).toISOString().split("T")
				setTaxOffice(info.taxOffice || "")
			} else {
				setCorpState(stateId);
			}
		} catch (err) {
			// setError("Existing company information could not be retrieved.");
		} finally {
			setLoading(false);
			setFinishCnt(1);
		}
	};

	useEffect(() => {
		if (userId) {
			getCompanyDetails();
		}

		if (finishCnt === 1) {
			// const TownResArr = [];
			// const CityesArr = [];
			// const LGAArr = [];

			// State LGA
			// lgas.forEach((element) => {
			// 	if (element.StateCode === preCorpState) {
			// 		LGAArr.push(element);
			// 	}
			// });
			setLgasOrigin([]);
			// setLgasOrigin(LGAArr);
			setLgasOrigin(lgas);

			// Towns of Origin & Resident
			if (preCorpState === stateId) {
				setCities(towns);
				setTowns(towns);
				setLgaOriginStatus(true);
			} else {
				setCities("Non-Indigene");
				setTowns("Non-Indigene");
				setLgaOriginStatus(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finishCnt, lgas, towns]);

	useEffect(() => {
		getStates();
		getTowns();
		getCities();
		getLGAs();
		getBusinessCategory();
		getEconomicSectors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (city.toLowerCase().trim() === state) {
			setLoadLgaOrigin();
			setLgaOriginStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [city, lgas]);

	const getTowns = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/locationsetup`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setTowns(info);
			setTownsOrigin(info);
			setTownsArr(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getCities = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/city`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setCities(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getStates = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/state`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setStates(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getLGAs = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/lga`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setLgas(info);
			//setLgasOrigin(info);
			setLgasArr(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getBusinessCategory = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/category`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setCategories(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	const getEconomicSectors = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			const info = await axios
				.get(`/settings/economicsector`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => (res.data || {}).data);

			setEconomicSectors(info);
		} catch (err) {
			console.error(err);
			// setError("Existing information could not be retrieved.");
		}
	};

	// function handleChange(e) {
	// 	const name = e.target.name;
	// 	const value = e.target.value;

	// 	setValue({
	// 		...values,
	// 		[name]: value
	// 	});
	// }

	const jtbChecks = async (e) => {
		//e.preventDefault();
		setError("");

		setLoading(false);
		const chk = e;
		console.log('chk >>>> ', chk)
		setTINname("")		// clear textbox
		setTinError("")

		if (chk !== "") {
			try {
				setTINname("")		// clear textbox

				const accessToken = localStorage.getItem("access_token");
				await axios
					.post(
						`${urlPrefix}/txn/jtb/verifyBiz`,
						{
							"tin": chk,
							name: coyname,
							email: email,
							fone: phoneNumber,
							rc: rcNumber,
							dob: registrationDate,
							"userId": userId
						},
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => {
						console.log(res);
						// console.log('JTBValidatedData  >>>>  ', res?.data?.JTBValidatedData)
						if (res?.data?.ResponseCode === "005") {
							setTinError('TIN ' + res?.data?.ResponseDescription)
							setBtnSubmitDisabled(true);
							setError('TIN ' + res?.data?.ResponseDescription)
						} else if (res?.data?.ResponseCode === "001") {
							// const name = res?.data?.Taxpayer?.registered_name
							// setTINname(name)
							setBtnSubmitDisabled(false);
							setCoyName(res?.data?.Taxpayer?.registered_name)
							setRCNumber(res?.data?.Taxpayer?.registration_number)
							setPhoneNumber(res?.data?.Taxpayer?.phone_no)
							setEmail(res?.data?.Taxpayer?.email)
							// setRegistrationDate(res?.data?.Taxpayer?.date_of_registration)
							// setRegistrationDate(new Date(res?.data?.Taxpayer?.date_of_registration).toISOString().split("T") || "")
							//---
							const dg = res?.data?.Taxpayer?.date_of_registration;
							var kl = dg.split('-');
							var nDate = "";
							console.log('kl[2]  =  ', kl[2].length);
							if (kl[2].length === 4) {
								nDate = kl[2] + '-' + kl[1] + '-' + kl[0];
							}
							//---
							console.log('nDate = ', nDate);
							console.log('nDate = ', new Date(nDate).toISOString().split("T"));
							// const bn = new Date(nDate).toISOString().split("T")
							setCommencementDate(formatDate(nDate))
							//---
							const dwg = res?.data?.Taxpayer?.date_of_incorporation;
							var kwl = dwg.split('-');
							var iDate = "";
							console.log('kwl[2]  =  ', kwl[2].length);
							if (kwl[2].length === 4) {
								iDate = kwl[2] + '-' + kwl[1] + '-' + kwl[0];
							}
							//---							
							setRegistrationDate(formatDate(iDate))
						}
					})
			} catch (err) {
				let message = "";
				if (err.response) {
					message =
						err.response.data.errors?.details[0].message ||
						err.response.data?.error ||
						err.response.data?.errors;
				} else {
					message = err.message;
				}
				setError(
					message ||
					"Wrong Date of Birth: Pls enter your Date of Birth. "
				);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleSubmit = async (e) => {
		if (e) {
			e.preventDefault();
		}
		setError("");
		if (anssid) {
			setSubmitted(true);
			setCurrentStep(2);
			return;
		}

		if (btnSubmitDisabled) {
			setError(`Error!!!  ${tinError}`)
		} else {
			try {
				setLoading(true);

				let query = "";
				if (userId) {
					query += `userId=${userId}`;
				}

				async function editCompany() {
					await axios
						// .post(`${urlPrefix}/corporate/company?${query}`, values)
						.post(`${urlPrefix}/corporate/company?${query}`,
							{
								// ...values,
								// verifyMode: verifyTin,
								tin: tin,
								tinName: tinName,
								name: coyname,
								headOfficeAddress,
								stateOfficeAddress,
								city,
								lga,
								townOfResidence,
								cityOfResidence,
								phoneNumber,
								email,
								businessCategory,
								rcNumber,
								economicSector,
								numberOfStaff,
								registrationDate,
								commencementDate,
								taxOffice
							})
						.then((resp) => resp.data);
				}

				if (locationState?.edit) {
					editCompany();
				} else {
					await axios
						.post(
							`${constants.BASE_URL}/admin/usermanagement/adduser`,
							{
								firstname: "Oh",
								middlename: "My",
								lastname: "Eyes",
								phonenumber: phoneNumber,
								email: email,
								roles: "corporate",
								accounttype: "corporate",
								mdaCode: ""
							}
						)
						.then(async (res) => {
							userId = res?.data?.data?.id;
							location["state"] = {
								userId
							};
							query += `userId=${userId}`;
							editCompany();
						});
				}

				setSubmitted(true);
				setCurrentStep(2);
			} catch (err) {
				let message = "";
				if (err.response) {
					message = err.response.data?.errors || err.response.data?.error;
				} else {
					message = err.message;
				}

				setError(
					message ||
					"Company details could not be submitted. Please verify your details and try again."
				);
			} finally {
				setLoading(false);
			}
		}
	};

	const form = ({ footer }) => (
		<form className={styles.form} onSubmit={handleSubmit}>
			<div className={styles.div__wrapper}>
				<div>
					<label htmlFor="tin">
						Tax Identification No.(TIN)
					</label>
					<input
						type="text"
						id="tin"
						name="tin"
						className={styles.input__medium}
						value={tin}
						// onChange={handleChange}
						onChange={(e) => {
							setTIN(e.target.value);
							jtbChecks(e.target.value)
						}}
						placeholder="input your TIN"
					/>
				</div>
				{/* <div>
					<label htmlFor="tinName">
						Name of TIN
					</label>
					<input
						id="tinName"
						type="text"
						value={tinName}
						onChange={(e) => setTINname(e.target.value)}
						placeholder="Name of TIN"
						disabled
					/>
				</div> */}

			</div>
			<div>
				<label htmlFor="name">
					Company's name <span style={{ color: "red" }}>*</span>
				</label>
				<input
					type="text"
					id="coyname"
					className={styles.input__medium}
					name="coyname"
					onChange={(e) => setCoyName(e.target.value)}
					value={coyname}
					placeholder="eg Okafor & Associates"
					required
				/>
				<label htmlFor="headOfficeAddress">
					Company's Head Office Address{" "}
					<span style={{ color: "red" }}>*</span>
				</label>
				<input
					type="text"
					id="headOfficeAddress"
					className={styles.input__long}
					name="headOfficeAddress"
					onChange={(e) => setHeadOfficeAddress(e.target.value)}
					value={headOfficeAddress}
					placeholder="eg 15 River's Road, Preston, Enugu State"
					required
				/>
				<label htmlFor="stateOfficeAddress">
					State Office Address <span style={{ color: "red" }}>*</span>
				</label>
				<input
					type="text"
					id="stateOfficeAddress"
					className={styles.input__long}
					name="stateOfficeAddress"
					onChange={(e) => setStateOfficeAddress(e.target.value)}
					value={stateOfficeAddress}
					placeholder="eg 15 River's Road, Preston, Enugu State"
					required
				/>
			</div>
			<div className={styles.div__wrapper}>
				<div>
					<label htmlFor="city">
						State <span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="city"
						className={styles.input__medium}
						name="city"
						onChange={(e) => {
							setCity(e.target.value);
							//handleChange({ target: { name: 'lga', value: '' } });
							setLoadLgaOrigin(e);
							//setLoadLgaOriginStatus(e);
							setLgaOriginStatus(true);
						}}
						value={city}
						required
					>
						<option value="">--Select State--</option>
						{states
							.sort((a, b) =>
								a.Description.localeCompare(b.Description)
							)
							.map((state, key) => (
								<option
									value={state.Description}
									statecode={state.Code}
									key={key}
								>
									{state.Description}
								</option>
							))}
					</select>
				</div>
				{city && (
					<div>
						<label htmlFor="lga">
							Local Government Area{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="lga"
							className={styles.input__medium}
							name="lga"
							onChange={(e) => {
								setLGA(e.target.value);
								setLoadTownsOrigin(e);
							}}
							value={lga}
							required
						>
							<option value="">--Select LGA--</option>
							{lgaOriginStatus ? (
								lgasOrigin
									?.sort((a, b) =>
										a.Description.localeCompare(
											b.Description
										)
									)
									.map((lga, key) => (
										<option
											value={lga.Description}
											lgacode={lga.Code}
											key={key}
										>
											{lga.Description.toLowerCase()}
										</option>
									))
							) : (
								<option value="Non-Indigene">
									Non-Indigene
								</option>
							)}
						</select>
					</div>
				)}
				<div>
					<label htmlFor="townOfResidence">
						Town of Residence{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="townOfResidence"
						className={styles.input__medium}
						name="townOfResidence"
						onChange={(e) => setTownOfResidence(e.target.value)}
						value={townOfResidence}
						required
					>
						<option value="">--Select Town of Residence--</option>
						{lgaOriginStatus &&
							city === state &&
							lga ? (
							townsOrigin.map((town, i) => (
								<option value={town.Description} key={i}>
									{town.Description}
								</option>
							))
						) : (
							<option value="Non-Indigene">Non-Indigene</option>
						)}
					</select>
				</div>
				<div>
					<label htmlFor="cityOfResidence">City of Residence</label>
					<select
						id="cityOfResidence"
						className={styles.input__medium}
						name="cityOfResidence"
						onChange={(e) => setCityOfResidence(e.target.value)}
						value={cityOfResidence}
					>
						<option value="">--Select City of Residence--</option>
						{lgaOriginStatus && city === state ? (
							townsOrigin.map((city, i) => (
								<option value={city.Description} key={i}>
									{city.Description}
								</option>
							))
						) : (
							<option value="Non-Indigene">Non-Indigene</option>
						)}
					</select>
				</div>
			</div>
			<div className={styles.div__wrapper}>
				<div>
					<label htmlFor="phoneNumber">
						Company Phone no <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="number"
						id="phoneNumber"
						className={styles.input__medium}
						name="phoneNumber"
						onChange={(e) => setPhoneNumber(e.target.value)}
						value={phoneNumber}
						placeholder="eg 0803 562 8845"
						required
					// disabled={!userId}
					/>
				</div>
				<div>
					<label htmlFor="email">
						Company Email Address{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="email"
						id="email"
						className={styles.input__medium}
						name="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						placeholder="eg okaforassociates@gmail.com"
						required
					// disabled={!userId}
					/>
				</div>
			</div>
			<div className={styles.div__wrapper}>
				<div>
					<label htmlFor="businessCategory">
						Category of Business{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="businessCategory"
						className={styles.input__medium}
						name="businessCategory"
						onChange={(e) => setBusinessCategory(e.target.value)}
						value={businessCategory}
						required
					>
						<option value="">--Select Business Category--</option>
						<option>Sole Proprietor</option>
						<option>Partnership</option>
						<option>Limited Liability Company</option>
						<option>Public Liability Company</option>
						<option>Specialised Business</option>
					</select>
				</div>
			</div>
			<div className={styles.div__wrapper}>
				<div>
					<label htmlFor="rcNumber">
						Company R.C No{" "}
						<span>
							{[
								"Limited Liability Company",
								"Public Liability Company"
							].includes({ businessCategory })
								? "*"
								: ""}
						</span>
					</label>
					<input
						type="text"
						id="rcNumber"
						className={styles.input__medium}
						name="rcNumber"
						onChange={(e) => setRCNumber(e.target.value)}
						value={rcNumber}
						placeholder="eg RC12345"
						required={[
							"Limited Liability Company",
							"Public Liability Company"
						].includes(businessCategory)}
					/>
				</div>
				<div>
					<label htmlFor="economicSector">
						Sector of Economy{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<select
						id="economicSector"
						className={styles.input__medium}
						name="economicSector"
						onChange={(e) => setEconomicSector(e.target.value)}
						value={economicSector}
						required
					>
						<option value="">--Select Economic Sector--</option>
						{economicSectors.map((sector, key) => (
							<option value={sector.Description} key={key}>
								{sector.Description}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className={styles.div__wrapper}>
				<div>
					<label htmlFor="numberOfStaff">
						No of Employees in the State{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="number"
						id="numberOfStaff"
						className={styles.input__short}
						name="numberOfStaff"
						onChange={(e) => setNumberOfStaff(e.target.value)}
						value={numberOfStaff}
						placeholder="eg 123"
						required
						min={0}
					/>
				</div>
			</div>
			<div className={styles.div__wrapper}>
				<div>
					<label htmlFor="registrationDate">Registration Date</label>
					<input
						type="date"
						id="registrationDate"
						className={styles.input__short}
						name="registrationDate"
						onChange={(e) => setRegistrationDate(e.target.value)}
						value={registrationDate}
					/>
				</div>
				<div>
					<label htmlFor="commencementDate">
						Business Commencement Date
					</label>
					<input
						type="date"
						id="commencementDate"
						className={styles.input__short}
						name="commencementDate"
						onChange={(e) => setCommencementDate(e.target.value)}
						value={commencementDate}
					/>
				</div>
			</div>

			<label htmlFor="taxOffice">Tax Office</label>
			<input
				type="text"
				id="taxOffice"
				className={styles.input__long}
				name="taxOffice"
				onChange={(e) => setTaxOffice(e.target.value)}
				value={taxOffice}
				placeholder="eg 15 River's Road, Preston, Enugu State"
			/>
			{footer}
		</form>
	);

	return {
		title: "Company's Details",
		description:
			"This is about the Company registered. Please enter your company information in the appropriate fields as required on this page, noting those marked with the asterisk (*) sign as compulsory and Click Continue to proceed OR Exit & Log out.",
		form,
		state: {
			//values,
			numberOfStaff,
			lga,
			submitted,
			loading,
			error,
			disabled: !!anssid
		},
		submit: handleSubmit
	};
};

export default useCompanyDetails;
