import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Modal from '../components/Modal';
import Spinner from '../components/spinner';

import styles from "./style.module.css";
import altStyles from "../Dashboard/Settings/style.module.css";
import axios from 'axios';
import Alert from '../components/Alert';
import backgroundEllipse from "../assets/Home/backgroundEllipse.svg";
// import darkBackgroundLogo from "../assets/Home/darkContrastAnscimsLogo.svg";
import homeBackground from "../assets/Home/homeBackground.svg";
// import mobileLogo from "../assets/Home/mobileLogo.svg";
import facebookMobile from "../assets/Home/facebookMobile.svg";
import instagramMobile from "../assets/Home/instagramMobile.svg";
import twitterMobile from "../assets/Home/twitterMobile.svg";
import norlicsFooterLogo from "../assets/Home/norlicsFooterLogo.svg"

// femi
// tmp = require("../../public/anambra_state_logo.png")
const coyname = process.env.REACT_APP_COYNAME
const coylogo = process.env.REACT_APP_COYLOGO
const coylogo2 = process.env.REACT_APP_ANSCIMS_MOBILE_LOGO
const coyLogoFooter = process.env.REACT_APP_COYLOGOFOOTER
const coyaddr = process.env.REACT_APP_COYADDR
const coyaddr2 = process.env.REACT_APP_COYADDR2
const coysupport = process.env.REACT_APP_COYSUPPORT
const coyfone = process.env.REACT_APP_COYFONE
const coySocialId = process.env.REACT_APP_COYSOCIALID
const coyIMS = process.env.REACT_APP_COYADMIN

let imgpath = "../assets/Home/" + process.env.REACT_APP_COYLOGO

console.log(imgpath)
console.log(typeof imgpath)
console.log(typeof "../assets/Home/norlicsFooterLogo.svg")
console.log(imgpath === "../assets/Home/norlicsFooterLogo.svg")

console.log(process.env)
// const coylogo = require(`../assets/Home/${process.env.REACT_APP_COYLOGO}`)

// import coylogo from "../assets/Home/norlicsFooterLogo.svg"



// const isMobile = window.innerWidth <= 600;
console.log(process.env.REACT_APP_COYLOGO)
// console.log(coylogo)

const Home = () => {
	const [showModal, shouldShowModal] = useState(false);
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [rcNumber, setRcNumber] = useState("");
	const [sendingStatus, setSendingStatus] = useState(false);
	const [error, setError] = useState("");
	const [anssid, setAnssid] = useState("");

	const lookupAnssid = async (e) => {
		e.preventDefault();
		setError("");

		setSendingStatus(true);

		try {
			let query = '?';
			if (email) {
				query += `email=${email}&`;
			}
			if (phoneNumber) {
				query += `phoneNumber=${phoneNumber}&`;
			}
			if (rcNumber) {
				query += `rcNumber=${rcNumber}&`;
			}

			const res = await axios.get(`/users/getanssid${query}`).then(res => res.data);

			if (!res?.data?.anssid) {
				setError(`${coySocialId} not found`);
			} else {
				setAnssid(res.data.anssid);
			}
		} catch (err) {
			let message = "";
			if (err.response) {
				message = err.response.data?.errors || err.response.data?.error;
			} else {
				message = err.message;
			}

			setError(message || `An error occurred while trying to get ${coySocialId}`);
		} finally {
			setSendingStatus(false);
		}
	};

	return (
		<>
			<header className={styles.header}>
				<div className={styles.desktop__header_logo}>
					{/* <img src="/anscims_logo.svg" alt="Logo" /> */}
					<img src={coylogo} alt="Logo" />
				</div>
				<img
					src={coylogo2}
					// src="/anscims_logo.svg" 
					alt="logo"
					className={styles.mobile__header_logo}
				/>
				<nav>
					<NavLink to="/login" className={styles.nav__link}>
						Get {coySocialId}
					</NavLink>
					<NavLink
						to={{ pathname: "/login", state: { anscims: true } }}
						className={styles.nav__link}
					>
						Login to {coyIMS}
					</NavLink>
				</nav>
				<div className={styles.lookup__button}>
					<button
						style={{ border: "none", outline: "none" }}
						className={styles.nav__button}
						onClick={() => {
							shouldShowModal(true);
						}}
					>
						Lookup {coySocialId}
					</button>
				</div>
			</header>
			<img
				src={backgroundEllipse}
				alt="background ellipse"
				className={styles.background__ellipse}
			/>
			<main className={styles.main}>
				<div className={styles.content}>
					{/* <h1>Anambra State Central Information Management System</h1> */}
					<h1>{coyname}</h1>
					<h1>Central Information Management System</h1>
					<p>...Our digital landscape</p>
					<NavLink to="/login" className={styles.get__anssid}>
						Get {coySocialId}
					</NavLink>
				</div>
				<div className={styles.main__img_div}>
					<img
						src={homeBackground}
						alt="A boy and a girl"
						className={styles.boy__and_girl_pic}
					/>
				</div>
			</main>
			<footer className={styles.footer}>
				<div className={styles.top}>
					<div>
						<img
							// src={darkBackgroundLogo}
							src={coyLogoFooter}
							alt="IMS dark background logo"
						/>
						<div className={styles.socials}>
							<a href="https://facebook.com">
								<img src="/facebook.svg" alt="facebook" />
							</a>
							<a href="https://instagram.com">
								<img src="/instagram.svg" alt="instagram" />
							</a>
							<a href="https://twitter.com">
								<img src="/twitter.svg" alt="twitter" />
							</a>
						</div>
					</div>
					<div className={styles.extra__links}>
						<h3>Extra Links</h3>
						<ul>
							<li>About {coyIMS}</li>
							<li>Self Service Portal</li>
							<li>Retrieve {coySocialId}</li>
							<li>Visit our FAQ</li>
						</ul>
					</div>
					<div className={styles.extra__links}>
						<h3>Address</h3>
						<ul>
							{/* <li>No 1 Esther Obiako Street, Agu-Awka</li> */}
							{/* <li>support@irs.an.gov.ng</li> */}
							{/* <li>+2348032647662</li> */}
							<li>{coyaddr}</li>
							<li>{coyaddr2}</li>
							<li>{coysupport}</li>
							<li>{coyfone}</li>
						</ul>
					</div>
					<hr className={styles.hr} />
					<div className={styles.mobile__socials}>
						<a href="https://facebook.com">
							<img src={facebookMobile} alt="facebook" />
						</a>
						<a href="https://instagram.com">
							<img src={instagramMobile} alt="instagram" />
						</a>
						<a href="https://twitter.com">
							<img src={twitterMobile} alt="twitter" />
						</a>
					</div>
				</div>
				<hr className={styles.hr} />
				<div className={styles.bottom}>
					<p className={styles.copyright}>
						<span>&copy;</span> Copyright {new Date().getFullYear()} {coyIMS}. All rights
						reserved
					</p>
					<p className={styles.powered__by}>
						<span>Powered by</span>{" "}
						<img
							src={norlicsFooterLogo}
							alt="Norlics"
						/>
					</p>
				</div>
			</footer>
			{showModal && (
				<Modal
					showing={showModal}
					toggle={(show) => {
						setAnssid("");
						return shouldShowModal(show);
					}}
					style={{
						width: "50%",
						maxHeight: "500px",
						left: "50%",
						transform: "translateX(-50%)"
					}}
				>
					<div
						className={altStyles.settings__page}
						style={anssid ? {} : {}}
					>
						{error && <Alert text={error} type="error" />}
						{!anssid ? (
							<form
								className={altStyles.idDeetsForm}
								onSubmit={lookupAnssid}
							>
								<div className={altStyles.input__container}>
									<div>
										<label htmlFor="email">
											Email Address
										</label>
										<input
											type="email"
											id="email"
											name="email"
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
											placeholder="eg test@email.com"
										/>
									</div>
									<div>
										<label htmlFor="phone">
											Description
										</label>
										<input
											type="text"
											id="phone"
											name="phone"
											value={phoneNumber}
											onChange={(e) =>
												setPhoneNumber(e.target.value)
											}
											placeholder="eg +2349012345678"
										/>
									</div>
								</div>
								<div className={altStyles.input__container}>
									<div>
										<label htmlFor="rcNumber">
											Company RC Number
										</label>
										<input
											type="text"
											id="rcNumber"
											name="rcNumber"
											value={rcNumber}
											onChange={(e) =>
												setRcNumber(e.target.value)
											}
											placeholder="eg 123244444334"
										/>
									</div>
								</div>
								<button
									type="submit"
									className="primary__btn"
									disabled={
										sendingStatus ||
										(!email && !phoneNumber && !rcNumber)
									}
								>
									{sendingStatus ? "Saving..." : "Save"}
									{sendingStatus && <Spinner />}
								</button>
							</form>
						) : (
							<Alert
								text={anssid}
								type="success"
								style={{ position: "unset", width: "90%" }}
							/>
						)}
					</div>
				</Modal>
			)}
		</>
	);
};

export default Home;
