import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import styles from "../../Identity-management-corporate/style.module.css";
import style1 from "./style.module.css";
import ArrowRight from "../../assets/arrowRight.svg";

let userInfo = localStorage.getItem("user_info");
if (userInfo) {
	userInfo = JSON.parse(userInfo);
}

const CompanyDetails = ({ next, userId }) => {
	const [values, setValue] = useState({
		name: "",
		headOfficeAddress: "",
		stateOfficeAddress: "",
		city: "Anambra",
		lga: "",
		townOfResidence: "",
		cityOfResidence: "", // TODO Add to backend
		phoneNumber: userInfo.phonenumber || "",
		email: userInfo.email || "",
		rcNumber: "",
		businessCategory: "",
		economicSector: "",
		numberOfStaff: "",
		registrationDate: "",
		commencementDate: "",
		tin: "",
		TinName: "",
		taxOffice: "" // TODO Add to backend
	});

	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);

	const getCompanyDetails = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`/corporate/company?${query}`)
				.then((res) => (res.data || {}).data);

			if (info) {
				setValue({
					...info,
					phoneNumber: info.phoneNumber || values.phoneNumber,
					email: info.email || values.email
				});
			}
		} catch (err) {
			// setError("Existing company information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getCompanyDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={style1.title__div}>
				<h1>Company's Details</h1>
				<Link to={next}>
					Next <img src={ArrowRight} alt="right arrow" />
				</Link>
			</div>
			<form className={styles.form}>
				<label htmlFor="name">Company's name</label>
				<input
					type="text"
					id="name"
					className={styles.input__medium}
					name="name"
					value={values.name}
					placeholder="eg Okafor & Associates"
					disabled
				/>
				<label htmlFor="headOfficeAddress">
					Company's Head Office Address
				</label>
				<input
					type="text"
					id="headOfficeAddress"
					className={styles.input__long}
					name="headOfficeAddress"
					value={values.headOfficeAddress}
					placeholder="eg 15 River's Road, Preston, Enugu State"
					disabled
				/>
				<label htmlFor="stateOfficeAddress">State Office Address</label>
				<input
					type="text"
					id="stateOfficeAddress"
					className={styles.input__long}
					name="stateOfficeAddress"
					value={values.stateOfficeAddress}
					placeholder="eg 15 River's Road, Preston, Enugu State"
					disabled
				/>
				<div className={styles.div__wrapper}>
					<div>
						<label htmlFor="city">State</label>
						<input
							type="text"
							id="city"
							className={styles.input__medium}
							name="city"
							value={values.city}
							disabled
						/>
					</div>
					{values.city && (
						<div>
							<label htmlFor="lga">Local Government Area</label>
							<input
								type="text"
								id="lga"
								className={styles.input__medium}
								name="lga"
								value={values.lga}
								disabled
							/>
						</div>
					)}
					<div>
						<label htmlFor="townOfResidence">
							Town of Residence
						</label>
						<input
							type="text"
							id="townOfResidence"
							className={styles.input__medium}
							name="townOfResidence"
							value={values.townOfResidence}
							disabled
						/>
					</div>
					{/* <div>
						<label htmlFor="cityOfResidence">
							City of Residence
						</label>
						<input
							type="text"
							id="cityOfResidence"
							className={styles.input__medium}
							name="cityOfResidence"
							value={values.cityOfResidence}
							disabled
						/>
					</div> */}
				</div>
				<div className={styles.div__wrapper}>
					<div>
						<label htmlFor="phoneNumber">Company Phone no</label>
						<input
							type="text"
							id="phoneNumber"
							className={styles.input__medium}
							name="phoneNumber"
							value={values.phoneNumber}
							placeholder="eg 0803 562 8845"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="email">Company Email Address</label>
						<input
							type="email"
							id="email"
							className={styles.input__medium}
							name="email"
							value={values.email}
							placeholder="eg okaforassociates@gmail.com"
							disabled
						/>
					</div>
				</div>
				<div className={styles.div__wrapper}>
					<div>
						<label htmlFor="businessCategory">
							Category of Business
						</label>
						<input
							type="text"
							id="businessCategory"
							className={styles.input__medium}
							name="businessCategory"
							value={values.businessCategory}
							disabled
						/>
					</div>
				</div>
				<div className={styles.div__wrapper}>
					<div>
						<label htmlFor="rcNumber">
							Company R.C no/BN no{" "}
							<span>
								{[
									"Limited Liability Company",
									"Public Liability Company"
								].includes(values.businessCategory)
									? "*"
									: ""}
							</span>
						</label>
						<input
							type="text"
							id="rcNumber"
							className={styles.input__medium}
							name="rcNumber"
							value={values.rcNumber}
							placeholder="eg RC12345"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="economicSector">
							Sector of Economy
						</label>
						<input
							type="text"
							id="economicSector"
							className={styles.input__medium}
							name="economicSector"
							value={values.economicSector}
							disabled
						/>
					</div>
				</div>
				<div className={styles.div__wrapper}>
					<div>
						<label htmlFor="numberOfStaff">
							No of Employees in the State
						</label>
						<input
							type="number"
							id="numberOfStaff"
							className={styles.input__short}
							name="numberOfStaff"
							value={values.numberOfStaff}
							placeholder="eg 123"
							disabled
							min={0}
						/>
					</div>
				</div>
				<div className={styles.div__wrapper}>
					<div>
						<label htmlFor="registrationDate">
							Registration Date
						</label>
						<input
							type="date"
							id="registrationDate"
							className={styles.input__short}
							name="registrationDate"
							value={values.registrationDate}
							disabled
						/>
					</div>
					<div>
						<label htmlFor="commencementDate">
							Business Commencement Date
						</label>
						<input
							type="date"
							id="commencementDate"
							className={styles.input__short}
							name="commencementDate"
							value={values.commencementDate}
							disabled
						/>
					</div>
				</div>
				<div className={styles.div__wrapper}>
					<div>
						<label htmlFor="tin">Tax Identification No.(TIN)</label>
						<input
							type="text"
							id="tin"
							className={styles.input__medium}
							name="tin"
							value={values.tin}
							placeholder=""
							disabled
						/>
					</div>
					<div>
						<label htmlFor="email">Name of TIN</label>
						<input
							type="text"
							id="tinName"
							className={styles.input__medium}
							name="tinName"
							value={values.tinName}
							placeholder=""
							disabled
						/>
					</div>
				</div>
				<label htmlFor="taxOffice">Tax Office</label>
				<input
					type="text"
					id="taxOffice"
					className={styles.input__long}
					name="taxOffice"
					value={values.taxOffice}
					placeholder="eg 15 River's Road, Preston, Enugu State"
					disabled
				/>
			</form>
		</>
	);
};

export default CompanyDetails;
