/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowRight from "../../assets/arrowRight.svg";
import ArrowLeft from "../../assets/arrowLeft.svg";
import styles from "../../Identity-management-individual/style.module.css";
import style1 from "./style.module.css";

const EmploymentInfo = ({ next, prev, userId, query }) => {
	const { state } = useLocation();
	const [occupationtype, setOccupation] = useState("");
	const [employername, setEmployer] = useState("");
	const [employeraddress, setEmployerAddress] = useState("");
	const [employerstate, setEmployerState] = useState("Anambra");
	const [employercity, setEmployerCity] = useState("");
	const [monthlyincomerange, setIncomeRange] = useState("");
	const [companyanssid, setCompanyAnssid] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const getEmploymentInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`/users/employment?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				setOccupation(info.occupationtype || "");
				setEmployer(info.employername || "");
				setEmployerAddress(info.employeraddress || "");
				setEmployerState(info.employerstate || "");
				setEmployerCity(info.employercity || "");
				setIncomeRange(info.monthlyincomerange || "");
				setCompanyAnssid(info.companyanssid || "");
			}
		} catch (err) {
			setError("Existing employment information could not be retrieved.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getEmploymentInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={style1.title__div}>
				<Link
					to={{
						pathname: prev,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					<img src={ArrowLeft} alt="left arrow" /> Prev
				</Link>
				<h1>Employment Information</h1>
				<Link
					to={{
						pathname: next,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					Next <img src={ArrowRight} alt="next arrow" />
				</Link>
			</div>
			<form className={styles.columned__form}>
				<label className={styles.input__label} htmlFor="occupation">
					Occupation *
				</label>
				<input
					type="text"
					id="occupation"
					value={occupationtype}
					disabled
				/>
				<hr className={styles.divider} />
				<label className={styles.input__label} htmlFor="employer">
					Employer’s name *
				</label>
				<input
					type="text"
					id="employer"
					value={employername}
					disabled
				/>
				<hr className={styles.divider} />
				<label
					className={styles.input__label}
					htmlFor="employerAddress"
				>
					Address of Employer *
				</label>
				<input
					type="text"
					id="employerAddress"
					value={employeraddress}
					long="true"
					disabled
				/>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="employerState"
						>
							State of employer's Address *
						</label>
						<input
							type="text"
							id="stateOfOrigin"
							value={employerstate}
							disabled
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="employerCity"
						>
							City/town of employer's Address *
						</label>
						<input
							type="text"
							id="stateOfOrigin"
							value={employercity}
							disabled
						/>
					</div>
				</div>
				<hr className={styles.divider} />
				<label className={styles.input__label} htmlFor="incomeRange">
					Personal Monthly income range *
				</label>
				<input
					type="text"
					id="stateOfOrigin"
					value={monthlyincomerange}
					disabled
				/>
				<label className={styles.input__label} htmlFor="companyAnssid">
					Company Anssid
				</label>
				<input
					type="text"
					id="companyAnssid"
					disabled
					value={companyanssid}
				/>
				<hr className={styles.divider} />
			</form>
		</>
	);
};

export default EmploymentInfo;
