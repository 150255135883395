import { Component } from "react";
import styles from "./printOut.module.css";
import coatOfArm from "../../assets/coatOfArm.svg";
import ANSSIDLogo from "../../assets/anssidLogo.svg";
import AnambraLogo from "../../assets/anambraLogo.svg";
const coy_ANSSID = process.env.REACT_APP_ANSSID

class PrintOut extends Component {
	render() {
		return (
			<div className={styles.print__out}>
				<div className={styles.hero}>
					<header>
						<img src={coatOfArm} alt="coat of arm" />
						<img src={ANSSIDLogo} alt="Norlics Logo" />
						<img src={AnambraLogo} alt="Anambra Logo" />
					</header>
					<p>{coy_ANSSID} NO: 202021909</p>
					<h2>Biometrics</h2>
					<img src="#" alt="biometric pic" className={styles.biopic} />
				</div>
				<h2 className={styles.personal__details__header}>
					Personal Details
				</h2>
				<div className={styles.props__and__values}>
					<div className={styles.props}>
						<div>Title</div>
						<div>firstname</div>
						<div>Middlename</div>
						<div>Surname</div>
						<div>Mother's Maiden name</div>
						<div>Gender</div>
						<div>Date of Birth</div>
						<div>Phone no</div>
						<div>Email Address</div>
					</div>
					<div className={styles.values}>
						<div>Mr</div>
						<div>John</div>
						<div>John</div>
						<div>John</div>
						<div>Yoko</div>
						<div>F</div>
						<div>23/09/2930</div>
						<div>0902897389</div>
						<div>john.john@test.com</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PrintOut;
