/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowRight from "../../assets/arrowRight.svg";
import ArrowLeft from "../../assets/arrowLeft.svg";
import styles from "../../Identity-management-individual/style.module.css";
import style1 from "./style.module.css";

function formatDate(date) {
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

const IdentityProof = ({ next, prev, userId, query }) => {
	const { state } = useLocation();
	const [idInfo, setIdInfo] = useState("voters_card");
	const [idDoc, setIdDoc] = useState("");
	const [finalKnt, setFinalKnt] = useState("0");
	const [licenseIssueDate, setLicenseIssueDate] = useState(new Date());
	const [licenseExpiryDate, setLicenseExpiryDate] = useState(new Date());
	const [votersCardCode, setVotersCardCode] = useState("");
	const [voterskard, setVoterskard] = useState("");
	const [idNumber, setIDNumber] = useState("");
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [imagePreview, setImagePreview] = useState();
	const [imgPreview, setImgPreview] = useState();

	function formatDate(date) {
		//---
		const dg = date;
		var kl = dg.split('-');
		var nDate = "";
		// console.log(kl[2].length);
		if (kl[2].length === 4) {
			nDate = kl[2] + '-' + kl[1] + '-' + kl[0];
		} else {
			nDate = date;
		}
		//---

		const [d] = new Date(nDate).toISOString().split("T");
		return d;
	}

	const getIdentityInformation = async () => {
		setLoading(true);
		try {
			let query = "";
			if (userId) {
				query += `userId=${userId}`;
			}

			const info = await axios
				.get(`/users/identity-proof?${query}`)
				.then((res) => (res.data || {}).data);
			if (info) {
				// console.log('info  >> ', info)
				// console.log('info  >> ', info.identityPhoto)
				setImgPreview(info.identityPhoto || "")
				setVoterskard(info.votersCardCode)
				setIdInfo(info.identityType);
				setLicenseIssueDate(
					formatDate(info.licenseIssueDate) || Date.now()
				);
				setLicenseExpiryDate(
					formatDate(info.licenseExpiryDate) || Date.now()
				);
				setVotersCardCode(info.votersCardCode || "")
				setIDNumber(info.idNumber || "");
				setImagePreview(info.identityPhoto || "")
			}
		} catch (err) {
			console.error(err);
			// setError("Existing identity proof information could not be retrieved.");
		} finally {
			setLoading(false);
			setFinalKnt("1")
		}
	};

	useEffect(() => {
		getIdentityInformation();
		// console.log('votersCardCode  =  ', votersCardCode)
		// console.log('setVoterskard  =  ', voterskard)
		// console.log('imgPreview  =  ', imgPreview)
		// console.log('finalKnt  >>> ', finalKnt)
		if (finalKnt === "1") {
			// console.log('votersCardCode [finalknt] =  ', voterskard)
			// console.log('imgPreview [finalknt] =  ', imgPreview)
			setVotersCardCode(voterskard)
			setImagePreview(imgPreview)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finalKnt]);

	return (
		<>
			<div className={style1.title__div}>
				<Link
					to={{
						pathname: prev,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					<img src={ArrowLeft} alt="left arrow" /> Prev
				</Link>
				<h1>Proof of Identity</h1>
				<Link
					to={{
						pathname: next,
						search: query,
						state: {
							authorize: state?.authorize
						}
					}}
				>
					Next <img src={ArrowRight} alt="next arrow" />
				</Link>
			</div>
			<form>
				<p className={styles.regular__text}>Proof of Identity *</p>
				<ul className="flat__list">
					<li>
						<input
							type="radio"
							id="votersCard"
							name="idInfo"
							value="voters_card"
							checked={idInfo === "voters_card"}
							disabled
						/>
						<label
							className={styles.radio__label}
							htmlFor="votersCard"
						>
							Voter’s Card
						</label>
					</li>
					<li>
						<input
							type="radio"
							id="national_idCard"
							name="idInfo"
							value="national_id"
							checked={idInfo === "national_id"}
							disabled
						/>
						<label
							className={styles.radio__label}
							htmlFor="national_idCard"
						>
							National ID Card
						</label>
					</li>
					<li>
						<input
							type="radio"
							id="drivers_license"
							name="idInfo"
							value="drivers_license"
							checked={idInfo === "drivers_license"}
							disabled
						/>
						<label
							className={styles.radio__label}
							htmlFor="drivers_license"
						>
							Driver’s License
						</label>
					</li>
					<li>
						<input
							type="radio"
							id="international_passport"
							name="idInfo"
							value="international_passport"
							checked={idInfo === "international_passport"}
							disabled
						/>
						<label
							className={styles.radio__label}
							htmlFor="international_passport"
						>
							Int’l Passport
						</label>
					</li>
					<li>
						<input
							type="radio"
							id="not_applicable"
							name="idInfo"
							value="not_applicable"
							checked={idInfo === "not_applicable"}
							disabled
						/>
						<label
							className={styles.radio__label}
							htmlFor="not_applicable"
						>
							Not Applicable
						</label>
					</li>
				</ul>
				<hr className={styles.divider} />
				{idInfo === "voters_card" && (
					<div className={styles.div__wrapper}>
						<div>
							<label htmlFor="votersCardCode">
								Voters Card Code <span>*</span>
							</label>
							<input
								type="text"
								id="votersCardCode"
								className={styles.input__medium}
								// name="votersCardCode"
								value={votersCardCode}
								disabled
							/>
						</div>
					</div>
				)}
				{idInfo !== "voters_card" && (
					<div className={styles.div__wrapper__input__short}>
						<div
							className={
								idInfo !== "voters_card"
									? styles.div__wrapper
									: styles.div__wrapper__input__short
							}
						>
							<div>
								<label htmlFor="IDNumber">
									ID Number <span>*</span>
								</label>
								<input
									type="text"
									id="IDNumber"
									className={styles.input__medium}
									name="IDNumber"
									onChange={(e) =>
										setIDNumber(e.target.value)
									}
									value={idNumber}
									placeholder="eg abc123"
									required
								/>
							</div>
							<div>
								<label
									htmlFor="licenseIssueDate"
									className={styles.input__label}
								>
									Issue Date
								</label>
								<input
									type="date"
									id="licenseIssueDate"
									name="licenseIssueDate"
									className={styles.input__short}
									onChange={(e) =>
										setLicenseIssueDate(e.target.value)
									}
									value={licenseIssueDate}
								/>
							</div>
							<div>
								<label
									htmlFor="companyEmail"
									className={styles.input__label}
								>
									Expiry Date
								</label>
								<input
									type="date"
									id="licenseExpiryDate"
									name="licenseExpiryDate"
									className={styles.input__short}
									onChange={(e) =>
										setLicenseExpiryDate(e.target.value)
									}
									value={licenseExpiryDate}
								/>
							</div>
						</div>
					</div>
				)}
				<button
					type="button"
					className={styles.file__input__button}
					disabled
				>
					{imagePreview && (
						<img
							src={imagePreview}
							className={styles.image__preview}
							alt="Uploaded document"
						/>
					)}
				</button>
			</form>
		</>
	);
};

export default IdentityProof;
