/* eslint-disable eqeqeq */
import Pagination from "./Pagination";
import styles from "./style.module.css";

const Table = ({
	headers,
	data = [],
	full,
	total = data.length,
	pageSize = 20,
	onPageChanged,
	onEdit,
	onView
}) => {
	// const pages = Array(Math.ceil(total / data.length)).fill(1);
	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr className={styles.table__row}>
						{headers.map((header, i) => {
							if (full) {
								return <th key={i}>{header[0]}</th>;
							} else
								return i > 4 ? null : (
									<th key={i}>{header[0]}</th>
								);
						})}
						{(onEdit || onView) && <th>Action</th>}
					</tr>
				</thead>
				<tbody>
					{data.map((each, i) => (
						<tr className={styles.table__row} key={i}>
							{headers.map((header, i) => {
								const value = header[2]
									? header[2](each[header[1]])
									: each[header[1]];
								if (full) {
									return (
										<td key={i}>
											{value != undefined ? value : "N/A"}
										</td>
									);
								} else
									return i > 4 ? null : (
										<td key={i}>
											{value != undefined ? value : "N/A"}
										</td>
									);
							})}
							{(onEdit || onView) && (
								<td>
									{onView && (
										<button
											type="button"
											className={styles.view__btn}
											onClick={() => onView(each)}
										>
											View
										</button>
									)}
									{onEdit && (
										<button
											type="button"
											className={styles.edit__btn}
											onClick={() => onEdit(each)}
										>
											Edit
										</button>
									)}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</table>
			{full && (
				<footer className={styles.table__footer}>
					<p>
						Displaying {data.length} out of {total}
					</p>
					{total && (
						<Pagination
							totalRecords={total}
							pageLimit={pageSize}
							pageNeighbours={1}
							onPageChanged={onPageChanged}
						/>
					)}
				</footer>
			)}
		</>
	);
};

export default Table;
